import { useContext } from "react";
import { FbaseAuthUserContext } from "../fbase";
import { getFirebase } from "react-redux-firebase";

const useAuthentication = () => {
  let authUser = useContext(FbaseAuthUserContext);
  if (!!authUser && getFirebase().auth().currentUser === null) {
    authUser = undefined;
  }
  const doneAuthenticating = authUser !== false && authUser !== undefined;
  return { doneAuthenticating, authUser };
};
export default useAuthentication;
