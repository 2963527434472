import { StyledFormGroup } from "../styled-form-group";
import { InputGroup } from "@blueprintjs/core";
import { onChangeFactory } from "./helpers";
import React from "react";
import "styled-components/macro";

export function SalesforceCaseUrlInput({
  formState,
  formActions,
  activeField,
  setActiveField,
  data_testid,
  helperText,
  label,
  isVisible = true,
}) {
  return !isVisible ? null : (
    <StyledFormGroup
      helperText={helperText}
      label={label}
      labelFor="salesforceCaseUrl"
      activeField={activeField}
    >
      <InputGroup
        data-testid={data_testid}
        name="salesforceCaseUrl"
        value={formState.salesforceCaseUrl}
        onChange={onChangeFactory(formActions.setSalesforceCaseUrl)}
        leftIcon="link"
        type="text"
        large
        onFocus={() => setActiveField("salesforceCaseUrl")}
      />
    </StyledFormGroup>
  );
}
