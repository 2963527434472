import React, { useMemo } from "react";
import "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import CategoryColumnFilter from "./categoryColumnFilter";
import { ChurnScoreCellRenderer } from "./churn-score-cell/cell-renderer";
import { StarCellRenderer } from "./star-cell-renderer";
import { useGridContext } from "./my-ag-grid-react-context";
import { ActionButtonCellRenderer } from "../table-actions/action-cell-renderer";
import { NapoCellRenderer } from "./napo-cell/cell-renderer";
import moment from "moment-timezone";

import _ from "lodash";
import { ReportCustomerInteractionButtonCellRenderer } from "../table-actions/report-customer-interaction-cell-renderer";
import { PhoneActionButtonCellRenderer } from "../table-actions/phone-action-cell-renderer";

const USE_BIG_HEADERS = false;

const nfInt = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const nfFloat = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const FALSEY_VALUES = new Set([
  false,
  "false",
  "False",
  "No",
  "no",
  0,
  0.0,
  -0,
]);
const TRUTHY_VALUES = new Set([true, "true", "True", "Yes", "yes", 1, 1.0]);

function LinkedTextCellRenderer({ value: text, data, colDef }) {
  let link = data[colDef.__linkField || colDef.field];

  if (!link) {
    const field: string = colDef.__linkField || colDef.field;
    const fields: string[] = (colDef.__linkField || colDef.field).split(".");
    link = data;
    fields.forEach((fi) => {
      link = link[fi];
    });
  }

  // noinspection JSConstructorReturnsPrimitive
  if (!link) {
    return <span>{text}</span>;
  } else {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a href={inferMailtoLinks(link)} target="_blank">
        {text}
      </a>
    );
  }
}

const emailPatt = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
function validateEmail(email) {
  return emailPatt.test(email);
}

function inferMailtoLinks(url) {
  if (url && validateEmail(url)) {
    return `mailto:${url}`;
  } else {
    return url;
  }
}

function UrlCellRenderer({ value: url }) {
  // noinspection JSConstructorReturnsPrimitive
  return !url ? null : (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a href={inferMailtoLinks(url)} target="_blank">
      Link
    </a>
  );
}

export function timestampComparator(filterLocalValue, cellValue) {
  // Assume dates are stored as iso
  const cellDate = cellValue && new Date(cellValue);
  const filterLocalDate = filterLocalValue && new Date(filterLocalValue);

  if (!cellDate && !filterLocalDate) {
    return 0;
  } else if (!filterLocalDate) {
    return -1;
  } else if (!cellValue) {
    return 1;
  } else if (filterLocalDate < cellDate) {
    return -1;
  } else if (filterLocalDate > cellDate) {
    return 1;
  } else {
    return 0;
  }
}

export function dateComparator(
  filterLocalValue,
  cellValue,
  nodeA,
  nodeB,
  isInverted
) {
  // Assume dates are stored as iso
  const cellDate = cellValue && new Date(cellValue);
  const filterLocalDate = filterLocalValue && new Date(filterLocalValue);

  const cellDateInteger = !cellDate
    ? 0
    : Math.round(
        10000 * cellDate.getUTCFullYear() +
          100 * cellDate.getUTCMonth() +
          cellDate.getUTCDate()
      );
  const filterLocalDateInteger = !filterLocalDate
    ? 0
    : Math.round(
        10000 * filterLocalDate.getUTCFullYear() +
          100 * filterLocalDate.getUTCMonth() +
          filterLocalDate.getUTCDate()
      );

  let ret = 0;
  if (!cellDateInteger && !filterLocalDateInteger) {
    ret = 0;
  } else if (!filterLocalDateInteger) {
    ret = -1;
  } else if (!cellDateInteger) {
    ret = 1;
  } else if (filterLocalDateInteger < cellDateInteger) {
    ret = -1;
  } else if (filterLocalDateInteger > cellDateInteger) {
    ret = 1;
  } else {
    ret = 0;
  }

  if (isInverted === undefined && ret !== 0) {
    ret = -ret;
  }

  // console.log({ cellDateInteger, filterLocalDateInteger, ret, isInverted });

  return ret;
}

export function dateVF__old(params) {
  const value = _.isString(params) ? params : params.value;
  let ret = null;
  if (value) {
    const dateString = toUTCLocaleDateString(new Date(value), [], {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    if (dateString !== "Invalid Date") {
      ret = dateString;
    }
  }
  return ret;
}
export function dateVF({ value, colDef }) {
  let ret = null;
  let fmt;
  if (value && _.isString(value)) {
    ret = moment(value);
    if (colDef.__timezone) {
      ret = ret.tz(colDef.__timezone);
      fmt = "ddd ll";
    } else {
      ret = ret.utc(false);
      fmt = "ddd ll";
    }
    ret = ret.format(fmt);
  }
  return ret;
}

function toUTCLocaleDateString(date, locales, options) {
  const timeDiff = date.getTimezoneOffset() * 60000;
  const adjustedDate = new Date(date.valueOf() + timeDiff);
  return adjustedDate.toLocaleDateString(locales, options);
}

function timestampVF({ value, colDef }) {
  let ret = null;
  let fmt;
  if (value && _.isString(value)) {
    ret = moment(value);
    if (colDef.__timezone) {
      ret = ret.tz(colDef.__timezone);
      fmt = "ddd ll LT z";
    } else {
      ret = ret.utc(false);
      fmt = "ddd ll LT";
    }
    ret = ret.format(fmt);
  }
  return ret;
}

export function booleanVF(params) {
  if (params.value === undefined || params.value === null) {
    return "N/A";
  } else if (FALSEY_VALUES.has(params.value)) {
    return "";
  } else if (TRUTHY_VALUES.has(params.value)) {
    return "\u2713";
  } else {
    throw Error(
      `booleanFormatter cannot format params with params.value=${params.value}`
    );
  }
}

export function floatVF(params) {
  // noinspection JSUnresolvedFunction
  if (typeof params === "number") {
    return nfFloat.format(params);
  } else {
    return nfFloat.format(params.value);
  }
}

export function integerVF(params) {
  // noinspection JSUnresolvedFunction
  let val;
  if (typeof params === "number") {
    val = params;
  } else {
    val = params.value;
  }
  return _.isNaN(val) || _.isUndefined(val) || _.isNull(val)
    ? null
    : nfInt.format(val);
}

export function moneyVF(params) {
  const value = typeof params === "number" ? params : params.value;
  const prefix = value < 0 ? "-" : "";
  return prefix + "$" + floatVF(Math.abs(value));
}

export function percentageVF(params) {
  let ret = integerVF(params);
  return _.isNull(ret) ? "" : `${ret}%`;
}

const NUMERIC_COLUMN_PROPERTIES = {
  headerClass: "ag-numeric-header",
  cellClass: "ag-numeric-cell",
};

// @ts-ignore
const window_Cypress = !!window.Cypress;

// noinspection JSUnresolvedFunction
const DEFAULT_GRID_OPTIONS = {
  cellStyle: { "justify-content": "flex-end", "text-align": "right" },
  columnTypes: {
    dateColumn: {
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: dateComparator,
      },
      comparator: dateComparator,
      valueFormatter: dateVF,
    },
    timestampColumn: {
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: timestampComparator,
      },
      comparator: timestampComparator,
      valueFormatter: timestampVF,
    },
    booleanColumn: {
      filter: "categoryFilterer",
      valueFormatter: booleanVF,
    },
    percentageColumn: {
      filter: "agNumberColumnFilter",
      valueFormatter: percentageVF,
      ...NUMERIC_COLUMN_PROPERTIES,
    },
    integerColumn: {
      filter: "agNumberColumnFilter",
      valueFormatter: integerVF,
      ...NUMERIC_COLUMN_PROPERTIES,
    },
    floatColumn: {
      filter: "agNumberColumnFilter",
      valueFormatter: floatVF,
      ...NUMERIC_COLUMN_PROPERTIES,
    },
    moneyColumn: {
      filter: "agNumberColumnFilter",
      valueFormatter: moneyVF,
      ...NUMERIC_COLUMN_PROPERTIES,
    },
    textColumn: {
      filter: "agTextColumnFilter",
    },
    urlColumn: {
      filter: "agTextColumnFilter",
      cellRenderer: "urlRenderer",
    },
    linkedTextColumn: {
      filter: "agTextColumnFilter",
      cellRenderer: "linkedTextRenderer",
    },
    categoryColumn: {
      filter: "categoryFilterer",
    },
  },
  frameworkComponents: {
    urlRenderer: UrlCellRenderer,
    linkedTextRenderer: LinkedTextCellRenderer,
    categoryFilterer: CategoryColumnFilter,
    churnScoreCellRenderer: ChurnScoreCellRenderer,
    starCellRenderer: StarCellRenderer,
    actionButtonCellRenderer: ActionButtonCellRenderer,
    napoCellRenderer: NapoCellRenderer,
    reportCustomerInteractionButtonCellRenderer: ReportCustomerInteractionButtonCellRenderer,
    phoneActionButtonCellRenderer: PhoneActionButtonCellRenderer,
  },
  defaultColDef: {
    resizable: true,
    sortable: true,
    sortingOrder: ["desc", "asc", null],
    filter: true,
    suppressAutoSizeColumns: !window_Cypress,
    filterParams: {
      applyButton: true,
      clearButton: true,
      debounceMs: 200,
    },
    maxWidth: USE_BIG_HEADERS ? 250 : undefined,
  },
  immutableData: true,
  getRowNodeId: (data) => data.id,
  headerHeight: 48,
  rowHeight: 48,
  enableCellTextSelection: true,
  enableBrowserTooltips: true,
};

function useGridOptions({ scrollbarWidth, ...onEvents }) {
  const eventListeners = Object.values(onEvents);
  const context = useGridContext();
  return useMemo(
    () => ({
      scrollbarWidth,
      ...onEvents,
      ...DEFAULT_GRID_OPTIONS,
      context,
    }),
    [eventListeners, scrollbarWidth, context]
  );
}

export { useGridOptions };
