import React, { createContext, useContext, useMemo } from "react";

export const GridContext = createContext({});
export function GridContextProvider({ tableName, children }) {
  const value = useMemo(
    () => ({
      tableName
    }),
    [tableName]
  );
  return <GridContext.Provider value={value}>{children}</GridContext.Provider>;
}
export const useGridContext = () => useContext(GridContext);
export const useTableName = () => useGridContext().tableName;
