import useRedirect from "../hooks/useRedirect";
import { useMe } from "../hooks/useMe";
import routes from "./routes";
import { useMemo } from "react";
import { MeData, UserRole } from "../store/model-me";
import {
  EMPLOYEE_ROUTES_PATT,
  ROLE_ALLOWED_ROUTES_PATTS,
  USER_EXTRA_ALLOWED_ROUTES_PATTS,
} from "./authorized-routes";

interface AuthorizationState {
  loading: boolean;
  isAuthorized: boolean;
}

export function allowedRoutesForRole(role: UserRole) {
  return ROLE_ALLOWED_ROUTES_PATTS[role];
}

export function allowedRoutesForUser(userEmail: string) {
  return USER_EXTRA_ALLOWED_ROUTES_PATTS[userEmail] || EMPLOYEE_ROUTES_PATT;
}

const amIAuthorized = (meData: MeData, route: string): boolean => {
  const roleAllowedRoutesPatt = allowedRoutesForRole(meData.role);
  const userAllowedRoutesPatt = allowedRoutesForUser(meData.email);
  return roleAllowedRoutesPatt.test(route) || userAllowedRoutesPatt.test(route);
};

export function useIsAuthorized(route: string): AuthorizationState {
  const me = useMe();
  const meInitialData: MeData = me.initialData;
  const isAuthorized: boolean = me.success
    ? amIAuthorized(meInitialData, route)
    : false;
  return { loading: me.loading, isAuthorized };
}

export function useAuthorizedRoutes(): Set<string> {
  const me = useMe();
  return useMemo(
    () =>
      !me.success
        ? new Set([])
        : new Set(
            Object.values(routes).filter((route) =>
              amIAuthorized(me.initialData, route)
            )
          ),
    [me.initialData, me.success]
  );
}

export function useRedirectIfNotAuthorized(
  protectedRoute: string,
  redirectRoute: string
): boolean {
  const auth = useIsAuthorized(protectedRoute);

  const doneLoading: boolean = !auth.loading;
  const isDefinitelyNotAuthorized: boolean = doneLoading && !auth.isAuthorized;
  const isDefinitelyAuthorized: boolean = doneLoading && auth.isAuthorized;
  useRedirect(() => isDefinitelyNotAuthorized, redirectRoute);
  return isDefinitelyAuthorized;
}
