import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Col, Row } from "antd";
import styled from "styled-components/macro";
import "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import { useGridOptions } from "./gridOptions";
import {
  useAutoSizeAll,
  useExportToCsv,
  useGetAllGridColumns,
  useGetAreAllColumnsVisible,
  useSetQuickFilterText,
} from "./gridApi";
import { useBoolean } from "../../helpers/useBoolean";
import { VisibilityModal } from "./show-hide-columns";
import { useToggle } from "react-use";
import {
  getStateOfGrid,
  MyAgGridReact,
  restoreStateToGrid,
} from "./my-ag-grid-react";
import { getGridHeight } from "./getGridHeight";
import _ from "lodash";
import { Ribbon } from "./ribbon";
import { CSS_TO_ALWAYS_SHOW_SCROLLBARS, useExtraGridCss } from "./styling";
import { Classes } from "@blueprintjs/core";
import { SearchBoxStore } from "./search-box-store";
import { AG_TABLE_RIBBON_HEIGHT_PX } from "../../styles/constants";
import { NoData } from "./no-data";
import { useGridBorderRadius } from "./use-grid-border-radius";
import { GridContextProvider, useTableName } from "./my-ag-grid-react-context";
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import { AgColDefs, AgGlobals, AgRows, AgState } from "./types";
import { Column } from "@ag-grid-community/core/dist/es6/entities/column";
import { useStoreState } from "../../hooks/ep";
import { TableRow } from "../../store/readonly-table-model-factory";

interface AgTableProps {
  rowData: AgRows;
  columnDefs: AgColDefs;
  onCellClicked?: any;
  height?: string;
  tableName: string;
  fullScreenEnabled?: boolean;
  finalizeRowData?: { (rowData: TableRow[]): TableRow[] };
  finalizeColDefs?: { (colDefs: AgColDefs): AgColDefs };
  customRibbonLeftElements?: JSX.Element[];
  ribbonTitle?: string | JSX.Element;
  hideEyeball?: boolean;
  hideSearchbox?: boolean;
}

function AgTable({
  rowData,
  columnDefs,
  onCellClicked,
  height,
  tableName,
  fullScreenEnabled,
  finalizeRowData = (v) => v,
  finalizeColDefs = (v) => v,
  customRibbonLeftElements,
  ...restProps
}: AgTableProps) {
  rowData = finalizeRowData(rowData);
  columnDefs = finalizeColDefs(columnDefs);
  const hasRowsToShow =
    rowData && columnDefs && _.size(rowData) && _.size(columnDefs);

  if (hasRowsToShow) {
    return (
      <SearchBoxStore.Provider>
        <GridContextProvider tableName={tableName}>
          <AgTableInner
            rowData={rowData}
            columnDefs={columnDefs}
            onCellClicked={onCellClicked}
            height={height}
            tableName={tableName}
            fullScreenEnabled={fullScreenEnabled ?? false}
            customRibbonLeftElements={customRibbonLeftElements ?? []}
            {...restProps}
          />
        </GridContextProvider>
      </SearchBoxStore.Provider>
    );
  } else {
    return <NoData />;
  }
}

function AgTableInner({
  rowData,
  columnDefs,
  onCellClicked,
  height,
  fullScreenEnabled,
  customRibbonLeftElements,
  ribbonTitle,
  hideEyeball,
  hideSearchbox,
}: AgTableProps) {
  const g = useGrid({ onCellClicked });

  const extraGridCss = useExtraGridCss(columnDefs);
  const fullScreen = useStoreState((s) => s.misc.fullScreenModeEnabled);
  const borderRadius = useGridBorderRadius();
  const tableName = useTableName();

  return (
    <Row
      css={`
        width: 100%;
      `}
    >
      <Col
        data-testid={`${tableName}_table_root`}
        className={`${Classes.ELEVATION_4}`}
        flex={g.vmShown ? 18 : 24}
        css={`
          & .ag-center-cols-viewport {
            background-color: #293742;
          }
          border-radius: ${borderRadius};
        `}
      >
        <Ribbon
          g={g}
          rowData={rowData}
          colDefs={columnDefs}
          tableName={tableName}
          fullScreenEnabled={fullScreenEnabled}
          customRibbonLeftElements={customRibbonLeftElements}
          ribbonTitle={ribbonTitle}
          hideEyeball={hideEyeball}
          hideSearchbox={hideSearchbox}
        />
        <Row data-testid={`${tableName}_table_content`}>
          <div
            data-testid={`${tableName}_table_content_inner`}
            css={`
              ${extraGridCss}
            `}
          >
            <g.GridWrapper
              height={height}
              fullScreen={fullScreen}
              tableName={tableName}
            >
              <MyAgGridReact
                gridRef={g.gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                gridOptions={g.gridOptions}
              />
            </g.GridWrapper>
          </div>
        </Row>
      </Col>
      {g.vmShown &&
        g.gridIsReady &&
        g.firstDataIsRendered &&
        _.size(g.columns) && (
          <>
            <Col flex={1}>
              <></>
            </Col>
            <Col
              flex={5}
              css={
                height &&
                `
                height: calc(${height} + ${AG_TABLE_RIBBON_HEIGHT_PX});
              `
              }
            >
              <VisibilityModal
                columns={g.columns}
                showColumn={g.showColumn}
                hideColumn={g.hideColumn}
              />
            </Col>
          </>
        )}
    </Row>
  );
}

const GridInnerWrapper = styled.div`
  height: ${getGridHeight};
  width: 100%;

  .ag-root[role="grid"] {
    border: none;
  }

  ${({ alwaysShowScrollbars }) =>
    alwaysShowScrollbars && CSS_TO_ALWAYS_SHOW_SCROLLBARS}
`;

function useGrid({ onCellClicked }): AgGlobals {
  const gridRef = useRef<AgGridReact>();

  const [displayedRowCount, setDisplayedRowCount] = useState<number>(null);

  const [localGridState, setLocalGridState] = useState<AgState>({});
  const getLocalGridState = useCallback(() => localGridState, [localGridState]);
  const setLocalGridStateFromActualGridState = useCallback(() => {
    setLocalGridState(getStateOfGrid(gridRef.current));
  }, [setLocalGridState]);

  const getAllGridColumns = useGetAllGridColumns(gridRef);

  // TODO: Just use easy-peasy for all table state
  const [columns, setColumns] = useState<Column[]>([]);
  const refreshColumns = () => {
    setColumns(getAllGridColumns().map((c) => ({ ...c })));
  };
  useEffect(() => {
    if (!_.size(columns)) {
      refreshColumns();
    }
    // eslint-disable-next-line
  }, [_.size(columns)]);

  const gridIsReady = useBoolean(true);
  const firstDataIsRendered = useBoolean(false);
  const exportToCsv = useExportToCsv(gridRef);
  const autoSizeAll = useAutoSizeAll(gridRef);
  const showColumn = useCallback(
    (column: string) => {
      gridRef?.current?.columnApi?.setColumnVisible(column, true);
      refreshColumns();
    },
    // eslint-disable-next-line
    [gridRef, getAllGridColumns]
  );
  const hideColumn = useCallback(
    (column: string) => {
      gridRef?.current?.columnApi?.setColumnVisible(column, false);
      refreshColumns();
    },
    // eslint-disable-next-line
    [gridRef, getAllGridColumns]
  );
  const getAreAllColumnsVisible = useGetAreAllColumnsVisible(gridRef);
  const [vmShown, toggleVmShown] = useToggle(false);
  const setQuickFilterText = useSetQuickFilterText(gridRef);
  const setCategoryFilter = useSetCategoryFilter(gridRef);

  const alwaysShowScrollbars = useBoolean(false);
  const handleToggleScrollbarsVisible = useCallback(() => {
    setLocalGridStateFromActualGridState();
    alwaysShowScrollbars.toggle();
    // eslint-disable-next-line
  }, [alwaysShowScrollbars.toggle, setLocalGridStateFromActualGridState]);

  useEffect(() => {
    if (_.size(getLocalGridState())) {
      restoreStateToGrid(getLocalGridState(), gridRef.current);
    }
    // eslint-disable-next-line
  }, [getLocalGridState()]);

  const gridOptions = useGridOptions({
    onCellClicked,
    onFilterChanged: useCallback(() => {
      const newDisplayedRowCount = gridRef?.current?.api?.getDisplayedRowCount();
      if (
        newDisplayedRowCount !== undefined &&
        newDisplayedRowCount !== displayedRowCount
      ) {
        setDisplayedRowCount(newDisplayedRowCount);
      }
      // eslint-disable-next-line
    }, [displayedRowCount, setDisplayedRowCount]),
    onFirstDataRendered: useCallback(() => {
      firstDataIsRendered.setTrue();
      // eslint-disable-next-line
    }, [firstDataIsRendered.setTrue]),
    onColumnVisible: useCallback(() => {
      refreshColumns();
      // eslint-disable-next-line
    }, [setColumns, getAllGridColumns]),
    scrollbarWidth: alwaysShowScrollbars.value ? 8 : undefined,
  });

  useEffect(() => {
    firstDataIsRendered.value && setTimeout(autoSizeAll, 300);
    // eslint-disable-next-line
  }, [firstDataIsRendered.value]);

  const GridWrapper = useCallback(
    ({ children, height, fullScreen, tableName }) => {
      return (
        <div
          data-testid={`${tableName}_grid_wrapper`}
          css={`
            width: 100%;
            height: 100%;
          `}
        >
          <GridInnerWrapper
            data-testid={`${tableName}_grid_wrapper_inner`}
            className="ag-theme-balham-dark"
            alwaysShowScrollbars={alwaysShowScrollbars.value}
            height={height}
            fullScreen={fullScreen}
            tableName={tableName}
          >
            {children}
          </GridInnerWrapper>
        </div>
      );
    },
    // eslint-disable-next-line
    [alwaysShowScrollbars.value]
  );

  return {
    gridRef,
    gridOptions,
    GridWrapper,
    exportToCsv,
    autoSizeAll,
    showColumn,
    hideColumn,
    getAllGridColumns,
    getAreAllColumnsVisible,
    vmShown,
    toggleVmShown,
    VisibilityModal,
    setQuickFilterText,
    toggleScrollbarsVisible: handleToggleScrollbarsVisible,
    gridIsReady,
    firstDataIsRendered,
    mySetStateOfGrid: setLocalGridStateFromActualGridState,
    columns,
    setCategoryFilter,
    displayedRowCount,
  };
}

function useSetCategoryFilter(gridRef: MutableRefObject<AgGridReact>) {
  return (colId: string, filterValue: any) => {
    // console.log("useSetCategoryFilter");
    const api = gridRef.current.api;
    api.setFilterModel({
      ...gridRef.current.api.getFilterModel(),
      [colId]: { value: filterValue },
    });
  };
}

export default AgTable;
