import { StyledFormGroup } from "./styled-form-group";
import React, { FocusEventHandler, useCallback } from "react";
import "styled-components/macro";
import { Radio } from "@blueprintjs/core";
import onlyShowFocusOnTabs from "../make-blueprint-only-show-focus-on-tabs";
import { TooltipLabel } from "./tooltip-label";

onlyShowFocusOnTabs();

// TODO: Click a selected radio button to "clear" the value for the radio field

export interface SetStringValue {
  (v: string): void;
}

export function SimpleRadio({
  value,
  label,
  selectedValue,
  setSelectedValue,
  onFocus,
}: {
  value: string;
  label?: string | JSX.Element;
  selectedValue: string | null;
  setSelectedValue: any;
  onFocus: FocusEventHandler;
}): JSX.Element {
  const onChange = useCallback(() => {}, []);
  // const onChange = useCallback((ev) => setSelectedValue(value), [
  //   setSelectedValue,
  // ]);
  const onClick = useCallback((ev) => setSelectedValue(value), [
    setSelectedValue,
  ]);
  return (
    <Radio
      inline
      large
      key={value}
      labelElement={label ?? value}
      checked={value === selectedValue}
      onChange={onChange}
      onClick={onClick}
      onFocus={onFocus}
    />
  );
}

export function SimpleRadioGroup({
  selectedValue,
  setSelectedValue,
  allValues,
  fieldLabel,
  fieldHelperText,
  fieldId,
  activeFieldId,
  setActiveFieldId,
}: {
  selectedValue: string | null;
  setSelectedValue: SetStringValue;
  allValues: string[];
  fieldLabel?: string;
  fieldHelperText?: string;
  fieldId?: string;
  activeFieldId?: string;
  setActiveFieldId?: SetStringValue;
}): JSX.Element {
  // assert(_.isNull(selectedValue) || allValues.includes(selectedValue));

  const onFocus = useCallback(
    () => setActiveFieldId && setActiveFieldId(fieldId),
    [setActiveFieldId, fieldId]
  );
  // const [other, setOther] = useState("");
  return (
    <StyledFormGroup
      label={
        <TooltipLabel labelText={fieldLabel} tooltipText={fieldHelperText} />
      }
      labelFor={fieldId}
      helperText={fieldHelperText}
      activeField={activeFieldId}
      css={`
        input,
        button,
        textarea,
        :focus {
          outline: none;
        }
      `}
    >
      {allValues.map((v) => (
        <SimpleRadio
          key={v}
          value={v}
          label={v}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          onFocus={onFocus}
        />
      ))}
      {/*<SimpleRadio*/}
      {/*  key={"thing"}*/}
      {/*  value={other}*/}
      {/*  label={*/}
      {/*    <EditableText*/}
      {/*      placeholder="Other..."*/}
      {/*      selectAllOnFocus={true}*/}
      {/*      confirmOnEnterKey={true}*/}
      {/*      value={other}*/}
      {/*      onChange={setOther}*/}
      {/*      onConfirm={setSelectedValue}*/}
      {/*    />*/}
      {/*  }*/}
      {/*  selectedValue={selectedValue}*/}
      {/*  setSelectedValue={setSelectedValue}*/}
      {/*  onFocus={onFocus}*/}
      {/*/>*/}
    </StyledFormGroup>
  );
}
