import moment from "moment-timezone";
import _ from "lodash";

const PACIFIC_TIME = "America/Los_Angeles";

function isoformat(m: moment.Moment): string {
  return m.format("YYYY-MM-DD HH:mm:ss z");
}

export function happenedToday(itxnTimestamp?: string): boolean {
  if (_.isNull(itxnTimestamp) || _.isUndefined(itxnTimestamp)) {
    return false;
  }
  const itxnTimestampPT = moment(itxnTimestamp).tz(PACIFIC_TIME);
  const currentTimestampPT: moment.Moment = moment.utc().tz(PACIFIC_TIME);

  const minTimestampPT = getMinTimestampPT(currentTimestampPT);

  // console.log({
  //   itxnTimestamp,
  //   itxnTimestampPT: isoformat(itxnTimestampPT),
  //   currentTimestampPT: isoformat(currentTimestampPT),
  //   minTimestampPT: isoformat(minTimestampPT),
  // });
  return itxnTimestampPT >= minTimestampPT;
}

function getMinTimestampPT(currentTimestampPT: moment.Moment): moment.Moment {
  const currentHourIndexPT = currentTimestampPT.hour();
  // console.log({ currentHourIndexPT });

  let ret = currentTimestampPT.clone();
  if (currentHourIndexPT < 4) {
    ret = ret.subtract(1, "day");
  }
  return ret.set({
    hour: 4,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
}

// function isWithinLast24Hours(timestamp?: string): boolean {
//   if (_.isNull(timestamp) || _.isUndefined(timestamp)) {
//     return false;
//   }
//   const currentTime: moment.Moment = moment.utc();
//   const hoursAgo: number = currentTime.diff(moment.utc(timestamp), "h");
//   console.log({ timestamp, currentTime: currentTime.toISOString(), hoursAgo });
//   return hoursAgo < 24;
// }
