import React from "react";
import "styled-components/macro";
import { SimpleTextarea } from "../simple-textarea";

const HELPER_TEXT =
  "Help us understand the nature of your customer outreach and how the customer is feeling about PeopleReady";

export function NotesInput({
  value,
  onValueChange,
  activeField,
  setActiveField,
  height = "100px",
}) {
  return (
    <SimpleTextarea
      text={value}
      setText={onValueChange}
      fieldLabel={"Notes"}
      fieldHelperText={HELPER_TEXT}
      fieldId={"notes"}
      activeFieldId={activeField}
      setActiveFieldId={setActiveField}
      height={height}
      icon="comment"
    />
  );
}
