import React from "react";
import {
  Classes,
  Popover,
  PopoverInteractionKind,
  Position,
} from "@blueprintjs/core";
import { ThePopoverContent } from "./popover-content";
import { ThePopoverTarget } from "./popover-target";
import "styled-components/macro";
import _ from "lodash";

export function NapoCellRenderer({
  value: napo,
  data: { customer_id: customerId },
}) {
  return <ThePopover napo={napo} customerId={customerId} />;
}

function ThePopover({ napo, customerId }) {
  if (_.isUndefined(napo) || _.isNull(napo) || _.isNaN(napo)) {
    return <ThePopoverTarget napo={napo} />;
  } else {
    return (
      <div
        css={`
          display: flex;
          justify-content: flex-end;
        `}
      >
        <Popover
          content={<ThePopoverContent customerId={customerId} />}
          position={Position.RIGHT_TOP}
          interactionKind={PopoverInteractionKind.HOVER}
          popoverClassName={`${Classes.ELEVATION_4}`}
          hoverOpenDelay={300}
          fill={false}
        >
          <ThePopoverTarget napo={napo} />
        </Popover>
      </div>
    );
  }
}
