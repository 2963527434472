import React, { useCallback } from "react";
import { Tooltip } from "antd";
import { Button, Menu, MenuItem, Popover, Position } from "@blueprintjs/core";
import styled from "styled-components/macro";
import { useStoreActions, useStoreState } from "../../hooks/ep";
import _ from "lodash";
import { CopyablePhoneTag } from "./phone-action-cell-renderer";
import { CustomerData } from "../../store/model-pready";

export function ActionButtonCellRenderer({ data, context }) {
  const popoverContentMenu = useMenu(data);

  return (
    <span>
      <StyledPopoverWrapper
        canEscapeKeyClose
        content={popoverContentMenu}
        position={Position.RIGHT_BOTTOM}
      >
        <Tooltip title="Actions" placement="topLeft" mouseEnterDelay={1.5}>
          <Button
            minimal
            small
            icon="take-action"
            large={false}
            intent="primary"
          />
        </Tooltip>
      </StyledPopoverWrapper>
    </span>
  );
}

function PopoverWrapper({ content, children, className, ...rest }) {
  return (
    <Popover {...rest} content={content} portalClassName={className}>
      {children}
    </Popover>
  );
}

export const StyledPopoverWrapper = styled(PopoverWrapper)`
  &&& * {
    z-index: 1000;
  }
`;

function composeMailtoLink(email, toName, fromName) {
  const et = {
    subject: "Hello From PeopleReady",
    body: `
Hi ${toName}, 

I hope that you are doing well. Do you have time to connect any time next week?

Best,
${fromName}
`.trim(),
  };
  const subject = encodeURIComponent(et.subject);
  const body = encodeURIComponent(et.body);
  return `mailto:${email}?subject=${subject}&body=${body}`;
}

function useSignedInUserName() {
  return useStoreState(
    (s) => s.me.initialData.nickname || s.me.initialData.first_name
  );
}

// eslint-disable-next-line no-unused-vars
function getPhoneMenuItem(data) {
  const phoneNumber = data["formatted_contact_phone_number"];

  let phoneMenuItem;
  if (!phoneNumber) {
    phoneMenuItem = null;
  } else {
    phoneMenuItem = (
      <MenuItem
        icon={null}
        text={<CopyablePhoneTag phoneNumber={phoneNumber} />}
        shouldDismissPopover={false}
      />
    );
  }
  return phoneMenuItem;
}

// eslint-disable-next-line no-unused-vars
function useStarMenuItem(data) {
  const customerId = data["customer_id"];
  const customerName = data["CustomerName"];

  const starredCustomerIds = useStoreState((a) => a.stars.starredCustomerIds);
  // noinspection JSUnresolvedFunction
  const starred = starredCustomerIds.has(customerId);
  const starCustomer = useStoreActions((a) => a.stars.starCustomer);
  const unstarCustomer = useStoreActions((a) => a.stars.unstarCustomer);
  const onClickStarsAction = useCallback(
    async (ev) => {
      const storeAction = starred ? unstarCustomer : starCustomer;
      await storeAction({ customerId, customerName });
    },
    [starred, starCustomer, unstarCustomer, customerId, customerName]
  );
  return (
    <MenuItem
      icon="star"
      text={starred ? "Unstar" : "Star"}
      onClick={onClickStarsAction}
      shouldDismissPopover={true}
    />
  );
}

function useEmailMenuItem(data) {
  const emailAddress = _.toLower(data["PrimaryContactEmail"] || "");
  const firstName = data["formatted_contact_full_name"].split(" ")[0];
  const signedInUserName = useSignedInUserName();
  const onClickEmailCustomerContactAction = useCallback(() => {
    window.open(composeMailtoLink(emailAddress, firstName, signedInUserName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailAddress, firstName]);
  return !emailAddress ? null : (
    <MenuItem
      icon="envelope"
      text="Email Contact"
      onClick={onClickEmailCustomerContactAction}
      shouldDismissPopover={true}
    />
  );
}

export function useReportCustomerInteraction(customerData: CustomerData) {
  const openDrawer = useStoreActions((a) => a.misc.ra_openModal);
  return useCallback(() => {
    const actionName = "report_customer_interaction";
    const fullAction = { actionName, snapshot: customerData };
    openDrawer(fullAction);
  }, [customerData, openDrawer]);
}

function useReportMenuItem(data) {
  const reportCustomerInteraction = useReportCustomerInteraction(data);
  return (
    <MenuItem
      icon="chat"
      text={"Report Interaction"}
      onClick={reportCustomerInteraction}
      shouldDismissPopover={true}
    />
  );
}

function useBriefingMenuItem(data) {
  const customerId: number = data.customer_id;
  const show = useStoreActions((a) => a.misc.customerBriefing_show);
  return (
    <MenuItem
      icon="info-sign"
      text={"Customer Briefing"}
      onClick={() => {
        show(customerId);
      }}
      shouldDismissPopover={true}
    />
  );
}

function useMenu(data) {
  const customerId: number = data.customer_id;
  const freshData = useStoreState((s) => s.pready.customer2data[customerId]);

  // let phoneMenuItem = getPhoneMenuItem(data);
  const emailMenuItem = useEmailMenuItem(freshData);
  const reportMenuItem = useReportMenuItem(freshData);
  const briefingMenuItem = useBriefingMenuItem(freshData);
  // const starMenuItem = useStarMenuItem(data);

  return (
    <Menu large>
      {/*{phoneMenuItem}*/}
      {emailMenuItem}
      {reportMenuItem}
      {briefingMenuItem}
      {/*{starMenuItem}*/}
    </Menu>
  );
}
