import { StyledFormGroup } from "../styled-form-group";
import { InputGroup } from "@blueprintjs/core";
import { onChangeFactory } from "./helpers";
import React from "react";
import "styled-components/macro";

export function PhoneInput({
  formState,
  formActions,
  activeField,
  setActiveField,
  data_testid,
  helperText,
  label,
  isVisible = true,
}) {
  return !isVisible ? null : (
    <StyledFormGroup
      helperText={helperText}
      label={label}
      labelFor="phone"
      activeField={activeField}
    >
      <InputGroup
        data-testid={data_testid}
        name="phone"
        value={formState.phone}
        onChange={onChangeFactory(formActions.setPhone)}
        leftIcon="phone"
        type="text"
        large
        onFocus={() => setActiveField("phone")}
      />
    </StyledFormGroup>
  );
}
