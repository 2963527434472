import { useStoreActions, useStoreState } from "../hooks/ep";
import { useAsync } from "react-use";

import { BackendConnectedModelSliceName } from "../store/model";
import { useCallback } from "react";

export function useEnsureData(
  modelSlice: BackendConnectedModelSliceName
): boolean {
  const initialDataReceived = useStoreState(
    (s) => s[modelSlice].initialDataReceived
  );
  const initialDataLoading = useStoreState(
    (s) => s[modelSlice].initialDataLoading
  );

  const fetcher = useStoreActions(
    (a) => a[modelSlice].maybeHandleFetchInitialData
  );

  const handleFetch = useCallback(async () => {
    if (!initialDataReceived) {
      await fetcher();
    }
  }, [fetcher]);

  const { loading } = useAsync(
    handleFetch
    // [initialDataReceived]
  );

  return loading || initialDataLoading;
}
