import React from "react";
import { DashboardTable } from "./dashboard-table";

export function PulseTop10Table(props) {
  return (
    <DashboardTable
      fullScreenEnabled={false}
      finalizeRowData={(rowData) =>
        rowData.filter((x) => x.churn_risk_score > 50).slice(0, 10)
      }
      finalizeColDefs={(colDefs) => colDefs.filter((x) => x.field !== "action")}
      {...props}
    />
  );
}
