import React, { useState } from "react";
import "styled-components/macro";
import { useEndpoint } from "../helpers/use-endpoint";
import {
  Callout,
  Card,
  H4,
  Switch,
  Tab,
  Tabs,
  Elevation,
} from "@blueprintjs/core";
import { Spin } from "antd";
// import { ScoresAndShapesTable } from "./ScoresAndShapesTable";
import { NivoTimeSeriesLineInner } from "./AgTable/nivo-line";
import { useBoolean } from "../helpers/useBoolean";
import { PulseTop10Table } from "./PulseTop10Table";

const DEFAULT_TAB_ID = "General";

export function PulsePage() {
  const [selectedTabId, setSelectedTabId] = useState(DEFAULT_TAB_ID);
  const handleTabChange = (newTabId, prevTabId, event) => {
    setSelectedTabId(newTabId);
  };
  const { loading, data } = useEndpoint("/weekly_overall_stats");

  if (loading) return <Spin size="large" />;
  return (
    <div
      css={`
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
      `}
    >
      <Tabs
        onChange={handleTabChange}
        selectedTabId={selectedTabId}
        large
        renderActiveTabPanelOnly={true}
        css={`
          width: 100%;
          height: 100%;
          margin-top: -15px;
        `}
      >
        {ALL_TAB_INFOS.map(({ id, title, Comp, data2props }) => (
          <Tab
            key={id}
            id={id}
            title={title}
            panel={<Comp {...data2props(data)} />}
          />
        ))}
      </Tabs>
    </div>
  );
}

function NivoLineWithCumulativeSwitch({
  vanillaData,
  cumulativeData,
  ...restProps
}) {
  const isCumulative = useBoolean(false);
  const data = isCumulative.value ? { ...cumulativeData } : { ...vanillaData };
  data.data = [...data.data];
  return (
    <div>
      <Switch
        checked={isCumulative.value}
        onChange={() => {
          isCumulative.toggle();
        }}
        label="Cumulative"
        large
        inline={false}
      />
      <ClassicNivoTimeSeriesLine data={data} {...restProps} />
    </div>
  );
}

function ClassicNivoTimeSeriesLine({ data, ...restProps }) {
  return (
    <NivoTimeSeriesLineInner
      height={600}
      width={1000}
      enableSlices={false}
      enablePoints={false}
      {...data}
      {...restProps}
    />
  );
}

function Facts({ data }) {
  return (
    <div
      css={`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        & > * {
          margin: 16px 0;
          width: 31%;
        }

        & .bp3-card.bp3-card {
          padding: 20px;
        }
      `}
    >
      {data.map((d, idx) => (
        <Fact key={idx} {...d} />
      ))}
    </div>
  );
}

function Fact(props) {
  return (
    // <Card elevation={Classes.ELEVATION_2}>
    <Callout intent="primary" icon="pulse" fill={false} title={props.name}>
      {props.content}
    </Callout>
    // </Card>
  );
}

function GeneralTabPanel({ data }) {
  const height = 425;
  const width = 650;
  return (
    <div
      css={`
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 40px;
        width: 100%;
      `}
    >
      <div
        css={`
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: flex-start;

          margin: -10px -20px -20px;
          & > * {
            margin: 20px;
          }

          & .bp3-card.bp3-card {
            padding-top: 10px;
            width: fit-content;
            height: 550px;
          }
          & .bp3-card .bp3-heading {
            margin-bottom: 20px;
          }
        `}
      >
        <Card
          key={"top10"}
          elevation={Elevation.TWO}
          css={`
            width: 100% !important;
            height: fit-content !important;
          `}
        >
          <H4>{"Top-10 Value-At-Risk (>50% Churn Risk)"}</H4>
          <PulseTop10Table height={`540px`} />
        </Card>
        {CHART_TAB_INFOS.map(({ id, title, Comp, data2props }) => (
          <Card key={id} elevation={Elevation.TWO}>
            <H4>{title}</H4>
            <Comp {...data2props(data)} height={height} width={width} />
          </Card>
        ))}
      </div>
    </div>
  );
}

const CHART_TAB_INFOS = [
  {
    id: "NumTrue(has_wages)",
    title: "Participation",
    Comp: ClassicNivoTimeSeriesLine,
    data2props: (data) => ({
      data: data.nivo_lines["NumTrue(has_wages)"],
    }),
  },
  {
    id: "Sum(wages)",
    title: "Volume",
    Comp: NivoLineWithCumulativeSwitch,
    data2props: (data) => ({
      vanillaData: data.nivo_lines["Sum(wages)"],
      cumulativeData: data.nivo_lines["CumSum(wages)"],
    }),
  },
  {
    id: "NumTrue(has_wages__is_1st_true)",
    title: "Activations",
    Comp: NivoLineWithCumulativeSwitch,
    data2props: (data) => ({
      vanillaData: data.nivo_lines["NumTrue(has_wages__is_1st_true)"],
      cumulativeData: data.nivo_lines["CumNumTrue(has_wages__is_1st_true)"],
    }),
  },
  {
    id: "NumTrue(is_churn)",
    title: "Churns",
    Comp: NivoLineWithCumulativeSwitch,
    data2props: (data) => ({
      vanillaData: data.nivo_lines["NumTrue(is_churn)"],
      cumulativeData: data.nivo_lines["CumNumTrue(is_churn)"],
    }),
  },
  {
    id: "NumTrue(is_churn__is_1st_true)",
    title: "Churners",
    Comp: NivoLineWithCumulativeSwitch,
    data2props: (data) => ({
      vanillaData: data.nivo_lines["NumTrue(is_churn__is_1st_true)"],
      cumulativeData: data.nivo_lines["CumNumTrue(is_churn__is_1st_true)"],
    }),
  },
];
const ALL_TAB_INFOS = [
  {
    id: "General",
    title: "General",
    Comp: GeneralTabPanel,
    data2props: (data) => ({ data }),
  },
  ...CHART_TAB_INFOS,
];
