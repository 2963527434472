import React from "react";
import { useStoreState } from "../hooks/ep";
import { ChurnScoreAgTable } from "./explain-churn-score";
import { useEnsureData } from "../helpers/use-ensure-data";
import { COLUMN_DEFS } from "./dashboard_coldefs";
import { ReportActionModalWrapper } from "./reporting-actions/modal-or-drawer";
import { Spinner } from "@blueprintjs/core";
import { happenedToday } from "./cust-itxn-timestamp-helpers";
import { CustomerBriefingDialog } from "../customer-briefing/cb-dialog";
import _ from "lodash";
import moment from "moment-timezone";

export function DashboardTable({ fullScreenEnabled = true, ...restProps }) {
  const loading = _.max([
    useEnsureData("pready"),
    useEnsureData("stars"),
    useEnsureData("customerInteractions"),
    useEnsureData("invoiceCredits"),
  ]);
  return loading ? (
    <Spinner />
  ) : (
    <DashboardTableInner fullScreenEnabled={fullScreenEnabled} {...restProps} />
  );
}

export function useDashboardRowData() {
  return useStoreState((s) => s.pready.rowData);
}

function secondsAgo(timestamp) {
  const currentTime: moment.Moment = moment.utc();
  return currentTime.diff(moment.utc(timestamp), "s");
}

function DashboardTableInner({
  finalizeRowData = (v) => v,
  finalizeColDefs = (v) => v,
  ...restProps
}) {
  let rowData = useDashboardRowData();

  return (
    <>
      <ChurnScoreAgTable
        rowData={finalizeRowData(rowData)}
        columnDefs={finalizeColDefs(COLUMN_DEFS)}
        {...restProps}
      />
      <ReportActionModalWrapper />
      <CustomerBriefingDialog />
    </>
  );
}
