import {
  action,
  Action,
  Computed,
  computed,
  State,
  thunk,
  Thunk,
  ThunkOn,
  thunkOn,
} from "easy-peasy";
import { Injections, StoreModel } from "./model";
import {
  makeAuthorizedGetRequestToBackend,
  makeUrl,
} from "../helpers/backendApi";
import _ from "lodash";
import { happenedToday } from "../components/cust-itxn-timestamp-helpers";
import moment from "moment-timezone";

export interface CustomerData {
  CustomerName: string;
  customer_id: number;
  PrimaryContactPhoneNumber: string;
  PrimaryContactEmail: string;
  SecondaryContactPhoneNumber: string;
  SecondaryContactEmail: string;
  [index: string]: any;
}

export interface PreadyInitialData {
  rowData: CustomerData[];
  inferenceData: CustomerData[];
  columnDefs: object[];
}

export interface PreadyModel {
  NAME: string;
  INITIAL_DATA_ENDPOINT: string;
  initialData: PreadyInitialData;
  initialDataReceived: boolean;
  initialDataLoading: boolean;
  data_refreshed_at: string;
  resetData: Action<PreadyModel>;
  onLogout: ThunkOn<PreadyModel, Injections, StoreModel>;
  receiveDataRefreshedAt: Action<PreadyModel, string>;
  onLogin: ThunkOn<PreadyModel, Injections, StoreModel>;
  receiveInitialData: Action<PreadyModel, PreadyInitialData>;
  markInitialDataReceived: Action<PreadyModel>;
  maybeHandleFetchInitialData: Thunk<PreadyModel, void, Injections, StoreModel>;
  handleFetchInitialData: Thunk<PreadyModel, void, Injections, StoreModel>;
  customer2data: Computed<PreadyModel, { [customerId: number]: CustomerData }>;
  rowData: Computed<PreadyModel, CustomerData[]>;
  averageChurnScore: Computed<PreadyModel, number>;
}

const initialInitialData = () => ({
  rowData: [],
  columnDefs: [],
  inferenceData: [],
});

const preadyModel: PreadyModel = {
  INITIAL_DATA_ENDPOINT: "peopleready_customers",
  NAME: "pready",
  initialData: initialInitialData(),
  initialDataReceived: false,
  initialDataLoading: false,
  data_refreshed_at: "",
  receiveDataRefreshedAt: action((state, data_refreshed_at) => {
    state.data_refreshed_at = data_refreshed_at;
  }),
  onLogin: thunkOn(
    (actions, storeActions) => storeActions.me.receiveInitialData,
    async (actions, __, { getState }) => {
      if (!getState().data_refreshed_at) {
        const resp = await makeAuthorizedGetRequestToBackend({
          url: makeUrl("data_refreshed_at"),
        });
        actions.receiveDataRefreshedAt(resp.data);
      }
    }
  ),
  resetData: action((state) => {
    state.initialData = initialInitialData();
    state.initialDataReceived = false;
    state.initialDataLoading = false;
    state.data_refreshed_at = "";
  }),
  onLogout: thunkOn(
    (__, { me }) => me.resetData,
    (actions) => {
      actions.resetData();
    }
  ),
  receiveInitialData: action((state, payload) => {
    state.initialData = { ...state.initialData, ...payload };
  }),
  maybeHandleFetchInitialData: undefined,
  handleFetchInitialData: thunk(async (actions, payload, { getState }) => {
    const { INITIAL_DATA_ENDPOINT } = getState();

    await Promise.all([
      makeAuthorizedGetRequestToBackend({
        url: makeUrl(INITIAL_DATA_ENDPOINT),
      }).then((resp) => actions.receiveInitialData(resp.data)),
    ]);
    actions.markInitialDataReceived();
  }),
  markInitialDataReceived: undefined,
  rowData: computed(
    [
      (state) => state.initialData.rowData,
      (state, storeState: State<StoreModel>) =>
        storeState.stars.starredCustomerIds,
      (state, storeState: State<StoreModel>) =>
        storeState.customerInteractions.customerId_to_latest,
    ],
    // rowData =>
    (rowData, starredCustomerIds, customerId_to_latest) =>
      rowData.map((row) => {
        const latestInteraction = customerId_to_latest[row.customer_id];
        return {
          ...row,
          id: row.customer_id,
          starred:
            starredCustomerIds && starredCustomerIds.has(row.customer_id)
              ? 1
              : 0,
          //
          hasReportedCustomerInteraction: !!latestInteraction,
          wasCustomerInteractionToday: happenedToday(
            latestInteraction?.submitted_at
          ),
          latestInteractionTimestamp: latestInteraction?.submitted_at,
          latestInteractionSecondsAgo: secondsAgo(
            latestInteraction?.submitted_at
          ),
        };
      })
  ),
  customer2data: computed([(s) => s.rowData], (rowData) => {
    return Object.fromEntries(rowData.map((row) => [row.customer_id, row]));
  }),

  // TODO: Ensure this is memoized
  averageChurnScore: computed(
    [(state) => state.initialData.rowData],
    (rowData) => {
      return _.sumBy(rowData, "churn_risk_score") / rowData.length;
    }
  ),
};

function secondsAgo(timestamp) {
  const currentTime: moment.Moment = moment.utc();
  return currentTime.diff(moment.utc(timestamp), "s");
}

export default preadyModel;
