import React from "react";
import "styled-components/macro";
import { SimpleRadioGroup } from "../simple-radio";

export type ChurnLikelihoodValue = "Unlikely" | "Unknown" | "Likely";
export const ALL_CHURN_LIKELIHOOD_VALUES: ChurnLikelihoodValue[] = [
  "Likely",
  "Unknown",
  "Unlikely",
];
const FIELD_LABEL = "Churn Likelihood";
const FIELD_ID = "churnLikelihood";
const HELPER_TEXT =
  "After your call, how likely do you think the customer is to churn from PeopleReady?";

export function ChurnLikelihoodInput({
  value,
  onValueChange,
  activeField,
  setActiveField,
}): JSX.Element {
  return (
    <SimpleRadioGroup
      selectedValue={value}
      setSelectedValue={onValueChange}
      allValues={ALL_CHURN_LIKELIHOOD_VALUES}
      fieldLabel={FIELD_LABEL}
      fieldHelperText={HELPER_TEXT}
      fieldId={FIELD_ID}
      activeFieldId={activeField}
      setActiveFieldId={setActiveField}
    />
  );
}
