import { useBoolean } from "../../helpers/useBoolean";
import React from "react";
import styled from "styled-components/macro";
import "styled-components/macro";
import {
  makeAuthorizedDeleteRequestToBackend,
  makeAuthorizedPostRequestToBackend,
  makeUrl,
} from "../../helpers/backendApi";
import { AppToaster } from "../../toaster";
import { Button, Icon as BP3Icon, Intent } from "@blueprintjs/core";
import * as COLORS from "../../styles/colors"; // DO NOT REMOVE. Necessary for using the css={`...`} prop

const useStarUnstarActions = (customerId1) => ({
  unstarCustomer: async (customerId2) =>
    await makeAuthorizedDeleteRequestToBackend({
      url: makeUrl(`stars/${customerId1 || customerId2}`),
      axiosConfig: undefined,
    }),
  starCustomer: async (customerId2) =>
    await makeAuthorizedPostRequestToBackend({
      url: makeUrl(`stars/${customerId1 || customerId2}`),
      data: {},
      axiosConfig: undefined,
    }),
});

const YellowIcon = ({ isStarred, ...restProps }) => (
  <BP3Icon
    css={`
      & {
        color: ${isStarred
          ? "rgba(242,184,36, 0.8)"
          : "rgba(242,184,36, 0.4)"} !important;
      }
      &:hover {
        color: ${isStarred
          ? "rgba(242,184,36, 0.4)"
          : "rgba(242,184,36, 0.8)"} !important;
      }
    `}
    icon={isStarred ? "star" : "star-empty"}
    {...restProps}
  />
);

const DivToPutIconInMiddleOfCell = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
`;

export function StarCellRenderer({ value, data, api }) {
  const customerId = data.customer_id;
  // noinspection JSUnresolvedVariable
  const customerName = data.CustomerName;

  // const starred = useIsStarred(customerId);
  const starred = useBoolean(value);

  const { starCustomer, unstarCustomer } = useStarUnstarActions(customerId);

  const onClick = async (ev) => {
    ev.preventDefault();
    if (!starred.value) {
      try {
        starred.setTrue();
        await starCustomer(customerId);
        AppToaster.show({
          message: `Successfully starred ${customerName}`,
          intent: Intent.SUCCESS,
          timeout: 1000,
        });
        api.updateRowData({ update: [{ ...data, starred: 1 }] });
      } catch (e) {
        starred.setFalse();
        AppToaster.show({
          message: `Failed to star ${customerName}`,
          intent: Intent.DANGER,
          timeout: 3000,
        });
      }
    } else {
      try {
        starred.setFalse();
        await unstarCustomer(customerId);
        AppToaster.show({
          message: `Successfully un-starred ${customerName}`,
          intent: Intent.SUCCESS,
          timeout: 1000,
        });
        api.updateRowData({ update: [{ ...data, starred: 0 }] });
      } catch (e) {
        starred.setTrue();
        AppToaster.show({
          message: `Failed to un-star ${customerName}`,
          intent: Intent.DANGER,
          timeout: 3000,
        });
      }
    }
  };

  return (
    <DivToPutIconInMiddleOfCell>
      <Button
        minimal
        small
        large={false}
        icon={<YellowIcon isStarred={starred.value} />}
        onClick={onClick}
      />
    </DivToPutIconInMiddleOfCell>
  );
}
