import {
  GetRowId,
  readonlyTableModelFactory,
  TableModel,
  TableRow,
  TableRowId,
} from "./readonly-table-model-factory";
import { Actions, Computed, computed, Thunk, thunk } from "easy-peasy";
import {
  ActionPayload_ReportCustomerInteraction,
  reportCustomerInteraction,
} from "../user-actions/report-customer-interaction";
import _ from "lodash";
import { toaster } from "../toaster";
import { UserAction } from "../user-actions/user_actions";
import { Injections, StoreModel } from "./model";

export interface CustomerInteractionsModel extends TableModel {
  handleReportCustomerInteraction: Thunk<
    CustomerInteractionsModel,
    ActionPayload_ReportCustomerInteraction,
    Injections,
    StoreModel
  >;
  byCustomerId: Computed<
    CustomerInteractionsModel,
    { [key: number]: UserAction[] },
    StoreModel
  >;
  customerId_to_latest: Computed<
    CustomerInteractionsModel,
    { [key: number]: UserAction },
    StoreModel
  >;
  rowIds: Computed<CustomerInteractionsModel, Set<TableRowId>, StoreModel>;
  rowExists: Computed<
    CustomerInteractionsModel,
    { (row: TableRow): boolean },
    StoreModel
  >;
}

export function getCustomerInteractionsModel(): CustomerInteractionsModel {
  return {
    ...readonlyTableModelFactory(
      "customerInteractions",
      "customer_interactions",
      (row) => row.id
    ),
    handleReportCustomerInteraction: thunk(
      async (
        actions: Actions<CustomerInteractionsModel>,
        userActionPayload: ActionPayload_ReportCustomerInteraction,
        { getState }
      ) => {
        const state = getState();
        // @ts-ignore
        const maxRowId = _.max([...state.rowIds]) ?? 0;
        const rowId = state.GET_ROW_ID(userActionPayload);
        const oldRow = state.getRow(rowId);
        actions.upsertRow({
          id: maxRowId + 1,
          action_payload: userActionPayload,
        });
        try {
          const { data } = await reportCustomerInteraction(userActionPayload);
          toaster.success("Successfully reported customer interaction", 2);
          actions.upsertRow(data);
        } catch (e) {
          if (oldRow) {
            actions.upsertRow(oldRow);
          } else {
            actions.deleteRow(rowId);
          }
          toaster.warning(
            "Failed to report customer interaction -- please try again"
          );
          throw Error();
        }
      }
    ),
    byCustomerId: computed(
      [(s) => s.initialData],
      (initialData: UserAction[]) =>
        _.groupBy(initialData, "action_payload.customer_snapshot.customer_id")
    ),
    customerId_to_latest: computed(
      [(s) => s.byCustomerId],
      (byCustomerId: { [key: string]: UserAction[] }) =>
        Object.fromEntries(
          Object.entries(byCustomerId).map(([customerId, itxns]) => [
            customerId,
            _.maxBy(itxns, "submitted_at"),
          ])
        )
    ),
    rowIds: computed(
      [(s) => s.initialData, (s) => s.GET_ROW_ID],
      (initialData: TableRow[], GET_ROW_ID: GetRowId) =>
        new Set(initialData.map((row: UserAction) => GET_ROW_ID(row)))
    ),
    rowExists: computed(
      [(s) => s.rowIds, (s) => s.GET_ROW_ID],
      (rowIds: Set<TableRowId>, GET_ROW_ID: GetRowId) => (row: UserAction) =>
        rowIds.has(GET_ROW_ID(row))
    ),
  };
}
