import routes from "./routes";
import React from "react";
import { Icon as BP3Icon } from "@blueprintjs/core";
import { Menu } from "antd";
import { DashboardOutlined } from "@ant-design/icons";

export const leftNavMenuItems = [
  {
    urlPath: routes.dailyQueue,
    title: "Daily Queue",
    icon: <BP3Icon icon={"numbered-list"} />,
  },
  {
    urlPath: routes.dashboard,
    title: "Dashboard",
    icon: <DashboardOutlined />,
  },
  {
    urlPath: routes.customerInteractions,
    title: "Reported Customer Interactions",
    icon: <BP3Icon icon={"chat"} />,
  },
  {
    urlPath: routes.pulse,
    title: "Pulse",
    icon: <BP3Icon icon={"pulse"} />,
  },
];

export function LeftNavMenuItem({ urlPath, title, icon, history }) {
  return (
    <Menu.Item
      key={urlPath || title}
      title={title}
      onClick={() => urlPath && history.push(urlPath)}
      data-testid={`leftnav-${urlPath}`}
    >
      {icon}
    </Menu.Item>
  );
}
