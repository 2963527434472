import React from "react";
import { Button, Position, Tooltip } from "@blueprintjs/core";
import { useStoreActions } from "../../hooks/ep";

export function ReportCustomerInteractionButtonCellRenderer({ data, context }) {
  const openDrawer = useStoreActions((a) => a.misc.ra_openModal);

  const reportFactory = (actionName) => () => {
    const fullAction = { actionName: actionName, snapshot: data };
    openDrawer(fullAction);
  };
  const reportCustomerInteraction = reportFactory(
    "report_customer_interaction"
  );

  return (
    <span>
      <Tooltip
        content="Report a Customer Interaction"
        position={Position.TOP_LEFT}
        hoverOpenDelay={500}
      >
        <Button
          minimal
          small
          icon="chat"
          large={false}
          intent="primary"
          onClick={reportCustomerInteraction}
        />
      </Tooltip>
    </span>
  );
}
