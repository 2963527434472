import { KeyboardEvent, useCallback } from "react";
import _ from "lodash";
import { useSetSearchBoxText } from "./search-box-store";
import { SetQuickFilterText } from "./types";

export function useExportToCsv(gridRef) {
  return useCallback(() => {
    // noinspection JSUnresolvedVariable
    gridRef.current.gridOptions.api.exportDataAsCsv({
      skipHeader: false,
      columnGroups: true,
      skipFooters: false,
      skipGroups: false,
      skipPinnedTop: false,
      skipPinnedBottom: false,
      allColumns: true,
      onlySelected: false,
      suppressQuotes: false,
      shouldRowBeSkipped: () => false,
      processCellCallback: (param) => param.value,
      processHeaderCallback: null,
    });
  }, [gridRef]);
}

export function useAutoSizeAll(gridRef) {
  return useCallback(() => {
    // @ts-ignore
    const isCypressRuntime = !!window.Cypress;
    if (!gridRef.current || isCypressRuntime) {
      return;
    }
    const columnApi = gridRef.current.gridOptions.columnApi;
    const allColumnIds = columnApi
      .getAllColumns()
      .filter((column) => _.isUndefined(column.userProvidedColDef.width)) // If user supplied column width, then leave it be
      .map((column) => column.colId);
    // console.log("autoSizing");
    columnApi.autoSizeColumns(allColumnIds);
  }, [gridRef]);
}

export function useGetAllGridColumns(gridRef) {
  return useCallback(() => {
    if (gridRef.current === undefined) {
      return [];
    } else {
      return gridRef.current.columnApi.getAllGridColumns();
    }
  }, [gridRef]);
}

export function useGetIsColumnVisible(gridRef) {
  return useCallback(
    (colId) => {
      return gridRef.current
        ? gridRef.current.columnApi.getColumn(colId).visible
        : null;
    },
    [gridRef]
  );
}

export function useGetAreAllColumnsVisible(gridRef) {
  const getAllGridColumns = useGetAllGridColumns(gridRef);
  return useCallback(
    () => _.every(getAllGridColumns().map((col) => col.visible)),
    [getAllGridColumns]
  );
}

export function useSetQuickFilterText(gridRef): SetQuickFilterText {
  const setText = useSetSearchBoxText();
  return useCallback(
    (params: string | KeyboardEvent<HTMLInputElement>, option?: any) => {
      const text =
        typeof params === "string" ? params : params.currentTarget.value;
      console.log({ params, text, option });
      setText(text);
      if (!gridRef.current) {
        return;
      }
      gridRef.current.api.setQuickFilter(text);
    },
    [setText, gridRef]
  );
}
