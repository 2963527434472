import React from "react";
import { Tag } from "@blueprintjs/core";
import { useClipboard } from "use-clipboard-copy";
import "styled-components/macro";

export function PhoneActionButtonCellRenderer({ data }) {
  return (
    <span>
      <CopyablePhoneTag phoneNumber={data["formatted_contact_phone_number"]} />
    </span>
  );
}

export function formatPhoneNumber(s) {
  if (!s) {
    return "-Unknown-";
  }
  const sz = s.length;
  const last4 = s.slice(sz - 4, sz);
  const first3 = s.slice(sz - 7, sz - 4);
  const areaCode = s.slice(sz - 10, sz - 7);
  const countryCode = sz > 10 ? s.slice(0, sz - 10) : "1";
  const f_countryCode = countryCode ? `+${countryCode} ` : "";
  const prefix = f_countryCode + (areaCode && `(${areaCode})`) + " ";
  return `${prefix}${first3}-${last4}`;
}

export function CopyablePhoneTag({ phoneNumber }) {
  const clipboard = useClipboard({ copiedTimeout: 500 });
  const onClick = () => clipboard.copy(phoneNumber);
  return (
    <Tag
      css={`
        height: 26px;
        & .bp3-icon {
          margin-right: 6px;
        }
      `}
      fill={true}
      minimal={true}
      intent={"primary"}
      large={false}
      icon="phone"
      interactive={true}
      onClick={onClick}
    >
      {clipboard.copied ? "Copied!" : phoneNumber}
    </Tag>
  );
}
