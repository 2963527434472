import React from "react";
import "styled-components/macro";
import { SimpleRadioGroup } from "../simple-radio";

export type UnderSalesforceReviewValue = "Yes" | "No";
export const ALL_UNDER_SALESFORCE_REVIEW_VALUES: UnderSalesforceReviewValue[] = [
  "Yes",
  "No",
];
const FIELD_LABEL = "Under Salesforce Review";
const FIELD_ID = "underSalesforceReview";
const HELPER_TEXT = "Is this customer currently under review in Salesforce?";

export function UnderSalesforceReviewInput({
  value,
  onValueChange,
  activeField,
  setActiveField,
}): JSX.Element {
  return (
    <SimpleRadioGroup
      selectedValue={value}
      setSelectedValue={onValueChange}
      allValues={ALL_UNDER_SALESFORCE_REVIEW_VALUES}
      fieldLabel={FIELD_LABEL}
      fieldHelperText={HELPER_TEXT}
      fieldId={FIELD_ID}
      activeFieldId={activeField}
      setActiveFieldId={setActiveField}
    />
  );
}
