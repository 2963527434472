import styled from "styled-components/macro";
import "react";
import { FormGroup } from "@blueprintjs/core";

function opacityFn({ activeField, labelFor }) {
  return activeField === labelFor ? 1 : 0;
}

export const StyledFormGroup = styled(FormGroup)`
  & .bp3-form-helper-text {
    opacity: ${opacityFn};
    transition: opacity 0.4s ease-in;
  }
`;
