import {
  Icon as BP3Icon,
  Intent,
  Popover,
  PopoverInteractionKind,
  Position,
  ProgressBar,
  Tag,
} from "@blueprintjs/core";
import React from "react";
import styled from "styled-components/macro";
import { Col, Row } from "antd";
// import { getScoreInfo } from "../../../helpers/get-score-info";
import { useCustomerRow } from "../../../helpers/use-customer-row";
import _ from "lodash";
import { moneyVF, timestampComparator } from "../gridOptions";
import { useStoreState } from "../../../hooks/ep";
import { InvoiceCreditsTable } from "../../invoice-credits";

const YesChurnIcon = () => <BP3Icon icon="warning-sign" intent="danger" />;
const NoChurnIcon = () => <BP3Icon icon="shield" intent="success" />;
const ScoFacExplanation = styled.div`
  margin-bottom: 8px;
`;

function ScoFacIcon({ contrib }) {
  return contrib > 0 ? <YesChurnIcon /> : <NoChurnIcon />;
}

function ScoFacBar({ contrib, maxContrib }) {
  return (
    <ProgressBar
      animate={false}
      stripes={false}
      intent={contrib < 0 ? Intent.SUCCESS : Intent.DANGER}
      value={Math.abs(contrib / maxContrib)}
    />
  );
}

function ScoreFactor({ name, contrib, valueExplanation, maxContrib }) {
  return (
    <Row
      className="feature-contribution-row"
      key={name}
      type="flex"
      justify="space-between"
      align="middle"
      gutter={16}
    >
      <Col>
        <ScoFacIcon contrib={contrib} />
      </Col>
      <Col
        css={`
          flex-grow: 1;
        `}
      >
        <ScoFacExplanation>{valueExplanation}</ScoFacExplanation>
        <ScoFacBar contrib={contrib} maxContrib={maxContrib} />
      </Col>
    </Row>
  );
}

const ScoFacWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > .feature-contribution-row {
    margin: 24px 0 0 0 !important;
  }
  & > .feature-contribution-row:first-child {
    margin-top: 0 !important;
  }
`;

function getScoreInfoItem(key, customerRow) {
  let ret = {};

  const billingState = customerRow["BillingState"];
  const revenueSeg = customerRow["RevenueSegmentation"];
  const verticalSeg = customerRow["VerticalSegmentation"];
  const creditLimit = customerRow["CustomerCreditLimit"];
  const churnRiskScore = customerRow["churn_risk_score"];

  if (key === "churn_risk_score") {
    if (churnRiskScore < 50.0) {
      ret = {
        contrib: (-2.0 * (50.0 - churnRiskScore)) / 100.0,
        valueExplanation: "Positive recent sales volume trends",
      };
    } else {
      ret = {
        contrib: (2.0 * (churnRiskScore - 50.0)) / 100.0,
        valueExplanation: "Negative recent sales volume trends",
      };
    }
  } else if (key === "good__rev_seg") {
    ret = {
      contrib: -0.5,
      valueExplanation: `Revenue seg is "${revenueSeg}"`, //Medium or Large`,
    };
  } else if (key === "bad__rev_seg") {
    ret = {
      contrib: 0.5,
      valueExplanation: `Revenue seg is "${revenueSeg}"`, //Small or Prime/Retail",
    };
  } else if (key === "good__vert_seg") {
    ret = {
      contrib: -0.25,
      valueExplanation: `Vertical seg is "${verticalSeg}"`, //Government, Transportation, or Hospitality",
    };
  } else if (key === "bad__vert_seg") {
    ret = {
      contrib: 0.25,
      valueExplanation: `Vertical seg is "${verticalSeg}"`, //Agricultural, Other, or Unclassified",
    };
  } else if (key === "good__serv_seg") {
    ret = {
      contrib: -0.6,
      valueExplanation: 'Service seg is "National"',
    };
  } else if (key === "bad__serv_seg") {
    ret = {
      contrib: 0.1,
      valueExplanation: 'Service seg is "COD"',
    };
  } else if (key === "good__state") {
    ret = {
      contrib: -0.15,
      valueExplanation: `Location state is "${billingState}"`, //NM, KY, WI, IN, LA, or WA",
    };
  } else if (key === "bad__state") {
    ret = {
      contrib: 0.15,
      valueExplanation: `Location state is "${billingState}"`, //WY, SD, CO, HI, AZ, NV, KS, NE, CT, or MD",
    };
  } else if (key === "good__credit_limit") {
    ret = {
      contrib: -0.65,
      valueExplanation: `High credit limit of ${moneyVF(creditLimit)}`,
    };
  } else if (key === "bad__jobsite_distance") {
    ret = {
      contrib: 0.55,
      valueExplanation: "Job site typically more than 20 miles from branch",
    };
  } else if (key === "bad__is_project_based") {
    ret = {
      contrib: 0.24,
      valueExplanation: "Seasonal or project-based",
    };
  } else if (key === "bad__is_covid_impacted") {
    ret = {
      contrib: 0.22,
      valueExplanation: "COVID-impacted",
    };
  } else if (key === "bad__recent_invoice_credits__is_timecard_fraud") {
    ret = {
      contrib: 0.54,
      valueExplanation: "Timecard fraud issue within past 90 days",
    };
  } else if (key === "bad__recent_invoice_credits__is_timecard_forgery") {
    ret = {
      contrib: 0.52,
      valueExplanation: "Timecard forgery issue within past 90 days",
    };
  } else if (key === "bad__recent_invoice_credits__is_labor_credit") {
    ret = {
      contrib: 0.27,
      valueExplanation:
        "Labor credit for incorrect timecard within past 90 days",
    };
  } else if (key === "bad__recent_invoice_credits__is_worker_issue") {
    ret = {
      contrib: 0.81,
      valueExplanation: "Worker quality issue within past 90 days",
    };
  } else if (key === "bad__recent_invoice_credits__is_billing_issue") {
    ret = {
      contrib: 0.37,
      valueExplanation: "Billing / invoicing issue within past 90 days",
    };
  } else if (key === "bad__recent_invoice_credits__is_collections_dispute") {
    ret = {
      contrib: 0.61,
      valueExplanation: "Collections dispute within past 90 days",
    };
  } else if (key === "good__recent_invoice_credits__is_worker_issue") {
    ret = {
      contrib: -0.81,
      valueExplanation: "No worker quality issues within past 90 days",
    };
  } else if (key === "good__recent_invoice_credits__is_billing_issue") {
    ret = {
      contrib: -0.37,
      valueExplanation: "No billing / invoicing issues within past 90 days",
    };
  } else if (key === "good__recent_invoice_credits__is_collections_dispute") {
    ret = {
      contrib: -0.61,
      valueExplanation: "No collections disputes within past 90 days",
    };
  } else if (key === "bad__recent_invoice_credits") {
    ret = {
      contrib: 0.8,
      valueExplanation: (
        <span>
          <RelatedInvoiceCreditsTag customerId={customerRow.customer_id}>
            {customerRow.num_invoice_credits_in_past_90_days}
          </RelatedInvoiceCreditsTag>
          <span>{` invoice credits issued within past 90 days`}</span>
        </span>
      ),
    };
  }
  ret = { ...ret, name: key, humanName: key, value: customerRow[key] };
  // console.log(ret);
  return ret;
}

function RelatedInvoiceCreditsTag({ customerId, children }) {
  const invoiceCreditRows = useStoreState(
    (s) => s.invoiceCredits.byCustomerId[customerId]
  );
  const invoiceCreditIds = invoiceCreditRows
    .filter((row) => row.credit_posted_within_past_90_days)
    .map((row) => row.id);
  return (
    <Popover
      autoFocus={false}
      usePortal={true}
      boundary={"viewport"}
      position={Position.BOTTOM}
      content={
        <div
          css={`
            padding: 0;
            width: 50vw;
          `}
        >
          <InvoiceCreditsTable
            finalizeRowData={(rowData) =>
              sortBy(
                rowData.filter((row) => invoiceCreditIds.includes(row.id)),
                timestampComparator,
                true
              )
            }
            height={"40vh"}
            fullScreenEnabled={false}
          />
        </div>
      }
      interactionKind={PopoverInteractionKind.CLICK}
    >
      <Tag interactive={true} intent={Intent.DANGER}>
        {children}
      </Tag>
    </Popover>
  );
}

function sortBy(arr, cmp, reverse = false) {
  arr = arr.sort(cmp);
  if (reverse) {
    arr = arr.reverse();
  }
  return arr;
}

function getScoreInfo(customerRow) {
  // console.log(customerRow);
  let ret = [getScoreInfoItem("churn_risk_score", customerRow)];
  Object.entries(customerRow).forEach(([key, val]) => {
    if (val && (_.startsWith(key, "good__") || _.startsWith(key, "bad__"))) {
      ret.push(getScoreInfoItem(key, customerRow));
    }
  });
  ret = ret.filter((v) => !_.isUndefined(v.contrib));
  ret = _.sortBy(ret, ({ contrib }) => -Math.abs(contrib));
  return ret;
}

export function ScoreFactors({ customerId }) {
  const customerRow = useCustomerRow(customerId);
  const scoreInfo = getScoreInfo(customerRow);
  const top3_scoreInfo = scoreInfo.filter(
    ({ valueExplanation }) => !!valueExplanation
  );
  return (
    <ScoFacWrapper>
      {/*<PopConHeader>Score Factors</PopConHeader>*/}
      {top3_scoreInfo.map((info, idx) => (
        <ScoreFactor key={idx} maxContrib={1.0} {...info} />
      ))}
    </ScoFacWrapper>
  );
}
