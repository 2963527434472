import { action, computed, createContextStore, persist } from "easy-peasy";
import _ from "lodash";
import { pushFactory, setFactory, setRadioFactory } from "./helpers";
import "styled-components/macro";
import { getCustomerInteractionFormErrorMessage } from "./form-error-messages";

function model({ key, actionName }) {
  const ret = {
    callTopicsQueryText: "",
    setCallTopicsQueryText: setFactory("callTopicsQueryText"),
    //
    callTopics: [],
    pushCallTopic: pushFactory("callTopics"),
    clearCallTopics: action((state) => {
      state.callTopics = [];
    }),
    removeCallTopic: action((state, reason) => {
      const codeName = _.isString(reason) ? reason : reason.codeName;
      const longName = _.isString(reason) ? reason : reason.longName;
      state.callTopics = state.callTopics.filter(
        (r) => r.codeName !== codeName && r.longName !== longName
      );
    }),
    //
    //
    customerIssuesQueryText: "",
    setCustomerIssuesQueryText: setFactory("customerIssuesQueryText"),
    //
    customerIssues: [],
    pushCustomerIssue: pushFactory("customerIssues"),
    clearCustomerIssues: action((state) => {
      state.customerIssues = [];
    }),
    removeCustomerIssue: action((state, reason) => {
      const codeName = _.isString(reason) ? reason : reason.codeName;
      const longName = _.isString(reason) ? reason : reason.longName;
      state.customerIssues = state.customerIssues.filter(
        (r) => r.codeName !== codeName && r.longName !== longName
      );
    }),
    //
    //
    notes: "",
    setNotes: setFactory("notes"),
    phone: "",
    setPhone: setFactory("phone"),
    //
    //
    underSalesforceReview: null,
    setUnderSalesforceReview: setRadioFactory("underSalesforceReview"),
    //
    //
    customerSentiment: null,
    setCustomerSentiment: setRadioFactory("customerSentiment"),
    //
    //
    churnLikelihood: null,
    setChurnLikelihood: setRadioFactory("churnLikelihood"),
    //
    //
    salesforceCaseOpened: null,
    setSalesforceCaseOpened: setRadioFactory("salesforceCaseOpened"),
    //
    salesforceCaseUrl: "",
    setSalesforceCaseUrl: setFactory("salesforceCaseUrl"),
    //
    //
    attendees: "",
    setAttendees: setFactory("attendees"),
    //
    //
    //
    formData: computed(
      ({
        underSalesforceReview,
        callTopics,
        customerIssues,
        notes,
        phone,
        customerSentiment,
        churnLikelihood,
        salesforceCaseOpened,
        salesforceCaseUrl,
        attendees,
      }) => ({
        underSalesforceReview,
        callTopics,
        customerIssues,
        notes,
        phone,
        customerSentiment,
        churnLikelihood,
        salesforceCaseOpened,
        salesforceCaseUrl,
        attendees,
      })
    ),
    errorMessage: computed(getCustomerInteractionFormErrorMessage),
  };
  return persist({ [key]: ret });
}

export const FormStore = createContextStore(model);
