import _ from "lodash";

const FEAT_CONTRIB_FIELD_PATT = /^C:\s(?<featname>.+)$/;
const FEAT_VALUE_FIELD_PATT = /^X:\s(?<featname>.+)$/;
const SCORE_INFO_FIELD_PATT = /^[CX]:\s(?<featname>.+)$/;

function getFieldType(field) {
  if (FEAT_CONTRIB_FIELD_PATT.exec(field)) {
    return "contrib";
  } else if (FEAT_VALUE_FIELD_PATT.exec(field)) {
    return "value";
  } else {
    return null;
  }
}

function getFeatureName(field) {
  return SCORE_INFO_FIELD_PATT.exec(field).groups.featname;
}

export const Features = {
  stddev_days_prev_week: {
    full_name:
      "STD(FT_WorkerPayrollDetail.ORDINAL_TO_NUMERIC(WEEKDAY(WorkDate)), Last 1 Weeks)",
    human_name: "Variety of Days Worked (past 1 week)",
  },
  days_since_last_workdate: {
    full_name:
      "TIME_SINCE(LAST(FT_WorkerPayrollDetail.WorkDate WHERE AND(Amount > 0.0, NOT(TimecardvoidedFlag))), unit=days)",
    human_name: "Days Since Last Paid Work Date",
  },
  wkly_avg_payable_hrs_past_2wk: {
    full_name:
      "SUM(FT_WorkerPayrollDetail.PayableHours WHERE AND(Amount > 0.0, NOT(TimecardvoidedFlag)), Last 2 Weeks) / 2",
    human_name: "Weekly Avg Payable Hours (past 2 weeks)",
  },
  avg_days_between_payments_past_4wks: {
    full_name:
      "AVG_TIME_BETWEEN(FT_WorkerPayrollDetail.CalculationDate WHERE AND(Amount > 0.0, NOT(TimecardvoidedFlag)), Last 4 Weeks, unit=days)",
    human_name: "Avg Days Between Payments (past 4 weeks)",
  },
};

const FULL_NAME__TO__HUMAN_NAME = Object.values(Features).reduce(
  (prev, curr) => ({
    ...prev,
    [curr.full_name]: curr.human_name,
  }),
  {}
);

export function explainFeatureValue({ name, value }) {
  if (name === Features.stddev_days_prev_week.full_name) {
    if (_.isNaN(value) || _.isNull(value) || _.isUndefined(value)) {
      return "No payments made for work done in past week";
    } else if (value === 0) {
      return "Work was done on single day in past week";
    } else {
      return "Work was done on multiple days in past week";
    }
  } else if (name === Features.days_since_last_workdate.full_name) {
    if (value < 14) {
      return "Latest paid work date was 1-14 days ago";
    } else if (value >= 14 && value < 24) {
      return "Latest paid work date was 14-24 days ago";
    } else {
      return "Latest paid work date was >24 days ago";
    }
  } else if (name === Features.wkly_avg_payable_hrs_past_2wk.full_name) {
    if (value === 0) {
      return "No payable hours in past 2 weeks";
    } else if (value <= 2) {
      return "Trailing avg of 1-2 paid hrs/wk in past 2 wks";
    } else if (value <= 8) {
      return "Trailing avg of 2-8 paid hrs/wk in past 2 wks";
    } else {
      return "Trailing avg of >8 paid hrs/wk in past 2 wks";
    }
  } else if (name === Features.wkly_avg_payable_hrs_past_2wk.full_name) {
    if (value === 0) {
      return "No payable hours in past 2 weeks";
    } else if (value <= 2) {
      return "Trailing avg of 1-2 paid hrs/wk in past 2 wks";
    } else if (value <= 8) {
      return "Trailing avg of 2-8 paid hrs/wk in past 2 wks";
    } else {
      return "Trailing avg of >8 paid hrs/wk in past 2 wks";
    }
  } else if (name === Features.avg_days_between_payments_past_4wks.full_name) {
    if (value === 0) {
      return "Single payment made in past month";
    } else if (value <= 2) {
      return "More than one payment made in past month";
    }
  }
}

export function getScoreInfo(customerRow) {
  const featureRows = {};
  Object.entries(customerRow)
    .filter(([field]) => !!getFieldType(field))
    .map(([field, featureValue]) => ({
      featureName: getFeatureName(field),
      fieldType: getFieldType(field),
      featureValue,
    }))
    .filter(({ featureName }) => !!FULL_NAME__TO__HUMAN_NAME[featureName])
    .forEach(({ featureName, fieldType, featureValue }) => {
      featureRows[featureName] = {
        [fieldType]: featureValue,
        humanName: FULL_NAME__TO__HUMAN_NAME[featureName],
        ...(featureRows[featureName] || {}),
      };
    });

  const scoreInfo = Object.entries(featureRows).map(
    ([name, { contrib, value, humanName }]) => ({
      name,
      humanName,
      value,
      contrib,
      valueExplanation: explainFeatureValue({ name, value }),
    })
  );
  return _.sortBy(scoreInfo, ({ contrib }) => -Math.abs(contrib));
}
