import {
  GetRowId,
  readonlyTableModelFactory,
  TableModel,
  TableRow,
  TableRowId,
} from "./readonly-table-model-factory";
import { Actions, Computed, computed, Thunk, thunk } from "easy-peasy";
import {
  ActionPayload_ReportCustomerInteraction,
  reportCustomerInteraction,
} from "../user-actions/report-customer-interaction";
import _ from "lodash";
import { toaster } from "../toaster";
import { UserAction } from "../user-actions/user_actions";
import { Injections, StoreModel } from "./model";

export interface InvoiceCreditsModel extends TableModel {
  byCustomerId: Computed<
    InvoiceCreditsModel,
    { [key: number]: UserAction[] },
    StoreModel
  >;
  rowIds: Computed<InvoiceCreditsModel, Set<TableRowId>, StoreModel>;
  rowExists: Computed<
    InvoiceCreditsModel,
    { (row: TableRow): boolean },
    StoreModel
  >;
}

export function getInvoiceCreditsModel(): InvoiceCreditsModel {
  return {
    ...readonlyTableModelFactory(
      "invoiceCredits",
      "invoice_credits",
      (row) => row.ID
    ),
    byCustomerId: computed(
      [(s) => s.initialData],
      (initialData: UserAction[]) => _.groupBy(initialData, "customer_id")
    ),
    rowIds: computed(
      [(s) => s.initialData, (s) => s.GET_ROW_ID],
      (initialData: TableRow[], GET_ROW_ID: GetRowId) =>
        new Set(initialData.map((row: UserAction) => GET_ROW_ID(row)))
    ),
    rowExists: computed(
      [(s) => s.rowIds, (s) => s.GET_ROW_ID],
      (rowIds: Set<TableRowId>, GET_ROW_ID: GetRowId) => (row: UserAction) =>
        rowIds.has(GET_ROW_ID(row))
    ),
  };
}
