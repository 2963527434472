import React from "react";
import "styled-components/macro";
import { SimpleRadioGroup } from "../simple-radio";

export type SalesforceCaseOpenedValue = "Yes" | "No";
export const ALL_SALESFORCE_CASE_OPENED_VALUES: SalesforceCaseOpenedValue[] = [
  "Yes",
  "No",
];
const FIELD_LABEL = "Salesforce Case Opened";
const FIELD_ID = "salesforceCaseOpened";
const HELPER_TEXT =
  "Did you open a Case in Salesforce as a result of this call?";

export function SalesforceCaseOpenedInput({
  value,
  onValueChange,
  activeField,
  setActiveField,
}): JSX.Element {
  return (
    <SimpleRadioGroup
      selectedValue={value}
      setSelectedValue={onValueChange}
      allValues={ALL_SALESFORCE_CASE_OPENED_VALUES}
      fieldLabel={FIELD_LABEL}
      fieldHelperText={HELPER_TEXT}
      fieldId={FIELD_ID}
      activeFieldId={activeField}
      setActiveFieldId={setActiveField}
    />
  );
}
