import { Button, MenuItem, Position } from "@blueprintjs/core";
import React, { useLayoutEffect, useRef } from "react";
import { MultiSelect } from "@blueprintjs/select";
import "styled-components/macro";

const ENTER_RETURN_KEY_CODE = 13;

export type CustomerIssueCodeName =
  | "billing_invoicing__general"
  | "credit_limit__general"
  | "jobstack_report__general"
  | "filling__underfilled"
  | "filling__overfilled"
  | "branch__unresponsive"
  | "branch__unprofessional"
  | "worker__unprofessional"
  | "worker__no_show"
  | "worker__poor_work_quality"
  | "worker__untrained"
  | "labor_needs__seasonal"
  | "labor_needs__project_based"
  | "covid19__general"
  | "pricing__too_high"
  | "other"
  | "no_issues";

export interface CustomerIssue {
  codeName: CustomerIssueCodeName;
  longName: string;
}

export const CustomerIssuesMS = MultiSelect.ofType<CustomerIssue>();

const DEFAULT_REASONS: CustomerIssue[] = [
  {
    codeName: "billing_invoicing__general",
    longName: "Billing/Invoicing (General)",
  },
  { codeName: "credit_limit__general", longName: "Credit Limit (General)" },
  {
    codeName: "jobstack_report__general",
    longName: "JobStack Report (General)",
  },
  { codeName: "filling__underfilled", longName: "Filling (Underfilled)" },
  { codeName: "filling__overfilled", longName: "Filling (Overfilled)" },
  { codeName: "branch__unresponsive", longName: "Branch (Unresponsive)" },
  { codeName: "branch__unprofessional", longName: "Branch (Unprofessional)" },
  { codeName: "worker__unprofessional", longName: "Worker (Unprofessional)" },
  { codeName: "worker__no_show", longName: "Worker (No-Show)" },
  {
    codeName: "worker__poor_work_quality",
    longName: "Worker (Poor Quality-of-Work)",
  },
  { codeName: "worker__untrained", longName: "Worker (Untrained)" },
  {
    codeName: "labor_needs__seasonal",
    longName: "Labor Needs (Seasonal)",
  },
  {
    codeName: "labor_needs__project_based",
    longName: "Labor Needs (Project-Based)",
  },
  { codeName: "covid19__general", longName: "Covid-19 (General)" },
  { codeName: "pricing__too_high", longName: "Pricing (Too High)" },
  { codeName: "other", longName: "Other" },
  { codeName: "no_issues", longName: "No Issues" },
];

export function CustomerIssuesInput({
  queryText,
  setQueryText,
  selectedItems,
  onAdd,
  onClear,
  onRemove,
  activeField,
  setActiveField,
}) {
  const customerIssueInputRef = useRef(null);

  const handleFocus = () => {
    customerIssueInputRef.current.focus();
    setActiveField("customerIssues");
  };
  // const handleBlur = ev => {
  //   ev.preventDefault();
  //   customerIssueInputRef.current.blur();
  //   setActiveField(null);
  // };

  const clearButton =
    selectedItems.length > 0 ? (
      <Button icon="cross" minimal={true} onClick={onClear} />
    ) : undefined;
  const isItemSelected = (r) => selectedItems.includes(r);
  const onItemSelect = (customerIssue) => {
    if (isItemSelected(customerIssue)) {
      onRemove(customerIssue.codeName);
    } else {
      onAdd(customerIssue);
    }
  };
  const onItemsSelect = (customerIssues) => customerIssues.map(onItemSelect);
  useLayoutEffect(() => {
    // noinspection JSUnresolvedFunction
    handleFocus();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      css={`
        .bp3-multi-select-popover .bp3-menu {
          //max-width: none !important;
          max-height: 220px !important;
          overflow-y: auto;
        }
      `}
    >
      <CustomerIssuesMS
        fill
        createNewItemFromQuery={undefined}
        onKeyDown={(ev) => {
          if (ev.keyCode === ENTER_RETURN_KEY_CODE) {
            ev.preventDefault();
          }
        }}
        resetOnSelect={true}
        createNewItemRenderer={null}
        openOnKeyDown={false}
        items={DEFAULT_REASONS}
        itemsEqual="codeName"
        itemDisabled={isItemSelected}
        itemPredicate={customerIssuesPredicate}
        itemRenderer={customerIssueItemRenderer(isItemSelected)}
        selectedItems={selectedItems}
        onItemSelect={onItemSelect}
        tagRenderer={customerIssueTagRenderer}
        onItemsPaste={onItemsSelect}
        placeholder="Choose all that apply..."
        query={queryText}
        onQueryChange={setQueryText}
        // onFocus={handleFocus}
        popoverProps={{
          usePortal: false,
          minimal: true,
          position: Position.BOTTOM_LEFT,
        }}
        tagInputProps={{
          onFocus: handleFocus,
          name: "customerIssues",
          autoFocus: true,
          inputRef: (r) => {
            customerIssueInputRef.current = r;
          },
          leftIcon: "diagnosis",
          large: true,
          onRemove: onRemove,
          rightElement: clearButton,
          tagProps: { large: false, minimal: true },
        }}
        // TODO: Center for IE11
        // css={`
        //   .bp3-popover-content .bp3-menu {
        //     width: ${width}px;
        //   }
        // `}
      />
    </div>
  );
}

const customerIssuesPredicate = (
  query,
  customerIssue: CustomerIssue,
  _index,
  exactMatch
) => {
  const normalizedTitle = customerIssue.longName.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedTitle === normalizedQuery;
  } else {
    return normalizedTitle.includes(normalizedQuery);
  }
};
const customerIssueTagRenderer = (customerIssue: CustomerIssue) =>
  customerIssue.longName;
const customerIssueItemRenderer = (isItemSelected) => (
  customerIssue: CustomerIssue,
  { modifiers, handleClick }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  const isSelected = isItemSelected(customerIssue);
  return (
    <MenuItem
      active={modifiers.active}
      icon={isSelected ? "tick" : "blank"}
      disabled={false}
      key={customerIssue.codeName}
      text={customerIssue.longName}
      // label={customerIssue.longName}
      onClick={handleClick}
      shouldDismissPopover={false}
    />
  );
};
