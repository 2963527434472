import { useStoreState } from "../hooks/ep";
import { epStore } from "../store";
import _ from "lodash";

export function useCustomerRow(customerId) {
  return useStoreState((s) => s.pready.customer2data[customerId], [customerId]);
}

export function getCustomerRow(customerId) {
  const customer2data = epStore.getState().pready.customer2data;
  return _.size(customer2data) ? customer2data[customerId] : {};
}
