import React, { useCallback } from "react";
import _ from "lodash";
import { MyCheckbox, MyCheckboxGroup } from "./styled-checkbox-group";
import "styled-components/macro";
import { useBoolean } from "../../helpers/useBoolean";
import { Card, Classes } from "@blueprintjs/core"; // DO NOT REMOVE. Necessary for using the css={`...`} prop

function getAllRowNodes(gridApi) {
  const rowNodes = [];
  gridApi.forEachNode((node) => rowNodes.push(node));
  return rowNodes;
}

export default class CategoryColumnFilter extends React.Component {
  constructor(props) {
    super(props);

    this.valueGetter = props.valueGetter;

    const allItemCounts = _.countBy(
      getAllRowNodes(props.api)
        .map(this.valueGetter)
        .map((v) => `${v}`),
      (v) => v
    );
    const allItemValues = _.sortBy(
      Object.keys(allItemCounts),
      (v) => -allItemCounts[v]
    );
    this.state = {
      selectedItemValues: allItemValues,
      allItemValues,
      allItemCounts,
    };

    this.onChange = this.onChange.bind(this);
  }

  isFilterActive() {
    return (
      _.size(this.state.selectedItemValues) !== _.size(this.state.allItemValues)
    );
  }

  doesFilterPass(params) {
    const v = `${this.valueGetter(params.node)}`;
    return this.isFilterActive()
      ? this.state.selectedItemValues.includes(v)
      : true;
  }

  getModel() {
    return { value: this.state.selectedItemValues };
  }

  setModel(model) {
    console.log("In setModel: ", model);

    const selectedItemValues = model && model.value;
    this.onChange(selectedItemValues);
  }

  afterGuiAttached(params) {
    this.focus();
  }

  onNewRowsLoaded() {
    // console.log("onNewRowsLoaded");
  }

  focus() {
    // console.log("filter focus");
    // window.setTimeout(() => {
    //   let container = findDOMNode(this.refs.input);
    //   if (container) {
    //     container.focus();
    //   }
    // });
  }

  componentMethod(message) {
    alert(`Alert from CategoryColumnFilter ${message}`);
  }

  onChange(newSelectedItemValues) {
    if (newSelectedItemValues === null) {
      newSelectedItemValues = this.state.allItemValues;
    }
    newSelectedItemValues = newSelectedItemValues.map((v) => `${v}`);
    if (!_.isEqual(this.state.selectedItemValues, newSelectedItemValues)) {
      this.setState(
        {
          selectedItemValues: newSelectedItemValues,
        },
        () => {
          this.props.filterChangedCallback();
        }
      );
    }
  }

  render() {
    return (
      <CategoryColumnFilterView
        selectedItemValues={this.state.selectedItemValues}
        allItemValues={this.state.allItemValues}
        allItemCounts={this.state.allItemCounts}
        onChange={this.onChange}
      />
    );
  }
}

function getCheckboxTitle(v, nItems, onClickCount) {
  return (
    <span>
      <span>{v}</span>
      <span className="nItems" onClick={onClickCount}>
        {nItems}
      </span>
    </span>
  );
}

function CategoryColumnFilterView({
  selectedItemValues,
  allItemValues,
  allItemCounts,
  onChange,
}) {
  const alwaysDisplayCounts = useBoolean(false);
  const onClickCount = useCallback(
    (ev) => {
      ev.preventDefault();
      alwaysDisplayCounts.toggle();
    },
    // eslint-disable-next-line
    [alwaysDisplayCounts.toggle]
  );

  return (
    <Card
      css={`
        border-radius: 0 !important;
        border: transparent !important;
        box-shadow: none !important;

        & .ant-checkbox-group {
          background: unset !important;
          color: unset !important;
        }
        & .ant-checkbox-wrapper {
          color: unset !important;
        }
      `}
    >
      <h5 className={Classes.HEADING}>Select Filter Values</h5>
      <MyCheckboxGroup value={selectedItemValues} onChange={onChange}>
        {allItemValues.map((v) => {
          const nItems = allItemCounts[v];
          const title = getCheckboxTitle(v, nItems, onClickCount);
          return (
            <span
              key={v}
              css={`
                & .nItems {
                  font-size: 0.6em;
                  padding: 0.3em !important;
                  text-align: center;
                  vertical-align: middle;
                  background-color: transparent !important;
                  border-radius: 1em;
                  margin-left: 1em;
                  color: transparent !important;
                  border: 1px solid transparent;
                }
                && .nItems.nItems:hover {
                  background-color: rgba(255, 255, 255, 0.2) !important;
                  color: white !important;
                  border: none !important;
                }
                ${alwaysDisplayCounts.value ? "& .nItems" : "&:hover .nItems"} {
                  color: white !important;
                  border: 1px solid rgba(255, 255, 255, 0.2);
                }
              `}
            >
              <MyCheckbox key={v} value={v} title={title} />
            </span>
          );
        })}
      </MyCheckboxGroup>
    </Card>
  );
}
