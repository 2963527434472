// This must be the first line in src/index.js
import "./index.less";
import "./polyfills";
import "./disable-console-log-in-production.js";
import "./index.css";
import "./ag-grid-customization.scss";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { epStore } from "./store";
import { firebase } from "./fbase";
import { StoreProvider } from "easy-peasy";
import { AgTableThemeProvider } from "./components/AgTable/theming";

if (
  window.Cypress ||
  !process.env.NODE_ENV ||
  process.env.NODE_ENV !== "production"
) {
  window.store = epStore;
  window.firebase = firebase;
}

ReactDOM.render(
  <StoreProvider store={epStore}>
    <AgTableThemeProvider>
      <App />
    </AgTableThemeProvider>
  </StoreProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
