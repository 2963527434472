import { AutoComplete, Input } from "antd";
import React, { useCallback } from "react";
import "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import { useOptions } from "./search-box-ac-options";
import { useSearchBoxText, useSetSearchBoxText } from "./search-box-store";
import { AgRows, OnPressEnter, OnSelect, SetQuickFilterText } from "./types";
import { DataSourceItemType } from "antd/es/auto-complete";

const { Search } = Input;

const handleFocus = (event) => event.target.select();
export function RbnSearchBox({
  rowData,
  onSearch,
  ...restProps
}: {
  rowData: AgRows;
  onSearch: SetQuickFilterText;
}): JSX.Element {
  const value = useSearchBoxText();
  const setText = useSetSearchBoxText();
  const onChange = useCallback(
    (ev) => {
      ev.preventDefault();
      if (ev.type === "click") {
        // @ts-ignore
        onSearch(ev.target.value);
      } else {
        setText(ev.target.value);
      }
    },
    [setText]
  );
  const options = useOptions(rowData, value);

  return (
    <AutoComplete
      placeholder="Search"
      defaultActiveFirstOption={false}
      dropdownMatchSelectWidth={400}
      value={value}
      onSelect={onSearch as OnSelect}
      dataSource={options as DataSourceItemType[]}
      css={`
        &&& {
          width: 200px;
          transition: width 0.2s ease-in-out;
        }
        &&&.ant-select-focused {
          width: 400px !important;
        }
      `}
      {...restProps}
    >
      <Search
        onPressEnter={onSearch as OnPressEnter}
        onChange={onChange}
        allowClear={true}
        onFocus={handleFocus}
      />
    </AutoComplete>
  );
}

export interface AutoCompleteProps {}

/*
Property
    Description
    Type
    Default
    Version

allowClear
    Show clear button, effective in multiple mode only.
    boolean
    false

autoFocus
    get focus when component mounted
    boolean
    false

backfill
    backfill selected item the input when using keyboard
    boolean
    false

children (for customize input element)
    customize input element
    Type
        HTMLInputElement
        HTMLTextAreaElement
        React.ReactElement<InputProps>
    <Input />

children (for dataSource)
    Data source to auto complete
    Type
        React.ReactElement<OptionProps>
        Array<React.ReactElement<OptionProps>>
    -

defaultActiveFirstOption
    Whether active first option by default
    boolean
    true

defaultValue
    Initial selected option.
    string
    -

disabled
    Whether disabled select
    boolean
    false

filterOption
    If true, filter options by input, if function, filter options against it. The function will receive two arguments, inputValue and option, if the function returns true, the option will be included in the filtered set; Otherwise, it will be excluded.
    boolean or function(inputValue, option)
    true

placeholder
    placeholder of input
    string
    -

value
    selected option
    string
    -

onBlur
    Called when leaving the component.
    function()
    -

onChange
    Called when select an option or input value change, or value of input is changed
    function(value)
    -

onFocus
    Called when entering the component
    function()
    -

onSearch
    Called when searching items.
    function(value)
    -

onSelect
    Called when a option is selected. param is option's value and option instance.
    function(value, option)
    -

defaultOpen
    Initial open state of dropdown
    boolean
    -

open
    Controlled open state of dropdown
    boolean
    -

onDropdownVisibleChange
    Call when dropdown open
    function(open)
    -

notFoundContent
    Specify content to show when no result matches..
    string
    'Not Found'

 */
