const routes = {
  dailyQueue: "/daily-queue",
  dashboard: "/dashboard",
  customerInteractions: "/customer-interactions",
  signin: "/signin",
  profile: "/profile",
  root: "/",
  rollup: "/rollup",
  pulse: "/pulse",
  dev: "/dev",
  manual: "/manual",
  //
  napo: "/napo/:customerId",
};
routes.DEFAULT = routes.dailyQueue;
export default routes;
