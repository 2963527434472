import React from "react";
import { DashboardTable, useDashboardRowData } from "./dashboard-table";
import "styled-components/macro";
import _ from "lodash";
import { useBoolean } from "../helpers/useBoolean";
import {
  Callout,
  FormGroup,
  PopoverPosition,
  Spinner,
  Switch,
  Tooltip,
} from "@blueprintjs/core";
import { toaster } from "../toaster";
import { useMeData } from "../hooks/useMe";
import { UserRole } from "../store/model-me";
import { useEnsureData } from "../helpers/use-ensure-data";

const HEIGHT = "70vh";

function useDefaultDailyX() {
  const myRole = useMeData().role;
  return myRole === UserRole.NA_SUPPORT_2 ? 50 : 10;
}

export function DailyQueuePage(props) {
  const loading = _.max([
    useEnsureData("pready"),
    useEnsureData("stars"),
    useEnsureData("customerInteractions"),
    useEnsureData("invoiceCredits"),
  ]);
  return loading ? <Spinner /> : <DailyQueuePageInner {...props} />;
}
export function DailyQueuePageInner(props) {
  const defaultDailyX = useDefaultDailyX();
  const includeNewbies = useBoolean(false);
  const includeProjectbased = useBoolean(false);
  const includeNonnationals = useBoolean(true);

  const finalizeRowData = (rowData) => {
    rowData = rowData
      .filter((el) => el.churn_risk_score >= 60)
      .filter(
        (el) =>
          !el.hasReportedCustomerInteraction || el.wasCustomerInteractionToday
      )
      .filter((x) => includeNewbies.value || !x.is_new_customer)
      .filter((x) => includeProjectbased.value || !x.is_project_based)
      .filter((x) => includeNonnationals.value || x.is_national_account);
    rowData = _.sortBy(rowData, (el) => -el.churn_risk_score);
    rowData = _.sortBy(rowData, (el) => -el.value_at_risk);
    rowData = _.sortBy(rowData, (el) => -el.is_primary_assigned_state);
    rowData = _.sortBy(rowData, (el) => -el.is_national_account);

    const nRowsWithItxns = _.sumBy(
      rowData,
      (row) => +row.hasReportedCustomerInteraction
    );
    let dailyX = defaultDailyX;
    if (nRowsWithItxns >= defaultDailyX) {
      dailyX = nRowsWithItxns + (5 - (nRowsWithItxns % 5));
    }
    rowData = rowData.slice(0, dailyX);
    rowData = _.sortBy(rowData, (el) =>
      el.hasReportedCustomerInteraction
        ? -el.latestInteractionSecondsAgo
        : -10000000000000
    );
    return rowData;
  };

  let dbRowData = useDashboardRowData();

  dbRowData = finalizeRowData(dbRowData);

  const hasExpandedDailyX = dbRowData.length > defaultDailyX;

  return (
    <div
      css={`
        width: 100%;
      `}
    >
      <div
        css={`
          display: flex;
          //max-height: 50px;
        `}
      >
        <FormGroup
          inline
          label={<span>{"New"}</span>}
          labelFor={"include-newbies"}
          css={`
            margin-bottom: 3vh;
            margin-right: 100px;
          `}
        >
          <SwitchTooltip name={"New"}>
            <Switch
              name="include-newbies"
              checked={includeNewbies.value}
              onChange={(ev) => {
                includeNewbies.setValue(ev.target.checked);
                showSwitchChangeToaster("New", ev.target.checked);
              }}
              large
            />
          </SwitchTooltip>
        </FormGroup>
        <FormGroup
          inline
          label={<span>{"Project-Based"}</span>}
          labelFor={"include-projectbased"}
          css={`
            margin-bottom: 3vh;
            margin-right: 100px;
          `}
        >
          <SwitchTooltip name={"Project-Based"}>
            <Switch
              name="include-projectbased"
              checked={includeProjectbased.value}
              onChange={(ev) => {
                includeProjectbased.setValue(ev.target.checked);
                showSwitchChangeToaster("Project-Based", ev.target.checked);
              }}
              large
            />
          </SwitchTooltip>
        </FormGroup>
        <FormGroup
          inline
          label={<span>{"Non-National"}</span>}
          labelFor={"include-nonnationals"}
          css={`
            margin-bottom: 3vh;
            margin-right: 100px;
          `}
        >
          <SwitchTooltip name={"Non-National"}>
            <Switch
              name="include-nonnationals"
              checked={includeNonnationals.value}
              onChange={(ev) => {
                includeNonnationals.setValue(ev.target.checked);
                showSwitchChangeToaster("Non-National", ev.target.checked);
              }}
              large
            />
          </SwitchTooltip>
        </FormGroup>
        {hasExpandedDailyX && (
          <Callout
            intent="info"
            icon="add-row-top"
            fill={false}
            title={`Bonus Customers`}
            css={`
              margin-bottom: 3vh;
              width: fit-content;
            `}
          >
            {`We added ${
              dbRowData.length - defaultDailyX
            } additional (optional) customers to your daily queue. Great work!.`}
          </Callout>
        )}
      </div>
      <DashboardTable
        height={HEIGHT}
        fullScreenEnabled={false}
        finalizeRowData={finalizeRowData}
        {...props}
      />
    </div>
  );
}

function showSwitchChangeToaster(name, newSwitchValue) {
  toaster.info(
    `Now ${
      newSwitchValue ? "including" : "excluding"
    } ${name} Customers in your "Daily Queue" customer list below`,
    1
  );
}

function SwitchTooltip({ name, children, ...restProps }) {
  return (
    <Tooltip
      content={`Toggle inclusion of ${name} Customers in your "Daily Queue" customer list below`}
      position={PopoverPosition.TOP}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
}
