import React from "react";
import { H6 } from "@blueprintjs/core";
import "moment-timezone";
import moment from "moment";
import "styled-components/macro";
import { useUserTimezone } from "../helpers/use-user-timezone";
import { useStoreState } from "../hooks/ep";

export function DataRefreshStats() {
  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      `}
    >
      <DataRefreshedAt />
    </div>
  );
}

export function useDataRefreshStatsText() {
  const dts = useStoreState((s) => s.pready.data_refreshed_at);
  const timezone = useUserTimezone();
  return dts ? formatTimestamp(dts, timezone) : "";
}

const PREFIX_TEXT = "Latest ML model deployed";

export function DataRefreshedAt() {
  const dts = useStoreState((s) => s.pready.data_refreshed_at);

  return <Statline name={PREFIX_TEXT} dts={dts} />;
}

function Statline({ name, dts, marginBottom = "0" }) {
  const content = useStatlineContent(dts, name);
  return (
    <H6
      css={`
        font-size: 10px;
        font-weight: normal;
        margin: 0 10px ${marginBottom} 0 !important;
      `}
    >
      {content}
    </H6>
  );
}

function useStatlineContent(dts, name) {
  const timezone = useUserTimezone();
  if (dts && timezone) {
    return `${name}: ${formatTimestamp(dts, timezone)}`;
  } else {
    return "";
  }
}

function formatTimestamp(dts, timezone) {
  return moment(dts).tz(timezone).format("M/D/YY [at] h:mm A (z)");
}
