import {
  Callout,
  Classes,
  Collapse,
  Colors,
  H2,
  H4,
  H5,
  H6,
  Icon,
  UL,
} from "@blueprintjs/core";
import React from "react";
import "styled-components/macro";
import { CustomerId } from "./dev-page";
import { Row, Space } from "antd";
import { useBoolean } from "./helpers/useBoolean";
import { Elevation } from "@blueprintjs/core/lib/esm/common/elevation";
import { useStoreState } from "./hooks/ep";
import { TableRow } from "./store/readonly-table-model-factory";
import { useMeData } from "./hooks/useMe";

interface CustomerIdProps {
  customerId: CustomerId;
}

function useCustomer(customerId: CustomerId): TableRow {
  return useStoreState((s) => s.pready.customer2data[customerId]);
}

export function CustomerCallScript({
  customerId,
  includeTitle = true,
}: CustomerIdProps & { includeTitle?: boolean }): JSX.Element {
  return (
    <div
      className="callscript-root"
      css={`
        width: fit-content;

        & .section-body {
          word-wrap: break-word;

          & .data-text {
            color: ${Colors.BLUE5};
          }

          & .silent-text {
            color: ${Colors.RED5};
          }
        }
      `}
    >
      {includeTitle && (
        <H2
          className="callscript-title"
          css={`
            margin-bottom: 24px;
          `}
        >{`Call Script: ${customerId}`}</H2>
      )}
      <div className="callscript-body">
        <Space direction={"vertical"} size={48}>
          <Gatekeeper customerId={customerId} />
          <Introduction customerId={customerId} />
          <PositiveResponse customerId={customerId} />
          <NegativeResponse customerId={customerId} />
          <FollowupVerify customerId={customerId} />
          <EndCallAndDocument customerId={customerId} />
        </Space>
      </div>
    </div>
  );
}

function EndCallAndDocument({ customerId }: CustomerIdProps): JSX.Element {
  return (
    <Section title={"End Call and Document"}>
      <p>
        Is there anyone else I should contact to have a similar conversation?
      </p>
      <p>
        Again, I am sorry for your experience and we do really value you as a
        customer. Thank you for allowing us the opportunity to resolve this
        issue and we look forward to working with you again in the future. Have
        a great day.
      </p>
      <p>
        <Silent>
          Document any response of importance. Document and send follow up
          email. Send case and email to RVP and MM)
        </Silent>
      </p>
    </Section>
  );
}
function FollowupVerify({ customerId }: CustomerIdProps): JSX.Element {
  const customer = useCustomer(customerId);
  return (
    <Section title={"Follow Up and Verify Contact Info"}>
      <p>Thank you. One of our leaders will be contacting you shortly.</p>
      <p>
        To ensure we have the right contact information, is the best number to
        reach you at still
        {customer.PrimaryContactEmail ? (
          <Data> {customer.formatted_contact_phone_number}</Data>
        ) : (
          "(xxx-xxx-xxxx)"
        )}
        ? Ok. Is there an additional number we should add?
      </p>
      <p>
        <Silent>
          (If incorrect or additional numbers, take the new number and update in
          Prism and SFDC)
        </Silent>
      </p>
      <p>
        Is your email address still
        {customer.PrimaryContactEmail ? (
          <Data> {customer.PrimaryContactEmail}</Data>
        ) : (
          "(email.com)"
        )}
        ? Great.
      </p>
      <p>OR</p>
      <p>
        We don’t seem to have an email address on file for you. What is your
        email address?
      </p>
      <p>
        <Silent>
          (If incorrect email or new email, update in Prism and SFDC)
        </Silent>
      </p>
      <p>Thank you for that information.</p>
    </Section>
  );
}
function NegativeResponse({ customerId }: CustomerIdProps): JSX.Element {
  return (
    <Section title={"Problems / Issues with Service Response"}>
      <H5>Customer Confirms the Churn Risk (or other issue):</H5>
      <H6>Resolved Issue:</H6>
      <p>
        <Silent>
          (If they confirmed there has been an issue with the identified churn,
          refer to that/those specific churns in the remainder of the
          conversation).
        </Silent>
      </p>
      <p>
        Great, thank you for that feedback. We appreciate your business. Are
        there any other staffing needs I can assist you with? Are you able to
        share when you think you will have a future staffing need?
      </p>
      <p>OR</p>
      <H6>Unresolved Issue:</H6>
      <p>
        <Silent>
          (If they are experiencing service failure, short fill, poor quality or
          billing issue, show empathy by utilizing LSCPA)
        </Silent>
      </p>
      <p>I am very sorry to hear that. Please tell me a little more?</p>
      <p>
        <em>Listen </em>– let the customer express the issue
      </p>
      <p>
        <em>Share </em>– you understand how frustrating/difficult this is
      </p>
      <p>
        <em>Clarify </em>– ensure you have a full understanding of the challenge
      </p>
      <p>
        <em>Problem Solve </em>– indicate you would like to escalate the issue
      </p>
      <p>
        <em>Ask For Action </em>– Ask the customer if they would allow us to
        attempt to rectify the issue
      </p>
      <p
        css={`
          margin-left: 48px;
        `}
      >
        <Silent>
          Document as “Actionable” or “Non-Actionable”. If non-actionable, just
          document conversation in Customax. If Actionable, then a case should
          be created in SF and sent to both MM and RVP until integration between
          Customax and SF happens.
        </Silent>
      </p>
      <p></p>
      <p></p>
      <p></p>
      <p></p>
    </Section>
  );
}

function PositiveResponse({ customerId }: CustomerIdProps): JSX.Element {
  return (
    <Section title={"Positive or Not Applicable Response"}>
      <H5>Customer Response of No Issues Exist:</H5>
      <p>
        <Silent>
          If everything is fine and the decline was project or externally based,
          thank them for their business, confirm accurate contact info, ensure
          on JobStack, ask if there is anyone else you should contact, and
          document call in Customax.
        </Silent>
      </p>
    </Section>
  );
}

function useMyFullName(): string {
  const me = useMeData();
  return [me.first_name, me.last_name].filter((v) => !!v).join(" ");
}
function Gatekeeper({ customerId }: CustomerIdProps): JSX.Element {
  const myFullName = useMyFullName();
  const customer = useCustomer(customerId);
  return (
    <Section title={"Gatekeeper"}>
      <p>
        Hi there, I am trying to connect with
        <Data> {customer.formatted_contact_full_name}</Data>.
      </p>
      <p>
        <b>Gatekeeper: </b>May I ask who is calling? And what is this regarding?
      </p>
      <p>
        This is<Data> {myFullName} </Data>, and I am calling to speak with
        <Data> {customer.formatted_contact_full_name} </Data>
        regarding their account with PeopleReady.
      </p>
    </Section>
  );
}

function Introduction({ customerId }: CustomerIdProps): JSX.Element {
  const myFullName = useMyFullName();
  const customer = useCustomer(customerId);
  return (
    <Section title={"Introduction and Purpose"}>
      <p>
        Hello<Data> {customer.formatted_contact_full_name} </Data>, this is
        <Data> {myFullName} </Data>from PeopleReady.
      </p>
      <p>
        <b>Purpose: </b>We are reviewing our business partnerships and reaching
        out proactively to you to see make sure your staffing needs are being
        met. I was hoping to take a minute or two of your time so that I can
        really understand where PeopleReady stands in terms of our partnership
        with you in general, but also specifically related to
        <Silent>
          {" "}
          [be sure to understand the churn risk prior to the call and select the
          applicable script(s) below]
        </Silent>
      </p>
      <UL>
        <li>
          <b>Billing/invoicing: </b>Our current billing and invoicing processes
          for you
        </li>
        <li>
          <b>JobStack: </b>Our JobStack application and how it’s working for you
          and your team
        </li>
        <li>
          <b>Fill issues: </b>
          <span>How our team in </span>
          <Data>{customer.BillingState} </Data>
          <span>
            is doing on getting your orders filled completely and accurately for
            you
          </span>
        </li>
        <li>
          <b>Branch unresponsiveness: </b>The quality of service you are
          receiving from our team in <Data>{customer.BillingState}</Data>
        </li>
        <li>
          <b>Worker unprofessionalism: </b>What you think about the quality of
          workers you are receiving from us
        </li>
        <li>
          <b>Pricing: </b>The value your company is receiving from the services
          that PeopleReady provides you
        </li>
      </UL>
      <p>Is now a good time?</p>
    </Section>
  );
}

function Silent({ children }) {
  return <span className="silent-text">{children}</span>;
}

function Data({ children }) {
  return <span className="data-text">{children}</span>;
}

function Section(props) {
  return <Section1 {...props} />;
}

function Section1({ title, children }): JSX.Element {
  return (
    <Row
      css={`
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        & .section-title {
          word-wrap: break-word;
          width: 124px;
          margin-right: 48px;
        }

        //& .section-body {
        //  width: 500px;
        //}
      `}
    >
      <H4 className="section-title">{title}</H4>
      <div className="section-body">{children}</div>
    </Row>
  );
}

function Section2({ title, children }): JSX.Element {
  const isOpen = useBoolean(false);
  // const icon = (
  //   <Button
  //     intent={Intent.PRIMARY}
  //     minimal
  //     icon={isOpen.value ? "caret-up" : "caret-down"}
  //     onClick={isOpen.toggle}
  //   />
  // );
  const icon = (
    <Icon
      css={`
        vertical-align: middle;
        display: inline flex;
      `}
      iconSize={16}
      color="#48aff0"
      className="my-callout-icon bp3-button bp3-minimal bp3-intent-primary"
      icon={isOpen.value ? "caret-up" : "caret-down"}
      onClick={isOpen.toggle}
    />
  );
  return (
    <Callout
      className={Classes.elevationClass(Elevation.ONE)}
      icon={icon}
      css={`
        & .section-title {
          height: 32px !important;
          display: flex;
          align-items: center;
          margin: 0 !important;
          padding: 0 !important;
        }
        & .section-body {
          margin-top: 12px;
          width: 500px;
        }
        & .my-callout-icon {
          min-width: 32px !important;
          width: 32px !important;
          min-height: 32px !important;
          height: 32px !important;
          vertical-align: middle !important;
          padding: 8px !important;
        }
      `}
    >
      <H4 className="section-title">
        <span>{title}</span>
      </H4>
      <div className="section-body">
        <Collapse isOpen={isOpen.value}>{children}</Collapse>
      </div>
    </Callout>
  );
}
