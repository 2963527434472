import {
  Button,
  Classes,
  H5,
  Intent,
  Popover,
  Position,
} from "@blueprintjs/core";
import React, { useCallback } from "react";
import "styled-components/macro";
import { isValidEmail } from "./email-input";
import Tooltip from "antd/es/tooltip";
import { useStoreActions } from "../../hooks/ep";
import { toaster } from "../../toaster";

function PopContent({ email }) {
  const handleYes = useHandlePasswordReset(email);
  return (
    <div
      css={`
        padding: 16px;
      `}
    >
      <H5>Confirm Password Reset</H5>
      <p>
        Send password reset email to <strong>{email}</strong>?
      </p>
      <div
        css={`
          display: flex;
          justify-content: flex-end;
          margin-top: 15px;
        `}
      >
        <Button
          autoFocus
          intent={Intent.SUCCESS}
          className={Classes.POPOVER_DISMISS}
          onClick={handleYes}
          css={`
            margin-right: 10px;
          `}
        >
          Yes
        </Button>
        <Button className={Classes.POPOVER_DISMISS}>No</Button>
      </div>
    </div>
  );
}

function useHandlePasswordReset(email) {
  const handlePasswordReset = useStoreActions(
    (a) => a.firebase.handlePasswordReset
  );
  return useCallback(() => {
    handlePasswordReset(email)
      .then(() => {
        toaster.success(`Sent password reset email to ${email}`);
      })
      .catch((error) => {
        toaster.error(`${error}`);
      });
  }, [email, handlePasswordReset]);
}

export function ForgotPasswordBtn({ email }) {
  const disabled = !isValidEmail(email);
  const tooltipProps = !disabled ? { visible: false } : {};
  return (
    <Popover
      usePortal={false}
      canEscapeKeyClose
      position={Position.RIGHT}
      content={<PopContent email={email} />}
    >
      <Tooltip
        title='Please enter your email address in the "Email" field above'
        placement="right"
        {...tooltipProps}
      >
        <Button minimal disabled={disabled}>
          Forgot Password?
        </Button>
      </Tooltip>
    </Popover>
  );
}
