import { useParams } from "react-router";
import React from "react";
import { NivoLineFromEndpoint } from "./components/AgTable/nivo-line";

export function NapoLine({ customerId, height, width }) {
  const endpoint = `wv_history/${customerId}`;
  return (
    <NivoLineFromEndpoint
      endpoint={endpoint}
      height={height}
      width={width}
      enableSlices={false}
      enablePoints={false}
    />
  );
}

export function NapoPage() {
  const { customerId } = useParams();
  return <NapoLine customerId={customerId} height={600} width={800} />;
}
