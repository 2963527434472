import { CallTopic } from "./call-topics-input";

export function getCustomerInteractionFormErrorMessage({
  callTopicsQueryText,
  callTopics,
  customerIssuesQueryText,
  customerIssues,
  notes,
  phone,
  customerSentiment,
  churnLikelihood,
  salesforceCaseOpened,
  salesforceCaseUrl,
  underSalesforceReview,
  attendees,
}) {
  const loneCallTopicNeedsExplanation =
    callTopics.length === 1 && callTopics[0].codeName === "other";
  const loneCustomerIssueNeedsExplanation =
    customerIssues.length === 1 && customerIssues[0].codeName === "other";
  const loneOtherNeedsExplanation =
    loneCallTopicNeedsExplanation || loneCustomerIssueNeedsExplanation;

  const interactionDidNotOccur = didNoInteractionOccur(callTopics);
  const noPhonecallOccurred = didNoPhonecallOccur(callTopics);

  const isUnderSalesforceReview = underSalesforceReview === "Yes";

  if (!!callTopicsQueryText) {
    return queryTextErrorMessage(callTopicsQueryText, "Call Topics");
  } else if (!!customerIssuesQueryText) {
    return queryTextErrorMessage(customerIssuesQueryText, "Customer Issues");
  } else if (!underSalesforceReview) {
    return selectionErrorMessage("Under Salesforce Review");
  } else if (interactionDidNotOccur && !phone && !noPhonecallOccurred) {
    return textualErrorMessage("Phone Number");
  } else if (salesforceCaseOpened === "Yes" && !salesforceCaseUrl) {
    return selectionErrorMessage("Salesforce Case URL");
  } else if (isUnderSalesforceReview || interactionDidNotOccur) {
    return null;
  } else if (!callTopics.length) {
    return multiselectErrorMessage("Call Topics");
  } else if (!customerIssues.length) {
    return multiselectErrorMessage("Customer Issues");
  } else if (loneOtherNeedsExplanation && !notes) {
    return textualErrorMessage("Notes");
  } else if (!customerSentiment) {
    return selectionErrorMessage("Customer Sentiment");
  } else if (!churnLikelihood) {
    return selectionErrorMessage("Churn Likelihood");
  } else if (!salesforceCaseOpened) {
    return selectionErrorMessage("Salesforce Case Opened");
  } else {
    return null;
  }
}

export function didNoInteractionOccur(callTopics: CallTopic[]): boolean {
  return callTopics
    .map((v) =>
      [
        "no_answer",
        "left_voicemail",
        "not_in_service",
        "contact_info_incorrect",
        "redirected_to_other_contact",
      ].includes(v.codeName)
    )
    .includes(true);
}

export function didNoPhonecallOccur(callTopics: CallTopic[]): boolean {
  return callTopics
    .map((v) => ["source_is_cer_review"].includes(v.codeName))
    .includes(true);
}

export function queryTextErrorMessage(queryText, fieldLabel) {
  return `Please transfer the text "${queryText}" from the "${fieldLabel}" section into the Notes section`;
}

export function multiselectErrorMessage(fieldLabel) {
  return `Please select at least 1 option in the "${fieldLabel}" section`;
}

export function textualErrorMessage(fieldLabel) {
  return `Please fill out the "${fieldLabel}" field`;
}

export function selectionErrorMessage(fieldLabel: string): string {
  return `Please choose a value for the "${fieldLabel}" field`;
}
