import React, { useMemo } from "react";
import styled from "styled-components/macro";
import { Col, Layout, Menu, Row } from "antd";
import { useAuthorizedRoutes } from "../helpers/useAuthorization";
import { useHistory, useLocation } from "react-router";
import {
  DRAWER_WIDTH_PX,
  LAYOUT_CONTENT_HORIZONTAL_PADDING,
  LAYOUT_CONTENT_VERTICAL_PADDING,
  LAYOUT_HEADER_HEIGHT_PX,
  LAYOUT_LEFTNAV_ITEM_HEIGHT,
  LAYOUT_LEFTNAV_WIDTH_PX,
} from "../styles/constants";
import { LeftNavMenuItem, leftNavMenuItems } from "../helpers/leftNavMenuItems";
import { Colors } from "@blueprintjs/core";
import { UserSettings } from "./user-settings";
import { useMe } from "../hooks/useMe";
import { useIsReportActionFormOpen } from "./reporting-actions/modal-or-drawer";
import { useStoreState } from "../hooks/ep";

const { Header, Content, Sider } = Layout;

const NAVBAR_LOGO_FILEPATH = "/customax-logo.png";

const StyledSider = styled(Sider)`
  && {
    margin: 0;
    padding: 0;
    width: ${LAYOUT_LEFTNAV_WIDTH_PX} !important;
    min-width: ${LAYOUT_LEFTNAV_WIDTH_PX} !important;
    max-width: ${LAYOUT_LEFTNAV_WIDTH_PX} !important;
    background: white;
  }
`;

const StyledMenu = styled(Menu)`
  height: 100%;
  width: ${LAYOUT_LEFTNAV_WIDTH_PX} !important;

  && .ant-menu-item.ant-menu-item.ant-menu-item {
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${LAYOUT_LEFTNAV_ITEM_HEIGHT} !important;
    line-height: ${LAYOUT_LEFTNAV_ITEM_HEIGHT} !important;
  }
  && .anticon,
  && .bp3-icon {
    height: 16px;
    line-height: 16px;
    vertical-align: middle;
    color: rgb(255, 255, 255, 0.8);
  }
  && .ant-menu-item:hover {
    .bp3-icon,
    .anticon {
      color: rgb(255, 255, 255, 1);
    }
  }
`;

function LeftNavSider() {
  const location = useLocation();
  const history = useHistory();

  const authorizedRoutes = useAuthorizedRoutes();
  const authorizedLeftNavMenuItems = useMemo(
    () =>
      leftNavMenuItems.filter(({ urlPath }) => authorizedRoutes.has(urlPath)),
    [authorizedRoutes]
  );

  const leftNavMenuItemsComponents = authorizedLeftNavMenuItems
    .map((el) => ({ ...el, history }))
    .map(LeftNavMenuItem);

  return (
    <StyledSider collapsed={true}>
      <StyledMenu
        mode="inline"
        theme="dark"
        defaultSelectedKeys={[location.pathname]}
      >
        {leftNavMenuItemsComponents}
      </StyledMenu>
    </StyledSider>
  );
}

function MyHeader() {
  const me = useMe();
  const fullScreenEnabled = useStoreState((s) => s.misc.fullScreenModeEnabled);
  if (fullScreenEnabled) {
    return null;
  }
  return (
    <Header
      data-testid="wrapper_header"
      css={`
        height: ${LAYOUT_HEADER_HEIGHT_PX};
        vertical-align: center;
        margin: 0 !important;
        padding: 0 !important;
      `}
    >
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        css={`
          height: 100%;
          margin: 0 !important;
          padding: 0 12px 0 24px !important;
        `}
      >
        <Col>
          <img
            src={NAVBAR_LOGO_FILEPATH}
            height="16px"
            alt="Customax Logo"
            css={`
              cursor: pointer;
            `}
            onClick={() => window.location.reload()}
          />
        </Col>
        <Col>
          <div
            css={`
              display: flex;
              align-items: center;
            `}
          >
            {!me.loading && <UserSettings />}
          </div>
        </Col>
      </Row>
    </Header>
  );
}

const getStyledContentWidth = ({ hideLeftNav }) =>
  hideLeftNav ? "auto" : `100%`;

function StyledContent({ hideLeftNav, children, ...restProps }) {
  const fullScreen = useStoreState((s) => s.misc.fullScreenModeEnabled);
  return (
    <Content
      {...restProps}
      css={`
        background-color: ${Colors.DARK_GRAY3};
        padding: ${fullScreen
          ? "0"
          : `${LAYOUT_CONTENT_VERTICAL_PADDING} ${LAYOUT_CONTENT_HORIZONTAL_PADDING}`};
        margin: 0;
        min-height: 280px;
        height: calc(100vh - ${fullScreen ? "0" : LAYOUT_HEADER_HEIGHT_PX});
        width: ${getStyledContentWidth};
        display: flex;
        justify-content: center;
      `}
    >
      {children}
    </Content>
  );
}

function RootLayout({ children }) {
  const drawerOpen = useIsReportActionFormOpen();
  return (
    <Layout
      className={drawerOpen ? "ws-sm" : "ws-full"}
      css={`
        min-height: 100vh;
        &.ws-full {
          width: 100vw;
          transition: width 0s linear;
        }
        &.ws-sm {
          width: calc(100vw - ${DRAWER_WIDTH_PX});
          transition: width 0.3s linear;
        }
      `}
    >
      {children}
    </Layout>
  );
}

const AppWrapper = ({ hideLeftNav, children }) => {
  const fullScreen = useStoreState((s) => s.misc.fullScreenModeEnabled);

  return (
    <RootLayout>
      <MyHeader />
      <Layout>
        {hideLeftNav || fullScreen ? null : <LeftNavSider />}
        <Layout>
          <StyledContent hideLeftNav={hideLeftNav}>{children}</StyledContent>
        </Layout>
      </Layout>
    </RootLayout>
  );
};

export default AppWrapper;
