import { ColDef } from "@ag-grid-community/core/dist/es6/entities/colDef";

export const COLUMN_DEFS: ColDef[] = [
  {
    field: "starred",
    headerName: " ",
    type: "categoryColumn",
    cellClass: "react-rendered-cell",
    cellRenderer: "starCellRenderer",
    width: 60,
  },
  {
    field: "hasReportedCustomerInteraction",
    headerName: "",
    type: "booleanColumn",
  },
  {
    headerName: "Customer Number",
    field: "CustomerNumber",
    type: "textColumn",
  },
  {
    headerName: "Customer Name",
    field: "CustomerName",
    type: "textColumn",
  },
  {
    headerName: "Churn Risk",
    field: "churn_risk_score",
    type: "floatColumn",
    cellRenderer: "churnScoreCellRenderer",
    cellClass: "react-rendered-cell",
    width: 150,
  },
  {
    headerName: "Value At Risk",
    field: "value_at_risk",
    type: "moneyColumn",
  },
  {
    headerName: "4-wk Avg Volume",
    field: "lwvr__R_MEAN(Amount, 4)",
    type: "moneyColumn",
    cellRenderer: "napoCellRenderer",
  },
  {
    headerName: "12-wk Total Volume",
    field: "total_volume_last_12wks",
    type: "moneyColumn",
  },
  // {
  //   headerName: "Volume Last Week",
  //   field: "lwvr__Amount",
  //   type: "moneyColumn",
  // },
  {
    headerName: "Contact Name",
    field: "formatted_contact_full_name",
    type: "textColumn",
  },
  // {
  //   headerName: "Contact Title",
  //   field: "PrimaryContactTitle",
  //   type: "textColumn",
  // },
  {
    field: "latestInteractionTimestamp",
    headerName: "Latest Interaction",
    type: "timestampColumn",
  },
  {
    headerName: "New",
    field: "is_new_customer",
    type: "booleanColumn",
  },
  {
    headerName: "Project-Based",
    field: "is_project_based",
    type: "booleanColumn",
  },
  {
    headerName: "National",
    field: "is_national_account",
    type: "booleanColumn",
  },
  {
    headerName: "COVID-Impacted",
    field: "is_covid_impacted",
    type: "booleanColumn",
  },
  {
    headerName: "Total Volume Since Jan 2018",
    field: "lwvr__cum_v",
    type: "moneyColumn",
  },
  {
    headerName: "NA Owner",
    field: "NationalAccountOwner",
    type: "categoryColumn",
  },
  {
    headerName: "NA Leader",
    field: "NationalAccountLeader",
    type: "categoryColumn",
  },
  {
    headerName: "Golden Number",
    field: "GoldenCustomerNumber",
    type: "categoryColumn",
  },
  {
    headerName: "Golden Name",
    field: "GoldenCustomerName",
    type: "categoryColumn",
  },
  {
    headerName: "Branch Name",
    field: "branch_name",
    type: "categoryColumn",
  },
  {
    headerName: "Customer Created",
    field: "CustomerCreatedOnDate",
    type: "dateColumn",
  },
  {
    headerName: "Revenue Segmentation",
    field: "RevenueSegmentation",
    type: "categoryColumn",
  },
  {
    headerName: "Service Segmentation",
    field: "ServiceSegmentation",
    type: "categoryColumn",
  },
  {
    headerName: "Vertical Segmentation",
    field: "VerticalSegmentation",
    type: "categoryColumn",
  },
  {
    headerName: "Total Positive-Volume Weeks (PVWs)",
    field: "lwvr__cum_pv",
    type: "integerColumn",
  },
  {
    headerName: "Current PVW Streak",
    field: "lwvr__consec_pv",
    type: "integerColumn",
  },
  {
    headerName: "Longest PVW Streak",
    field: "lwvr__CUMMAX(consec_pv)",
    type: "integerColumn",
  },
  {
    headerName: "# Weeks Since 1st PVW",
    field: "lwvr__CUMSUM(cum_v > 0)",
    type: "integerColumn",
  },
  {
    headerName: "First Transaction",
    field: "FirstTransactionDate",
    type: "dateColumn",
  },
  {
    headerName: "Last Transaction",
    field: "LastTransactionDate",
    type: "dateColumn",
  },
  {
    headerName: "Line Of Business",
    field: "LineOfBusiness",
    type: "categoryColumn",
  },
  {
    headerName: "Billing City",
    field: "BillingCity",
    type: "categoryColumn",
  },
  {
    headerName: "Billing MSA",
    field: "BillingMSA",
    type: "categoryColumn",
  },
  {
    headerName: "Billing County",
    field: "BillingCounty",
    type: "categoryColumn",
  },
  {
    headerName: "Billing State",
    field: "BillingState",
    type: "categoryColumn",
  },
  {
    headerName: "Customer Status",
    field: "CustomerStatus",
    type: "categoryColumn",
  },
  {
    headerName: "Credit Terms Name",
    field: "CreditTermsName",
    type: "categoryColumn",
  },
  {
    headerName: "Customer Credit Limit",
    field: "CustomerCreditLimit",
    type: "moneyColumn",
  },
  {
    headerName: "Credit Status",
    field: "CreditStatus",
    type: "categoryColumn",
  },
  {
    headerName: "Drug Test Required",
    field: "DrugTestRequiredFlag",
    type: "booleanColumn",
  },
  {
    headerName: "Prism Customer ID",
    field: "PrismCustomerID",
    type: "textColumn",
  },
  {
    headerName: "Parent Customer Name",
    field: "ParentCustomerName",
    type: "textColumn",
  },
  {
    headerName: "Mean NVW Streak Length",
    field: "mean_npv_streaklen",
    type: "floatColumn",
  },
  {
    headerName: "Median NVW Streak Length",
    field: "median_npv_streaklen",
    type: "floatColumn",
  },
  {
    headerName: "Invoices w/ Credits (90-D)",
    field: "num_invoices_with_credits_in_past_90_days",
    type: "integerColumn",
  },
  {
    headerName: "Invoices w/ Credit Requests (90-D)",
    field: "num_invoices_with_credit_requests_in_past_90_days",
    type: "integerColumn",
  },
  {
    headerName: "Invoices w/ Approved Credit Requests (90-D)",
    field: "num_invoices_with_approved_credit_requests_in_past_90_days",
    type: "integerColumn",
  },
  {
    headerName: "Invoice Credits (90-D)",
    field: "num_invoice_credits_in_past_90_days",
    type: "integerColumn",
  },
  {
    headerName: "Invoice Credit Requests (90-D)",
    field: "num_credit_requests_in_past_90_days",
    type: "integerColumn",
  },
  {
    headerName: "Approved Invoice Credit Requests (90-D)",
    field: "num_approved_credit_requests_in_past_90_days",
    type: "integerColumn",
  },
  {
    headerName: "Timecard Fraud ICs (90-D)",
    field: "num_invoice_credits_in_past_90_days__is_timecard_fraud",
    type: "integerColumn",
  },
  {
    headerName: "Timecard Forgery ICs (90-D)",
    field: "num_invoice_credits_in_past_90_days__is_timecard_forgery",
    type: "integerColumn",
  },
  {
    headerName: "Labor Credit ICs (90-D)",
    field: "num_invoice_credits_in_past_90_days__is_labor_credit",
    type: "integerColumn",
  },
  {
    headerName: "Worker Issue ICs (90-D)",
    field: "num_invoice_credits_in_past_90_days__is_worker_issue",
    type: "integerColumn",
  },
  {
    headerName: "Billing Issue ICs (90-D)",
    field: "num_invoice_credits_in_past_90_days__is_billing_issue",
    type: "integerColumn",
  },
  {
    headerName: "Collections Dispute ICs (90-D)",
    field: "num_invoice_credits_in_past_90_days__is_collections_dispute",
    type: "integerColumn",
  },
  {
    headerName: "",
    field: "formatted_contact_phone_number",
    cellClass: "react-rendered-cell",
    cellRenderer: "phoneActionButtonCellRenderer",
    type: "textColumn",
    width: 156,
    pinned: "right",
  },
  {
    headerName: "",
    field: "action",
    cellClass: "react-rendered-cell",
    cellRenderer: "actionButtonCellRenderer",
    width: 48,
    pinned: "right",
  },
];
