import { StyledFormGroup } from "./styled-form-group";
import { TextArea } from "@blueprintjs/core";
import React, { useCallback } from "react";
import "styled-components/macro";
import { css } from "styled-components";
import { SetStringValue } from "./simple-radio";
import { IconName } from "@blueprintjs/icons";
import { TooltipLabel } from "./tooltip-label";

export function SimpleTextarea({
  text,
  setText,
  fieldLabel,
  fieldHelperText,
  fieldId,
  activeFieldId,
  setActiveFieldId,
  icon,
  height = "100px",
}: {
  text: string;
  setText: SetStringValue;
  fieldLabel?: string;
  fieldHelperText?: string;
  fieldId?: string;
  activeFieldId?: string;
  setActiveFieldId?: SetStringValue;
  icon?: IconName;
  height?: string;
}): JSX.Element {
  const onChange = useCallback(
    (ev) => {
      ev.preventDefault();
      setText(ev.target.value);
    },
    [setText]
  );

  const onFocus = useCallback(() => setActiveFieldId(fieldId), [
    fieldId,
    setActiveFieldId,
  ]);

  return (
    <StyledFormGroup
      label={
        <TooltipLabel labelText={fieldLabel} tooltipText={fieldHelperText} />
      }
      labelFor={fieldId}
      helperText={fieldHelperText}
      activeField={activeFieldId}
    >
      <div className="bp3-input-group">
        {icon && <span className={`bp3-icon bp3-icon-${icon}`} />}
        <TextArea
          name={fieldId}
          value={text}
          onChange={onChange}
          onFocus={onFocus}
          css={
            height
              ? css`
                  &&& {
                    height: ${height};
                  }
                `
              : ""
          }
        />
      </div>
    </StyledFormGroup>
  );
}
