import { Action, action } from "easy-peasy";

export interface MiscModel {
  NAME: string;
  //
  fullScreenModeEnabled: boolean;
  enableFullScreenMode: Action<MiscModel>;
  disableFullScreenMode: Action<MiscModel>;
  toggleFullScreenMode: Action<MiscModel>;
  //
  ra_payload: RA_Payload | null;
  ra_openModal: Action<MiscModel, RA_Payload>;
  ra_closeModal: Action<MiscModel>;
  //
  customerBriefing_currentCustomerId: number | null;
  customerBriefing_show: Action<MiscModel, number>;
  customerBriefing_hide: Action<MiscModel>;
}

export interface RA_Payload {
  actionName: string;
  snapshot: any;
}

const miscModel: MiscModel = {
  NAME: "misc",
  fullScreenModeEnabled: false,
  enableFullScreenMode: action((state) => {
    state.fullScreenModeEnabled = true;
  }),
  disableFullScreenMode: action((state) => {
    state.fullScreenModeEnabled = false;
  }),
  toggleFullScreenMode: action((state) => {
    state.fullScreenModeEnabled = !state.fullScreenModeEnabled;
  }),
  //
  ra_payload: null,
  ra_openModal: action((state, ra_payload) => {
    state.ra_payload = ra_payload;
  }),
  ra_closeModal: action((state) => {
    state.ra_payload = null;
  }),
  //
  customerBriefing_currentCustomerId: null,
  customerBriefing_show: action((state, customerId: number) => {
    state.customerBriefing_currentCustomerId = customerId;
  }),
  customerBriefing_hide: action((state) => {
    state.customerBriefing_currentCustomerId = null;
  }),
  //
};

export default miscModel;
