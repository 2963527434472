import React from "react";
import { useStoreState } from "../hooks/ep";
import { ChurnScoreAgTable } from "./explain-churn-score";
import { useEnsureData } from "../helpers/use-ensure-data";
import { Spinner } from "@blueprintjs/core";
import _ from "lodash";
import { AgColDefs } from "./AgTable/types";
import { useMeData } from "../hooks/useMe";
import { UserRole } from "../store/model-me";

export function InvoiceCreditsTable({
  fullScreenEnabled = true,
  ...restProps
}) {
  const loading = _.max([useEnsureData("invoiceCredits")]);
  return loading ? (
    <Spinner />
  ) : (
    <InvoiceCreditsTableInner
      fullScreenEnabled={fullScreenEnabled}
      {...restProps}
    />
  );
}

function InvoiceCreditsTableInner({
  finalizeRowData = (v) => v,
  finalizeColDefs = (v) => v,
  ...restProps
}) {
  const rowData = useStoreState((s) => s.invoiceCredits.initialData);
  const colDefs = useColumnDefs();

  return (
    <ChurnScoreAgTable
      rowData={finalizeRowData(rowData)}
      columnDefs={finalizeColDefs(colDefs)}
      {...restProps}
    />
  );
}

function useColumnDefs(): AgColDefs {
  const myRole = useMeData().role;
  const ret: AgColDefs = [
    {
      headerName: "Posted",
      field: "CreditPostedDate",
      type: "dateColumn",
      pinned: "left",
    },
    {
      headerName: "Amt",
      field: "CreditAmount",
      type: "moneyColumn",
    },
    {
      headerName: "Type",
      field: "CreditType",
      type: "categoryColumn",
    },
    {
      headerName: "Note",
      field: "CreditNote",
      type: "textColumn",
      // tooltipField: "CreditNote",
      width: 200,
      suppressSizeToFit: true,
      autoHeight: true,
      cellClass: "cell-wrap-text",
    },
    {
      headerName: "CR Note",
      field: "CreditRequestApprovedNote",
      type: "textColumn",
      // tooltipField: "CreditRequestApprovedNote",
      width: 200,
      suppressSizeToFit: true,
      autoHeight: true,
      cellClass: "cell-wrap-text",
    },
    {
      headerName: "CR Amt",
      field: "CreditRequestAmount",
      type: "moneyColumn",
    },
    {
      headerName: "CR?",
      field: "has_credit_request",
      type: "booleanColumn",
    },
    {
      headerName: "CR Submitted",
      field: "CreditRequestRequestedDate",
      type: "dateColumn",
    },
    {
      headerName: "Approved CR?",
      field: "has_approved_credit_request",
      type: "booleanColumn",
    },
    {
      headerName: "CR Approved",
      field: "CreditRequestApprovedDate",
      type: "dateColumn",
    },
    {
      headerName: "Posted to GL?",
      field: "IsPostedToGLFlag",
      type: "booleanColumn",
    },
    {
      headerName: "CR Number",
      field: "CreditRequestNumber",
      type: "textColumn",
    },
    {
      headerName: "Customer Number",
      field: "customer_number",
      type: "textColumn",
    },
    {
      headerName: "JobOrder Number",
      field: "joborder_number",
      type: "textColumn",
    },
    {
      headerName: "Branch Number",
      field: "branch_number",
      type: "textColumn",
    },
    {
      headerName: "JobOrder ID",
      field: "JobOrderFKID",
      type: "integerIdentifierColumn",
      __authorized_roles: [UserRole.SAXECAP_DEV],
    },
    {
      headerName: "JobOrder Created",
      field: "joborder__created_date",
      type: "dateColumn",
    },
    {
      headerName: "Customer ID",
      field: "customer_id",
      type: "integerIdentifierColumn",
      __authorized_roles: [UserRole.SAXECAP_DEV],
    },
    {
      headerName: "ID",
      field: "ID",
      type: "integerIdentifierColumn",
      __authorized_roles: [UserRole.SAXECAP_DEV],
    },
    {
      headerName: "Invoice ID",
      field: "InvoiceFKID",
      type: "integerIdentifierColumn",
      __authorized_roles: [UserRole.SAXECAP_DEV],
    },
    {
      headerName: "Cost Center (Branch) ID",
      field: "CostCenterFKID",
      type: "integerIdentifierColumn",
      __authorized_roles: [UserRole.SAXECAP_DEV],
    },
    {
      headerName: "CR Cost Center (Branch) ID",
      field: "CreditRequestCostCenterFKID",
      type: "integerIdentifierColumn",
      __authorized_roles: [UserRole.SAXECAP_DEV],
    },
    {
      headerName: "Inv Cost Center (Branch) ID",
      field: "InvoiceCostCenterFKID",
      type: "integerIdentifierColumn",
      __authorized_roles: [UserRole.SAXECAP_DEV],
    },
    {
      headerName: "Timecard Fraud",
      field: "is_timecard_fraud",
      type: "booleanColumn",
    },
    {
      headerName: "Timecard Forgery",
      field: "is_timecard_forgery",
      type: "booleanColumn",
    },
    {
      headerName: "Labor Credit",
      field: "is_labor_credit",
      type: "booleanColumn",
    },
    {
      headerName: "Worker Issue",
      field: "is_worker_issue",
      type: "booleanColumn",
    },
    {
      headerName: "Billing Issue",
      field: "is_billing_issue",
      type: "booleanColumn",
    },
    {
      headerName: "Collections Dispute",
      field: "is_collections_dispute",
      type: "booleanColumn",
    },
    {
      headerName: "Prism CR ID",
      field: "POSCreditRequestID",
      type: "integerIdentifierColumn",
    },
    {
      headerName: "Prism ID",
      field: "POSCreditRequestID",
      type: "integerIdentifierColumn",
    },
    {
      headerName: "Prism Source",
      field: "POSInvoiceCreditIDSource",
      type: "categoryColumn",
    },
    {
      headerName: "Tax Rate",
      field: "TaxRate",
      type: "floatColumn",
    },
    {
      headerName: "Tax State",
      field: "TaxState",
      type: "categoryColumn",
    },
    {
      headerName: "Tax Type",
      field: "TaxTypeName",
      type: "categoryColumn",
    },
  ];
  return ret.filter(
    // @ts-ignore
    (cd) => !cd.__authorized_roles || cd.__authorized_roles.includes(myRole)
  );
}

/*
TaxRate
TaxState
TaxTypeName
 */
