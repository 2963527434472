import React from "react";
// eslint-disable-next-line no-restricted-imports
import { css } from "styled-components";
import "styled-components/macro";
import {
  AnchorButton,
  Button,
  ButtonGroup,
  Classes,
  Dialog,
  Intent,
  Menu,
  MenuItem,
  Popover,
  PopoverInteractionKind,
  PopoverPosition,
  Position,
  Spinner,
  Tag,
  Tooltip,
} from "@blueprintjs/core";
import { useStoreActions, useStoreState } from "../hooks/ep";
import { CustomerData } from "../store/model-pready";
import { useReportCustomerInteraction } from "../components/table-actions/action-cell-renderer";
import _ from "lodash";
import { Col, Row, Space } from "antd";
import { NapoLine } from "../napo";
import { useWindowSize } from "react-use";
import { ChuScoPopover } from "../components/AgTable/churn-score-cell/cell-renderer";
import { dateVF, moneyVF } from "../components/AgTable/gridOptions";
import { useClipboard } from "use-clipboard-copy";
import { toaster } from "../toaster";
import { CustomerCallScript } from "../customer-call-script";
import { useEndpoint } from "../helpers/use-endpoint";
import moment from "moment";
import { useBoolean } from "../helpers/useBoolean";

function useCustomerData(customerId: number): CustomerData | undefined {
  return useStoreState((s) => s.pready.customer2data[customerId]);
}
export function CustomerBriefingDialog() {
  const customerId = useStoreState(
    (s) => s.misc.customerBriefing_currentCustomerId
  );
  const ra_payload = useStoreState((s) => s.misc.ra_payload);
  return (
    customerId &&
    !ra_payload && <CustomerBriefingDialogInner customerId={customerId} />
  );
}

function CustomerBriefingDialogInner({
  customerId,
}: {
  customerId: number;
}): JSX.Element {
  const customerData = useCustomerData(customerId);
  const customerBriefing_hide = useStoreActions(
    (a) => a.misc.customerBriefing_hide
  );
  const handleClose = (ev) => {
    ev.preventDefault();
    customerBriefing_hide();
  };
  const reportCustomerInteraction = useReportCustomerInteraction(customerData);
  const handleReportCustomerInteraction = (ev) => {
    ev.preventDefault();
    // customerBriefing_hide();
    reportCustomerInteraction();
  };

  return (
    <Dialog
      className={Classes.DARK}
      icon="info-sign"
      autoFocus={true}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      enforceFocus={true}
      usePortal={true}
      isOpen={!!customerData}
      onClose={handleClose}
      title={
        <span>
          <span>Customer Briefing: </span>
          <span className="cb-dialog-header--customer-name">
            {customerData.CustomerName}
          </span>
          <Tooltip
            content={"The customer's canonical Customer Number (click to copy)"}
          >
            <CopyableTag
              icon="id-number"
              css={`
                margin-left: 12px;
              `}
              text={customerData.CustomerNumber}
            />
          </Tooltip>
        </span>
      }
      css={`
        width: max(80vw, 600px);
      `}
    >
      <BriefingBody>
        <BriefingBodyLeft customerId={customerId} />
        <BriefingBodyRight customerId={customerId} />
      </BriefingBody>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={handleClose}>Close</Button>
          <Button
            intent={Intent.PRIMARY}
            onClick={handleReportCustomerInteraction}
          >
            Report Customer Interaction
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

function CopyableTag({
  icon = null,
  large = true,
  intent = Intent.NONE,
  fill = false,
  minimal = true,
  text,
  ...restProps
}) {
  const clipboard = useClipboard({ copiedTimeout: 500 });
  const onClick = () => clipboard.copy(text);
  return (
    <Tag
      fill={fill}
      minimal={minimal}
      intent={intent}
      large={large}
      icon={icon}
      interactive={true}
      onClick={onClick}
      {...restProps}
    >
      {clipboard.copied ? "Copied!" : text}
    </Tag>
  );
}

function BriefingBody({ children, ...restProps }): JSX.Element {
  return (
    <div
      className={Classes.DIALOG_BODY}
      css={`
        padding-left: 12px;
        padding-right: 12px;
        max-height: 80vh;
        height: 80vh;
        //overflow-y: hidden;
        //overflow-x: visible;

        & .briefing-item {
          flex-basis: 0;
        }
      `}
      {...restProps}
    >
      <Row
        css={`
          height: 100%;
          width: 100%;
        `}
      >
        {children}
      </Row>
    </div>
  );
}

function BriefingBodyLeft({ customerId }: { customerId: number }): JSX.Element {
  const customerData = useCustomerData(customerId);

  return (
    <Col
      xs={12}
      css={`
        margin-top: 12px;
        margin-bottom: 12px;
        height: 100%;
        overflow-y: scroll;
      `}
    >
      <Space size={24} direction="vertical">
        {/*<BriefingItem label="Customer ID">{customerId}</BriefingItem>*/}

        <BriefingItem_ChurnRiskScore customerId={customerId} />

        <BriefingItem_AtoContacts customerId={customerId} />
        <BriefingItem_RecentJobOrderContacts customerId={customerId} />

        <TextBriefingItem
          label="Contact Name"
          text={customerData["formatted_contact_full_name"]}
        />

        {!customerData.formatted_contact_phone_number ||
        customerData.formatted_contact_phone_number === "-Unknown-" ? null : (
          <BriefingItem label="Primary Phone">
            <LinkedCopyablePhoneNumber
              text={customerData.formatted_contact_phone_number}
            />
          </BriefingItem>
        )}

        <BriefingItem_SecondaryPhones customerId={customerId} />

        {!customerData.PrimaryContactEmail ? null : (
          <BriefingItem label="Primary Email">
            <LinkedCopyableEmail text={customerData.PrimaryContactEmail} />
          </BriefingItem>
        )}

        <BriefingItem_SecondaryEmails customerId={customerId} />

        <BriefingVolumeTimeSeries customerId={customerId} />
        <TextBriefingItem
          label="New"
          text={customerData.is_new_customer ? "Yes" : "No"}
        />
        <TextBriefingItem
          label="National"
          text={customerData.is_national_account ? "Yes" : "No"}
        />
        {!customerData.NationalAccountOwner ? null : (
          <TextBriefingItem
            label="NA Owner"
            text={customerData.NationalAccountOwner}
          />
        )}
        {!customerData.NationalAccountLeader ? null : (
          <TextBriefingItem
            label="NA Leader"
            text={customerData.NationalAccountLeader}
          />
        )}
        <TextBriefingItem label="Total PVWs" text={customerData.lwvr__cum_pv} />
        <TextBriefingItem
          label="Credit Limit"
          text={moneyVF(customerData.CustomerCreditLimit)}
        />
        <TextBriefingItem
          label="Billing State"
          text={customerData.BillingState}
        />
        <TextBriefingItem
          label="Value At Risk"
          text={moneyVF(customerData.value_at_risk)}
        />
        <TextBriefingItem label="Branch Name" text={customerData.branch_name} />
        <TextBriefingItem
          label="Project-Based"
          text={customerData.is_project_based ? "Yes" : "No"}
        />
        <TextBriefingItem
          label="Customer Status"
          text={customerData.CustomerStatus}
        />
        <TextBriefingItem
          label="4WK Avg Volume"
          text={moneyVF(customerData["lwvr__R_MEAN(Amount, 4)"])}
        />
        <TextBriefingItem
          label="Customer Created"
          text={dateVF(customerData.CustomerCreatedOnDate)}
        />
        <TextBriefingItem
          label="Current PVW Streak"
          text={customerData.lwvr__consec_pv}
        />
        <TextBriefingItem
          label="Longest PVW Streak"
          text={customerData["lwvr__CUMMAX(consec_pv)"]}
        />
        <TextBriefingItem
          label="Service Segmentation"
          text={customerData.ServiceSegmentation}
        />
        <TextBriefingItem
          label="Vertical Segmentation"
          text={customerData.VerticalSegmentation}
        />
        <TextBriefingItem
          label="Revenue Segmentation"
          text={customerData.RevenueSegmentation}
        />
        <TextBriefingItem
          label="Volume Since Jan 2018"
          text={moneyVF(customerData.lwvr__cum_v)}
        />
        <TextBriefingItem
          label="# Weeks Since 1st PVW"
          text={customerData["lwvr__CUMSUM(cum_v > 0)"]}
        />
      </Space>
    </Col>
  );
}

function BriefingBodyRight({
  customerId,
}: {
  customerId: number;
}): JSX.Element {
  return (
    <Col
      xs={12}
      css={`
        margin-top: 12px;
        margin-bottom: 12px;
        height: 100%;
        width: 100%;
        min-width: 300px;
        overflow-y: scroll;
      `}
    >
      <CustomerCallScript customerId={customerId} includeTitle={false} />
    </Col>
  );
}

function BriefingVolumeTimeSeries({
  customerId,
}: {
  customerId: number;
}): JSX.Element {
  const viewport = useWindowSize();
  const totalDialogHorizontalPadding = 40 + 36;

  let width = _.max([0.7 * viewport.width, 600]) - totalDialogHorizontalPadding;
  if (viewport.width >= 992) {
    width /= 2;
  }
  width = _.floor(width);

  const height = _.floor((0.8 * viewport.height) / 3);

  return <NapoLine customerId={customerId} height={338} width={width} />;
}

function BriefingItem_ChurnRiskScore({ customerId }) {
  const customerData = useCustomerData(customerId);
  return (
    <BriefingItem
      label="Churn Risk"
      css={`
        & .bp3-popover-wrapper {
          cursor: pointer;
          padding-left: 16px;
          width: 50%;
          display: inline-block;
        }
      `}
    >
      <ChuScoPopover
        churnScore={customerData.churn_risk_score}
        customerId={customerId}
        position={Position.BOTTOM}
      />
    </BriefingItem>
  );
}

const ZERO_PHONE_PATT = new RegExp("^0+$");

function BriefingItem_SecondaryPhones({ customerId }) {
  const customerData = useCustomerData(customerId);
  const primaryPhone = customerData.formatted_contact_phone_number;
  const secondaryPhones = _.uniq<string>(
    _.split(customerData.formatted_secondary_phone_numbers, ",").filter(
      (v) => !!(v && v !== primaryPhone && !ZERO_PHONE_PATT.test(v))
    )
  );
  return !_.size(secondaryPhones) ? null : (
    <BriefingItem
      label={`Secondary Phone${_.size(secondaryPhones) > 1 ? "s" : ""}`}
    >
      <LinkedCopyablePhoneNumber
        text={secondaryPhones[0]}
        otherTexts={secondaryPhones.slice(1)}
      />
    </BriefingItem>
  );
}

function BriefingItem_SecondaryEmails({ customerId }) {
  const customerData = useCustomerData(customerId);
  const primaryEmail = customerData.PrimaryContactEmail;
  const secondaryEmails = _.uniq<string>(
    _.split(customerData.SecondaryContactEmail, ",")
      .map((v) => v.trim())
      .filter((v) => !!(v && v !== primaryEmail))
  );
  return !_.size(secondaryEmails) ? null : (
    <BriefingItem
      label={`Secondary Email${_.size(secondaryEmails) > 1 ? "s" : ""}`}
    >
      <LinkedCopyableEmail
        text={secondaryEmails[0]}
        otherTexts={secondaryEmails.slice(1)}
      />
    </BriefingItem>
  );
}

function TextBriefingItem({ label, text, ...restProps }): JSX.Element {
  return (
    <BriefingItem label={label} {...restProps}>
      <span>{text}</span>
    </BriefingItem>
  );
}

function BriefingItem({ label, children, ...restProps }): JSX.Element {
  return (
    <div className="briefing-item" {...restProps}>
      <span
        className="briefing-item--label bp3-text-muted"
        css={`
          display: inline-block;
          margin-right: 12px;
        `}
      >
        {label}:
      </span>
      {children}
    </div>
  );
}

type ExtraInfoElement = JSX.Element;
// type ExtraInfoElement = ReactNode | JSX.Element;
type ExtraInfoElements = ExtraInfoElement[];

function LinkedCopyablePhoneNumber({
  text,
  otherTexts,
  excludeClipboardIcon,
  tooltipPosition,
  ...restProps
}: {
  text: string;
  otherTexts?: string[];
  excludeClipboardIcon?: boolean;
  tooltipPosition?: PopoverPosition;
  extraInfoElements?: ExtraInfoElements;
}): JSX.Element {
  const formatText = (v) => v;
  const formatAnchorButtonHref = (t, ft) => `tel:${t}`;
  const anchorTooltipContent = "Call";
  return (
    <LinkedCopyableContactInfo
      key={text}
      formatText={formatText}
      formatAnchorButtonHref={formatAnchorButtonHref}
      anchorTooltipContent={anchorTooltipContent}
      text={text}
      otherTexts={otherTexts}
      excludeClipboardIcon={excludeClipboardIcon}
      tooltipPosition={tooltipPosition}
      {...restProps}
    />
  );
}

function LinkedCopyableEmail({
  text,
  otherTexts,
  excludeClipboardIcon,
  tooltipPosition,
  ...restProps
}: {
  text: string;
  otherTexts?: string[];
  excludeClipboardIcon?: boolean;
  tooltipPosition?: PopoverPosition;
  extraInfoElements?: ExtraInfoElements;
}): JSX.Element {
  const formatAnchorButtonHref = (t, ft) => `mailto:${t}`;
  const anchorTooltipContent = "Email";
  return (
    <LinkedCopyableContactInfo
      formatAnchorButtonHref={formatAnchorButtonHref}
      anchorTooltipContent={anchorTooltipContent}
      text={text}
      otherTexts={otherTexts}
      excludeClipboardIcon={excludeClipboardIcon}
      tooltipPosition={tooltipPosition}
      {...restProps}
    />
  );
}

function LinkedCopyableContactInfo({
  text,
  otherTexts,
  excludeClipboardIcon,
  tooltipPosition,
  formatText = (v) => v,
  formatAnchorButtonHref,
  anchorTooltipContent,
  extraInfoElements,
  extraInfoTooltipProps,
  ...restProps
}: {
  text: string;
  otherTexts?: string[];
  excludeClipboardIcon?: boolean;
  tooltipPosition?: PopoverPosition;
  formatText?: { (text: string): string };
  formatAnchorButtonHref: { (text: string, formattedText: string): string };
  anchorTooltipContent: string | JSX.Element;
  extraInfoElements?: ExtraInfoElements;
  extraInfoTooltipProps?: any;
}): JSX.Element {
  const formattedText = formatText(text);

  return (
    <ButtonGroup>
      <StyledTooltip
        // interactionKind={PopoverInteractionKind.HOVER_TARGET_ONLY}
        position={tooltipPosition}
        content={anchorTooltipContent}
      >
        <AnchorButton
          minimal
          intent={Intent.PRIMARY}
          href={formatAnchorButtonHref(text, formattedText)}
          target="_blank"
          css={
            excludeClipboardIcon
              ? ""
              : css`
                  margin-right: -8px !important;
                `
          }
          {...restProps}
        >
          {formattedText}
        </AnchorButton>
      </StyledTooltip>
      {excludeClipboardIcon ? null : <ClipboardIcon text={formattedText} />}
      {!extraInfoElements ? null : (
        <ExtraInfoIcon
          element={extraInfoElements[0]}
          tooltipProps={extraInfoTooltipProps}
        />
      )}
      {!!(otherTexts && _.size(otherTexts)) && (
        <Popover
          autoFocus={false}
          canEscapeKeyClose
          content={
            <Menu
              css={`
                max-height: 50vh;
                overflow-y: auto;
              `}
            >
              {otherTexts.map((v, idx) => (
                <MenuItem
                  key={idx}
                  disabled
                  shouldDismissPopover={true}
                  tagName="span"
                  text={
                    <LinkedCopyableContactInfo
                      text={v}
                      excludeClipboardIcon={false}
                      tooltipPosition={PopoverPosition.LEFT}
                      formatText={formatText}
                      formatAnchorButtonHref={formatAnchorButtonHref}
                      anchorTooltipContent={anchorTooltipContent}
                      extraInfoElements={
                        null
                        // extraInfoElements && [extraInfoElements[idx + 1]]
                      }
                      extraInfoTooltipProps={extraInfoTooltipProps}
                    />
                  }
                  labelElement={
                    /*
                    <ClipboardIcon
                      text={formatText(v)}
                      tooltipProps={{ position: PopoverPosition.RIGHT }}
                    />
                     */
                    !extraInfoElements ? (
                      ""
                    ) : (
                      <ExtraInfoIcon
                        element={extraInfoElements[idx + 1]}
                        tooltipProps={extraInfoTooltipProps}
                      />
                    )
                  }
                  css={`
                    display: flex;
                    justify-content: flex-end;
                  `}
                />
              ))}
            </Menu>
          }
          position={Position.RIGHT_TOP}
        >
          <Tooltip
            content={`Show ${_.size(otherTexts)} other${
              _.size(otherTexts) > 1 ? "s" : ""
            }`}
          >
            <Button minimal intent={Intent.PRIMARY} icon="caret-down" />
          </Tooltip>
        </Popover>
      )}
    </ButtonGroup>
  );
}

function ClipboardIcon({
  text,
  tooltipProps,
}: {
  text: string;
  tooltipProps?: any;
}) {
  const clipboard = useClipboard({
    copiedTimeout: 500,
    onSuccess: () => toaster.success(`Copied "${text}" to clipboard`, 1),
  });
  const onClickIcon = () => clipboard.copy(text);
  return (
    <StyledTooltip content="Copy" {...(tooltipProps ?? {})}>
      <Button
        minimal
        intent={Intent.PRIMARY}
        icon="clipboard"
        onClick={onClickIcon}
      />
    </StyledTooltip>
  );
}
function ExtraInfoIcon({
  element,
  tooltipProps,
}: {
  element: ExtraInfoElement;
  tooltipProps?: any;
}) {
  const isOpen = useBoolean(false);
  const ElementComponent = (
    <div
      css={`
        width: 100%;
        height: 100%;
      `}
    >
      <Button
        minimal
        intent={Intent.DANGER}
        icon="cross"
        onClick={isOpen.setFalse}
        css={`
          position: absolute;
          top: 0;
          right: 0;
        `}
      />
      <div
        css={`
          width: 100%;
          height: 100%;
        `}
      >
        {element}
      </div>
    </div>
  );
  return (
    <Popover
      isOpen={isOpen.value}
      content={ElementComponent}
      position={Position.RIGHT}
      interactionKind={PopoverInteractionKind.CLICK}
      boundary={"viewport"}
    >
      <StyledTooltip content="Source Job Order Info" {...(tooltipProps ?? {})}>
        <Button
          minimal
          intent={Intent.PRIMARY}
          icon="info-sign"
          onClick={isOpen.toggle}
        />
      </StyledTooltip>
    </Popover>
  );
}

function StyledTooltip({
  position,
  children,
  ...restProps
}: {
  position?: PopoverPosition;
  children: JSX.Element;
}): JSX.Element {
  position = position ?? PopoverPosition.TOP;
  return (
    <Tooltip
      position={position}
      interactionKind={PopoverInteractionKind.HOVER_TARGET_ONLY}
      // css={`
      //   .bp3-transition-container {
      //     &&& {
      //       z-index: 1001;
      //     }
      //   }
      // `}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
}

function BriefingItem_RecentJobOrderContacts({ customerId }) {
  const { loading, data } = useEndpoint(
    `recent_joborder_contacts/${customerId}`
  );
  return loading ? (
    <Spinner />
  ) : !(data && _.size(data)) ? null : (
    <BriefingItem_RecentJobOrderContactsInner
      customerId={customerId}
      recentJoborderContacts={data}
    />
  );
}

function BriefingItem_RecentJobOrderContactsInner({
  customerId,
  recentJoborderContacts,
}) {
  const phoneNumbers = recentJoborderContacts.map(
    (v) => v.formatted_contact_phone ?? v.formatted_contact_mobile
  );

  const extraInfoElements = recentJoborderContacts.map((v) => {
    const createdDate = v.created_date && moment(v.created_date);

    const expirationDate = v.expiration_date && moment(v.expiration_date);
    const expirationDateIsInFuture =
      expirationDate && expirationDate > moment();

    const effectiveDate = v.effective_date && moment(v.effective_date);

    const infoItems = [
      [`Created`, `${createdDate.format("MM/DD/YYYY")}`],
      expirationDate && [
        `Expire${expirationDateIsInFuture ? "s" : "d"}`,
        `${expirationDate.format("MM/DD/YYYY")}`,
      ],
      effectiveDate && [`Effective`, `${effectiveDate.format("MM/DD/YYYY")}`],
      //
      [
        `Contact Phone`,
        `${v.formatted_contact_phone ?? v.formatted_contact_mobile}`,
      ],
      v.contact_name && [`Contact Name`, `${v.contact_name}`],
      v.contact_type_name && [`Contact Type`, `${v.contact_type_name}`],
      v.contact_email && [`Contact Email`, `${v.contact_email}`],
      v.contact_title && [`Contact Title`, `${v.contact_title}`],
      v.contact_prism_id && [`Contact Prism ID`, `${v.contact_prism_id}`],
      //
      (v.jobsite_city || v.jobsite_state) && [
        `Job-Site Location`,
        `${[v.jobsite_city, v.jobsite_state].filter((x) => !!x).join(", ")}`,
      ],
      (v.jurisdiction_city || v.jurisdiction_state) && [
        `Jurisdiction Location`,
        `${[v.jurisdiction_city, v.jurisdiction_state]
          .filter((x) => !!x)
          .join(", ")}`,
      ],
      v.branch_number && [`Branch`, `${v.branch_number}`],
      //
      v.job_duty && [`Job Duty`, `${v.job_duty}`],
      v.job_type && [`Job Type`, `${v.job_type}`],
      v.work_type && [`Work Type`, `${v.work_type}`],
      v.work_category && [`Work Category`, `${v.work_category}`],
      //
      v.joborder_number && [`Job Order Number`, `${v.joborder_number}`],
    ].filter((x) => !!x);

    return (
      <div
        css={`
          padding: 13px 24px 23px 24px;
          max-height: 70vh;
          overflow-y: auto;
          & > * {
            padding-top: 8px;
            padding-bottom: 8px;
          }
        `}
      >
        {infoItems.map(([label, text], idx) => (
          <TextBriefingItem key={label} label={label} text={text} />
        ))}
      </div>
    );
  });

  return !_.size(phoneNumbers) ? null : (
    <BriefingItem label="Job Order Contacts">
      <LinkedCopyablePhoneNumber
        key={"joborder-contacts--" + phoneNumbers[0]}
        text={phoneNumbers[0]}
        otherTexts={phoneNumbers.slice(1)}
        extraInfoElements={extraInfoElements}
      />
    </BriefingItem>
  );
}

function BriefingItem_AtoContacts({ customerId }) {
  const { loading, data } = useEndpoint(`good_contacts__ato/${customerId}`);
  return loading ? (
    <Spinner />
  ) : !(data && _.size(data)) ? null : (
    <BriefingItem_AtoContactsInner customerId={customerId} contacts={data} />
  );
}

function BriefingItem_AtoContactsInner({ customerId, contacts }) {
  const phoneNumbers = contacts.map((v) => v.phone__validated_n_formatted);

  const extraInfoElements = contacts.map((v) => {
    const createdDate = v.ContactCreatedDate && moment(v.ContactCreatedDate);

    const recentFlag: boolean = v.RecentFlag;
    const activeFlag: boolean = v.ActiveFlag;

    const updatedDate = v.ContactUpdatedDate && moment(v.ContactUpdatedDate);

    const infoItems = [
      v.CustomerContactName && [`Name`, `${v.CustomerContactName}`],
      v.Title && [`Title`, `${v.Title}`],
      (v.City || v.State) && [
        `Location`,
        `${[v.City, v.State].filter((x) => !!x).join(", ")}`,
      ],
      //
      createdDate && [`Created`, `${createdDate.format("MM/DD/YYYY")}`],
      updatedDate && [`Updated`, `${updatedDate.format("MM/DD/YYYY")}`],
      [`Recent?`, `${recentFlag ? "Yes" : "No"}`],
      [`Active?`, `${activeFlag ? "Yes" : "No"}`],
      //
      v.CustomerContactEmailAddress && [
        `Email`,
        `${v.CustomerContactEmailAddress}`,
      ],
      v.POSCustomerContactId && [`Prism ID`, `${v.POSCustomerContactId}`],
      //
      v.CustomerContactTypeName && [`Type`, `${v.CustomerContactTypeName}`],
      [`Phone`, `${v.phone__validated_n_formatted}`],
    ].filter((x) => !!x);

    return (
      <div
        css={`
          padding: 13px 24px 23px 24px;
          max-height: 70vh;
          overflow-y: auto;
          & > * {
            padding-top: 8px;
            padding-bottom: 8px;
          }
        `}
      >
        {infoItems.map(([label, text], idx) => (
          <TextBriefingItem key={label} label={label} text={text} />
        ))}
      </div>
    );
  });

  return !_.size(phoneNumbers) ? null : (
    <BriefingItem label="ATO Contacts">
      <LinkedCopyablePhoneNumber
        key={"ato-contacts--" + phoneNumbers[0]}
        text={phoneNumbers[0]}
        otherTexts={phoneNumbers.slice(1)}
        extraInfoElements={extraInfoElements}
        extraInfoTooltipProps={{ content: "Details" }}
      />
    </BriefingItem>
  );
}
