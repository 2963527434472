import { Button, H5, H6 } from "@blueprintjs/core";
import React, { useRef } from "react";
import "styled-components/macro";
import { Tooltip } from "antd";
import { useRaPayload2 } from "./modal-or-drawer";
import { useStoreActions } from "../../hooks/ep";
import { ActionPayload_ReportCustomerInteraction } from "../../user-actions/report-customer-interaction";

export function FormBase({ children, formState, formActions }) {
  const { snapshot, actionName } = useRaPayload2();
  const handleClose = useStoreActions((a) => a.misc.ra_closeModal);
  const isSubmitted = useRef(false);

  const handleReport = useStoreActions(
    (a) => a.customerInteractions.handleReportCustomerInteraction
  );
  const constructActionPayload = (
    formData
  ): ActionPayload_ReportCustomerInteraction => ({
    customer_id: snapshot.customer_id,
    customer_snapshot: { ...snapshot },
    form_payload: { ...formData },
  });

  const onSubmit = async (ev) => {
    ev.preventDefault();
    if (isSubmitted.current) {
      // No double-submissions!
      console.error("Already submited");
    } else {
      // console.log("constructActionPayload", {
      //   snapshot,
      //   formData: formState.formData,
      // });
      const actionPayload = constructActionPayload(formState.formData);
      // console.log("actionPayload", actionPayload);
      try {
        isSubmitted.current = true;
        await handleReport(actionPayload);
        handleClose();
      } catch (err) {
        isSubmitted.current = false;
      }
    }
  };

  const readyToSubmit = !formState.errorMessage;
  const notReadyToSubmit = !readyToSubmit;
  // console.log({
  //   notReadyToSubmit,
  //   "formState.errorMessage": formState.errorMessage,
  // });

  return (
    <form
      onSubmit={onSubmit}
      css={`
        width: 100%;
        //height: 100%;
        //min-height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        & > * {
          margin-bottom: 32px !important;
        }
        & > *:last-child {
          margin-bottom: unset !important;
        }
      `}
    >
      <H6>{snapshot.CustomerName}</H6>
      {children}
      <Tooltip
        placement="bottom"
        title={
          formState.errorMessage && (
            <span data-testid="report_action_form__submit_button_tooltip_title">
              {formState.errorMessage}
            </span>
          )
        }
        mouseEnterDelay={0.1}
        mouseLeaveDelay={0.1}
      >
        <span>
          <Button
            fill
            type="submit"
            intent="primary"
            data-testid="report_action_form__submit_button"
            disabled={notReadyToSubmit || isSubmitted.current}
          >
            Submit
          </Button>
        </span>
      </Tooltip>
    </form>
  );
}
