import { UserRole } from "../store/model-me";

const SAXECAP_DEV_ROUTES_PATT = RegExp("^/.*$");
const ADMIN_ROUTES_PATT = SAXECAP_DEV_ROUTES_PATT;
export const EMPLOYEE_ROUTES_PATT = SAXECAP_DEV_ROUTES_PATT;
export const ROLE_ALLOWED_ROUTES_PATTS = {
  [UserRole.SAXECAP_DEV]: SAXECAP_DEV_ROUTES_PATT,
  [UserRole.ADMIN_READ_WRITE]: ADMIN_ROUTES_PATT,
  [UserRole.ADMIN_READ_ONLY]: ADMIN_ROUTES_PATT,
  [UserRole.NA_OWNER_LEADER]: EMPLOYEE_ROUTES_PATT,
  [UserRole.NA_SUPPORT]: EMPLOYEE_ROUTES_PATT,
  [UserRole.NA_SUPPORT_2]: EMPLOYEE_ROUTES_PATT,
  [UserRole.DEFAULT]: EMPLOYEE_ROUTES_PATT,
};
export const USER_EXTRA_ALLOWED_ROUTES_PATTS = {};
