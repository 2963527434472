import { actions } from "./commonActions";
import { thunks } from "./commonThunks";
import preadyModel, { PreadyModel } from "./model-pready";
import { StarsModel } from "./model-stars";
import { FirebaseModel, firebaseModel } from "./model-firebase";
import { meModel, MeModel } from "./model-me";
import miscModel, { MiscModel } from "./model-misc";
import { tableModels, TableModelSliceName } from "./table-models";
import { CustomerInteractionsModel } from "./model-customer-interactions";
import { InvoiceCreditsModel } from "./model-invoice-credits";

export interface Injections {}

export interface EmailPassword {
  email: string;
  password: string;
}

// The interface representing our entire store model
export interface StoreModel {
  me: MeModel;
  pready: PreadyModel;
  invoiceCredits: InvoiceCreditsModel;
  firebase: FirebaseModel;
  stars: StarsModel;
  misc: MiscModel;
  customerInteractions: CustomerInteractionsModel;
}

const model: StoreModel = {
  me: meModel,
  pready: preadyModel,
  firebase: firebaseModel,
  stars: tableModels.stars,
  misc: miscModel,
  invoiceCredits: tableModels.invoiceCredits,
  customerInteractions: tableModels.customerInteractions,
};

export type BackendConnectedModelSliceName =
  | TableModelSliceName
  | "me"
  | "pready"
  | "invoiceCredits"
  | "stars";

export type ModelSliceName =
  | BackendConnectedModelSliceName
  | "firebase"
  | "misc";

function isResourceModel(modelSlice) {
  return !!modelSlice.INITIAL_DATA_ENDPOINT;
}

Object.entries(model).forEach(([modelSliceName, modelSlice]) => {
  if (!isResourceModel(modelSlice)) {
    return;
  }

  modelSlice.initialDataReceived = false;
  modelSlice.initialDataLoading = false;

  const myActions = actions();
  [
    "markInitialDataReceived",
    "markInitialDataNotReceived",
    "markInitialDataLoading",
    "markInitialDataNotLoading",
    "receiveInitialData",
    "clear",
  ].forEach((actionName) => {
    if (!modelSlice[actionName]) {
      modelSlice[actionName] = myActions[actionName];
    }
  });

  const myThunks = thunks(modelSliceName);
  ["handleFetchInitialData", "maybeHandleFetchInitialData"].forEach(
    (thunkName) => {
      if (!modelSlice[thunkName]) {
        modelSlice[thunkName] = myThunks[thunkName];
      }
    }
  );
});

export { model };
