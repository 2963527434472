import { FormStore } from "./form-store";
import React, { useState } from "react";
import { CustomerInteractionFormContent } from "./customer-interaction-form-content";
import "styled-components/macro";
import { FormBase } from "./form-base";
import { Drawer, Spinner } from "@blueprintjs/core";

import { DRAWER_WIDTH } from "../../styles/constants";
import { useStoreActions, useStoreState } from "../../hooks/ep";

export function ReportActionModalWrapper() {
  const isOpen = useIsReportActionFormOpen();
  return isOpen && <ReportActionView />;
}

export function useActionName() {
  const { actionName } = useRaPayload2();
  return actionName;
}

export function useRaPayload2() {
  const ra_payload = useStoreState((a) => a.misc.ra_payload);
  if (ra_payload === null) {
    return { snapshot: null, actionName: null, key: null };
  } else {
    const snapshot = ra_payload.snapshot;
    const actionName = ra_payload.actionName;
    const key =
      snapshot.customer_id.toString() +
      "|" +
      (snapshot.latestInteractionTimestamp ?? "");
    return { snapshot, actionName, key };
  }
}

export function useFormTitle() {
  const actionName = useActionName();
  if (actionName === "report_customer_interaction") {
    return "Tell us more about your customer interaction";
  } else {
    console.error(`In useFormTitle -- Unknown actionName=${actionName}`);
    return "";
  }
}

export function useIsReportActionFormOpen() {
  return !!useStoreState((a) => a.misc.ra_payload);
}

export function useFormStore() {
  const { key } = useRaPayload2();
  let formActions = FormStore.useStoreActions((a) => a[key], [key]);
  let formState = FormStore.useStoreState((s) => s[key], [key]);
  return [formState, formActions];
}

function MyFormStoreProvider({ children }) {
  const { actionName, key } = useRaPayload2();
  return (
    <FormStore.Provider
      initialData={{
        key,
        actionName,
      }}
    >
      {children}
    </FormStore.Provider>
  );
}

function ReportActionFormContent() {
  const rehydrated = FormStore.useStoreRehydrated();
  return rehydrated ? <ModalContentInner /> : <Spinner />;
}

function ModalContentInner() {
  const actionName = useActionName();
  const [formState, formActions] = useFormStore();
  const [activeField, setActiveField] = useState(null);

  let formContent;
  if (actionName === "report_customer_interaction") {
    formContent = (
      <CustomerInteractionFormContent
        formState={formState}
        formActions={formActions}
        activeField={activeField}
        setActiveField={setActiveField}
      />
    );
  } else {
    throw Error();
  }

  return (
    <FormBase formState={formState} formActions={formActions}>
      {formContent}
    </FormBase>
  );
}

function ReportActionDrawer() {
  const hideDrawer = useStoreActions((a) => a.misc.ra_closeModal);
  return (
    <Drawer
      canEscapeKeyClose={false}
      isCloseButtonShown={true}
      isOpen={useIsReportActionFormOpen()}
      onClose={hideDrawer}
      className="bp3-dark"
      size={DRAWER_WIDTH}
      title={useFormTitle()}
    >
      <div
        css={`
          padding: 24px;
          overflow-y: auto;
        `}
      >
        <MyFormStoreProvider>
          <ReportActionFormContent />
        </MyFormStoreProvider>
      </div>
    </Drawer>
  );
}

export const ReportActionView = ReportActionDrawer;
