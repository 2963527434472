import React from "react";
import "styled-components/macro";
import { SimpleRadioGroup } from "../simple-radio";

export type CustomerSentimentValue = "Negative" | "Neutral" | "Positive";
export const ALL_CUSTOMER_SENTIMENT_VALUES: CustomerSentimentValue[] = [
  "Negative",
  "Neutral",
  "Positive",
];
const FIELD_LABEL = "Customer Sentiment";
const FIELD_ID = "customerSentiment";
const HELPER_TEXT =
  "How would you classify your customer’s attitude towards PeopleReady?";

export function CustomerSentimentInput({
  value,
  onValueChange,
  activeField,
  setActiveField,
}): JSX.Element {
  return (
    <SimpleRadioGroup
      selectedValue={value}
      setSelectedValue={onValueChange}
      allValues={ALL_CUSTOMER_SENTIMENT_VALUES}
      fieldLabel={FIELD_LABEL}
      fieldHelperText={HELPER_TEXT}
      fieldId={FIELD_ID}
      activeFieldId={activeField}
      setActiveFieldId={setActiveField}
    />
  );
}
