import { useEnsureData } from "./helpers/use-ensure-data";
import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useStoreState } from "./hooks/ep";
import { TableRow } from "./store/readonly-table-model-factory";
import "styled-components/macro";
import { CustomerCallScript } from "./customer-call-script";

export function DevPage() {
  const loading = useEnsureData("pready");
  return loading ? <Spinner /> : <DevPageInner />;
}

export type CustomerId = number;

function DevPageInner() {
  const [selectedCustomerId, setSelectedCustomerId] = useState<CustomerId>(0);
  return (
    <div
      css={`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      `}
    >
      <CustomerIdSelect
        selectedCustomerId={selectedCustomerId}
        setSelectedCustomerId={setSelectedCustomerId}
      />
      {(selectedCustomerId || "") && (
        <CustomerCallScript customerId={selectedCustomerId} />
      )}
    </div>
  );
}

function CustomerIdSelect({
  selectedCustomerId,
  setSelectedCustomerId,
}): JSX.Element {
  const customers: TableRow[] = useStoreState((s) => s.pready.rowData).slice(
    0,
    20
  );

  useEffect(() => {
    setSelectedCustomerId(customers[0].customer_id);
  }, []);

  return (
    <div
      css={`
        width: fit-content;
        &&& select {
          background-color: transparent;
          border: none;
          color: white;
        }
      `}
    >
      <select
        value={selectedCustomerId}
        onChange={(ev) => {
          ev.preventDefault();
          setSelectedCustomerId(ev.target.value);
        }}
      >
        <option key={"null"} value={0}>
          Select...
        </option>
        {customers.map((row) => (
          <option
            key={row.customer_id}
            value={row.customer_id}
            selected={selectedCustomerId === row.customer_id}
          >
            {`${row.customer_id}: "${row.CustomerName}"`}
          </option>
        ))}
      </select>
    </div>
  );
}
