import {
  Action,
  action,
  computed,
  Computed,
  Thunk,
  thunkOn,
  ThunkOn,
} from "easy-peasy";
import { StoreModel, Injections } from "./model";
import { TableModelSliceName } from "./table-models";

export type TableRowId = string | number;
export type TableRow = { id?: TableRowId; [column: string]: any };
export type TableData = TableRow[];
export interface GetRowId {
  (row: TableRow): TableRowId;
}
export interface TableModel {
  NAME: TableModelSliceName;
  INITIAL_DATA_ENDPOINT: string;
  GET_ROW_ID: GetRowId;
  initialData: TableData;
  initialDataReceived: boolean;
  initialDataLoading: boolean;
  //
  resetData: Action<TableModel>;
  onLogout: ThunkOn<TableModel, Injections, StoreModel>;
  receiveInitialData: Action<TableModel, TableData>;
  upsertRow: Action<TableModel, TableRow>;
  deleteRow: Action<TableModel, TableRowId>;
  getRow: Computed<
    TableModel,
    (rowId: TableRowId) => undefined | TableRow,
    StoreModel
  >;
  //
  markInitialDataReceived: Action<TableModel>;
  markInitialDataNotReceived: Action<TableModel>;
  markInitialDataLoading: Action<TableModel>;
  markInitialDataNotLoading: Action<TableModel>;
  //
  maybeHandleFetchInitialData: Thunk<TableModel, any, Injections, StoreModel>;
  handleFetchInitialData: Thunk<TableModel, any, Injections, StoreModel>;
}

export function readonlyTableModelFactory(
  sliceName: TableModelSliceName,
  initialDataEndpoint: string,
  getRowId: GetRowId
): TableModel {
  return {
    NAME: sliceName,
    INITIAL_DATA_ENDPOINT: initialDataEndpoint,
    GET_ROW_ID: getRowId,
    initialData: [],
    initialDataReceived: false,
    initialDataLoading: false,
    resetData: action((state) => {
      state.initialData = [];
      state.initialDataReceived = false;
      state.initialDataLoading = false;
    }),
    onLogout: thunkOn(
      (__, { me }) => me.resetData,
      (actions) => {
        actions.resetData();
      }
    ),
    receiveInitialData: action((state, payload) => {
      state.initialData = payload.map((row) => ({
        ...row,
        id: getRowId(row),
      }));
    }),
    upsertRow: action((state, payload) => {
      payload = { ...payload, id: getRowId(payload) };
      state.initialData = [
        ...state.initialData.filter((row) => row.id !== payload.id),
        payload,
      ];
    }),
    deleteRow: action((state, rowId) => {
      state.initialData = state.initialData.filter((row) => row.id !== rowId);
    }),
    getRow: computed((state) => (rowId) => {
      return state.initialData.find((row) => row.id === rowId);
    }),
    //
    markInitialDataReceived: undefined,
    markInitialDataNotReceived: undefined,
    markInitialDataLoading: undefined,
    markInitialDataNotLoading: undefined,
    //
    maybeHandleFetchInitialData: undefined,
    handleFetchInitialData: undefined,
  };
}
