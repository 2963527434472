import { DataSnapshot, submitUserActionPostRequest } from "./user_actions";
import { CallTopic } from "../components/reporting-actions/call-topics-input";
import { CustomerIssue } from "../components/reporting-actions/customer-issues-input";
import { assert } from "../helpers/assertions";
import {
  ALL_CUSTOMER_SENTIMENT_VALUES,
  CustomerSentimentValue,
} from "../components/reporting-actions/customer-sentiment-input";
import {
  ALL_CHURN_LIKELIHOOD_VALUES,
  ChurnLikelihoodValue,
} from "../components/reporting-actions/churn-likelihood-input";
import {
  ALL_SALESFORCE_CASE_OPENED_VALUES,
  SalesforceCaseOpenedValue,
} from "../components/reporting-actions/salesforce-case-opened-input";
import {
  ALL_UNDER_SALESFORCE_REVIEW_VALUES,
  UnderSalesforceReviewValue,
} from "../components/reporting-actions/under-salesforce-review-input";
import {
  didNoInteractionOccur,
  didNoPhonecallOccur,
} from "../components/reporting-actions/form-error-messages";

export async function reportCustomerInteraction(
  actionPayload: ActionPayload_ReportCustomerInteraction
) {
  verifyActionPayload(actionPayload);
  return await submitUserActionPostRequest(
    "report_customer_interaction",
    actionPayload
  );
}

function verifyActionPayload(
  actionPayload: ActionPayload_ReportCustomerInteraction
): void {
  assert(typeof actionPayload.customer_id === typeof 12345);
  assert(
    actionPayload.customer_id === actionPayload.customer_snapshot.customer_id
  );
  assert(
    ALL_UNDER_SALESFORCE_REVIEW_VALUES.includes(
      actionPayload.form_payload
        .underSalesforceReview as UnderSalesforceReviewValue
    )
  );

  const isUnderSalesforceReview =
    actionPayload.form_payload.underSalesforceReview === "Yes";
  const interactionDidNotOccur = didNoInteractionOccur(
    actionPayload.form_payload.callTopics
  );
  const noPhonecallOccurred = didNoPhonecallOccur(
    actionPayload.form_payload.callTopics
  );
  const isActualHumanInteraction = !(
    isUnderSalesforceReview || interactionDidNotOccur
  );
  if (isActualHumanInteraction) {
    assert(
      ALL_CUSTOMER_SENTIMENT_VALUES.includes(
        actionPayload.form_payload.customerSentiment as CustomerSentimentValue
      )
    );
    assert(
      ALL_CHURN_LIKELIHOOD_VALUES.includes(
        actionPayload.form_payload.churnLikelihood as ChurnLikelihoodValue
      )
    );
    assert(
      ALL_SALESFORCE_CASE_OPENED_VALUES.includes(
        actionPayload.form_payload
          .salesforceCaseOpened as SalesforceCaseOpenedValue
      )
    );
  }

  if (actionPayload.form_payload.salesforceCaseOpened === "Yes") {
    assert(!!actionPayload.form_payload.salesforceCaseUrl);
  }

  if (interactionDidNotOccur && !noPhonecallOccurred) {
    assert(!!actionPayload.form_payload.phone);
  }
}

export interface ActionPayload_ReportCustomerInteraction {
  customer_id: number;
  form_payload: {
    callTopics: CallTopic[];
    customerIssues: CustomerIssue[];
    notes: string;
    phone: string;
    attendees: string;
    customerSentiment: string | CustomerSentimentValue;
    churnLikelihood: string | ChurnLikelihoodValue;
    salesforceCaseOpened: string | SalesforceCaseOpenedValue;
    salesforceCaseUrl: string;
    underSalesforceReview: string | UnderSalesforceReviewValue;
  };
  customer_snapshot: DataSnapshot;
}
