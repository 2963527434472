import React, {
  ComponentType,
  MouseEventHandler,
  useCallback,
  useState,
} from "react";
import "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import * as COLORS from "../../styles/colors";
import { Button, Col, Row, Tooltip } from "antd";
import {
  AG_TABLE_RIBBON_BUTTON_IS_GHOST,
  AG_TABLE_RIBBON_BUTTON_SIZE,
  AG_TABLE_RIBBON_BUTTON_TOOLTIP_DELAY,
  AG_TABLE_RIBBON_BUTTON_TYPE,
  AG_TABLE_RIBBON_HEIGHT_PX,
  AG_TABLE_RIBBON_HORIZONTAL_GUTTER,
} from "../../styles/constants";
import { Colors, Divider, H5, Tag } from "@blueprintjs/core";
import { RbnSearchBox } from "./search-box";
import { useGridBorderRadius } from "./use-grid-border-radius";
import { useStoreActions, useStoreState } from "../../hooks/ep";
import { TooltipPlacement } from "antd/es/tooltip";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { ButtonType } from "antd/lib/button/button";
import { AgColDefs, AgGlobals, AgRows } from "./types";
import {
  ColumnWidthOutlined,
  DownloadOutlined,
  DragOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
} from "@ant-design/icons";
import { TableRow } from "../../store/readonly-table-model-factory";
import _ from "lodash";
import { toaster } from "../../toaster";
import { Intent } from "@blueprintjs/core/lib/esm/common/intent";
import { resetAllFilters } from "./my-ag-grid-react";

const backgroundColor = Colors.BLUE1;
const activeBtnBgColor = "rgba(0, 0, 0, 0.1)";

export function Ribbon({
  g,
  rowData,
  colDefs,
  tableName,
  customRibbonLeftElements,
  fullScreenEnabled,
  ribbonTitle,
  hideEyeball,
  hideSearchbox,
}: {
  g: AgGlobals;
  rowData: AgRows;
  colDefs: AgColDefs;
  customRibbonLeftElements: JSX.Element[];
  tableName: string;
  fullScreenEnabled?: boolean;
  ribbonTitle?: string | JSX.Element;
  hideEyeball?: boolean;
  hideSearchbox?: boolean;
}) {
  const brad = useGridBorderRadius();
  return (
    <Row
      data-testid={`${tableName}_table_ribbon`}
      type="flex"
      align="middle"
      justify="space-between"
      css={`
        transition: background 0.2s linear 0.2s;
        background: ${backgroundColor};
        padding: 0 ${AG_TABLE_RIBBON_HORIZONTAL_GUTTER}px;
        margin: 0;
        height: ${AG_TABLE_RIBBON_HEIGHT_PX};
        flex-wrap: wrap;
        border-radius: ${brad} ${brad} 0 0;
        & .ribbon-divider {
          height: 16px;
        }
        & .ribbon-divider-right {
          margin-left: 12px;
          margin-right: 12px;
        }
      `}
    >
      <RibbonLeft
        g={g}
        fullScreenEnabled={fullScreenEnabled}
        rowData={rowData}
        customRibbonLeftElements={customRibbonLeftElements}
        ribbonTitle={ribbonTitle}
      />
      <RibbonRight
        g={g}
        fullScreenEnabled={fullScreenEnabled}
        rowData={rowData}
        colDefs={colDefs}
        customRibbonLeftElements={customRibbonLeftElements}
        ribbonTitle={ribbonTitle}
        hideEyeball={hideEyeball}
        hideSearchbox={hideSearchbox}
      />
    </Row>
  );
}

function RibbonLeft({
  g,
  fullScreenEnabled,
  rowData,
  customRibbonLeftElements,
  ribbonTitle,
}: {
  g: AgGlobals;
  fullScreenEnabled?: boolean;
  rowData: TableRow[];
  customRibbonLeftElements: JSX.Element[];
  ribbonTitle?: string | JSX.Element;
}) {
  return (
    <Col>
      <Row
        type="flex"
        justify="start"
        align="middle"
        gutter={AG_TABLE_RIBBON_HORIZONTAL_GUTTER}
        css={`
          margin: 0;
        `}
      >
        {ribbonTitle ? (
          _.isString(ribbonTitle) ? (
            <H5
              css={`
                vertical-align: center;
                margin-left: 12px;
                margin-bottom: 0;
              `}
            >
              {ribbonTitle}
            </H5>
          ) : (
            ribbonTitle
          )
        ) : (
          <RibbonWidgets
            g={g}
            fullScreenEnabled={fullScreenEnabled}
            rowData={rowData}
            customRibbonLeftElements={customRibbonLeftElements}
          />
        )}
      </Row>
    </Col>
  );
}

function FullScreenBtn(): JSX.Element {
  const enabled = useStoreState((s) => s.misc.fullScreenModeEnabled);
  // noinspection JSUnresolvedVariable
  const toggle = useStoreActions((a) => a.misc.toggleFullScreenMode);

  return (
    <RbnBtn
      title={(enabled ? "Exit" : "Enter") + " full-screen mode"}
      icon={enabled ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
      onClick={useCallback(() => toggle(), [toggle])}
    />
  );
}

function RibbonWidgets({
  g,
  fullScreenEnabled,
  rowData,
  customRibbonLeftElements,
}: {
  g: AgGlobals;
  fullScreenEnabled?: boolean;
  rowData: TableRow[];
  customRibbonLeftElements: JSX.Element[];
}) {
  return (
    <>
      <RbnBtn
        title="Download as CSV"
        icon={<DownloadOutlined />}
        onClick={g.exportToCsv}
      />
      <RbnBtn
        title="Auto-size columns"
        icon={<ColumnWidthOutlined />}
        onClick={g.autoSizeAll}
      />
      <RbnBtn
        title="Show/hide scrollbars (for click+drag scrolling)"
        icon={<DragOutlined />}
        onClick={g.toggleScrollbarsVisible}
      />
      {fullScreenEnabled && <FullScreenBtn />}
      {customRibbonLeftElements.length > 0 && (
        <Divider className={"ribbon-divider"} key="divider-1" />
      )}
      {customRibbonLeftElements.length > 0 && customRibbonLeftElements}
      <Divider className={"ribbon-divider"} key="divider-2" />
      <RowCounts g={g} rowData={rowData} />
    </>
  );
}

function RibbonMiddle({
  customRibbonLeftElements,
}: {
  customRibbonLeftElements: JSX.Element[];
}) {
  return (
    customRibbonLeftElements.length > 0 && (
      <Col>
        <Row
          type="flex"
          justify="start"
          align="middle"
          gutter={AG_TABLE_RIBBON_HORIZONTAL_GUTTER}
          css={`
            margin: 0;
          `}
        >
          {customRibbonLeftElements}
        </Row>
      </Col>
    )
  );
}

function RowCounts({
  g,
  rowData,
}: {
  g: AgGlobals;
  rowData: TableRow[];
}): JSX.Element {
  const originalRowCount = rowData.length;
  const displayedRowCount = g.displayedRowCount ?? rowData.length;
  const countsSame = originalRowCount === displayedRowCount;

  const onClick = () => {
    if (!countsSame) {
      // @ts-ignore
      g.setQuickFilterText("");
      resetAllFilters(g.gridRef.current);
      toaster.success("All filters have been reset", 1);
    }
  };

  return (
    <span
      className="ribbon-info-text"
      css={`
        margin-left: 12px;
      `}
    >
      <Tooltip
        title={
          countsSame
            ? "Number of rows"
            : "Fraction of rows displayed (click to reset all filters)"
        }
        placement={"topLeft" as TooltipPlacement}
        mouseEnterDelay={AG_TABLE_RIBBON_BUTTON_TOOLTIP_DELAY}
      >
        <Tag
          large
          minimal
          round
          interactive
          intent={Intent.NONE}
          icon={countsSame ? "th" : "th-filtered"}
          onClick={onClick}
        >
          {countsSame
            ? `${originalRowCount}`
            : `${displayedRowCount} / ${originalRowCount}`}
        </Tag>
      </Tooltip>
    </span>
  );
}

function RibbonRight({
  g,
  rowData,
  colDefs,
  ribbonTitle,
  fullScreenEnabled,
  customRibbonLeftElements,
  hideEyeball,
  hideSearchbox,
}: {
  g: AgGlobals;
  rowData: AgRows;
  colDefs: AgColDefs;
  ribbonTitle?: string | JSX.Element;
  fullScreenEnabled?: boolean;
  customRibbonLeftElements: JSX.Element[];
  hideEyeball?: boolean;
  hideSearchbox?: boolean;
}): JSX.Element {
  const showRibbonWidgets = !!ribbonTitle;
  const showSearchboxOrEyeball = !hideSearchbox || !hideEyeball;
  const ribbonWidgetsAreFurthestRight =
    showRibbonWidgets && !showSearchboxOrEyeball;
  const ribbonWidgetsAreNotFurthestRight =
    showRibbonWidgets && showSearchboxOrEyeball;
  return (
    <Col>
      <Row
        type="flex"
        justify="end"
        align="middle"
        gutter={AG_TABLE_RIBBON_HORIZONTAL_GUTTER}
        css={`
          margin: 0;
          & .ribbon-info-text {
            margin-right: ${ribbonWidgetsAreFurthestRight ? "12px" : "0"};
          }
        `}
      >
        {showRibbonWidgets && (
          <RibbonWidgets
            g={g}
            fullScreenEnabled={fullScreenEnabled}
            rowData={rowData}
            customRibbonLeftElements={customRibbonLeftElements}
          />
        )}
        {ribbonWidgetsAreNotFurthestRight && (
          <Divider className={"ribbon-divider ribbon-divider-right"} />
        )}
        {!hideSearchbox && (
          <Col>
            <RbnSearchBox rowData={rowData} onSearch={g.setQuickFilterText} />
          </Col>
        )}
        {!hideEyeball && (
          <RbnVisibilityBtn
            vmShown={g.vmShown}
            toggleVmShown={g.toggleVmShown}
            VisibilityModal={g.VisibilityModal}
          />
        )}
      </Row>
    </Col>
  );
}

function RbnBtn({
  icon,
  onClick,
  title,
  dataTestId,
  onMouseEnter,
  onMouseLeave,
  RootElement = Col,
  ghost = AG_TABLE_RIBBON_BUTTON_IS_GHOST,
  hoverColor = COLORS.secondary,
  size = AG_TABLE_RIBBON_BUTTON_SIZE as SizeType,
  tooltipDisabled = false,
  tooltipPlacement = "topLeft" as TooltipPlacement,
  type = AG_TABLE_RIBBON_BUTTON_TYPE,
}: {
  icon: JSX.Element;
  onClick: MouseEventHandler;
  title: string;
  RootElement?: ComponentType;
  dataTestId?: string;
  ghost?: boolean;
  hoverColor?: string;
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
  size?: SizeType;
  tooltipDisabled?: boolean;
  tooltipPlacement?: TooltipPlacement;
  type?: ButtonType;
}): JSX.Element {
  let content = (
    <Button
      data-testid={dataTestId}
      type={type}
      ghost={ghost}
      size={size}
      icon={icon}
      //
      // To allow the buttons to return to their un-hovered, original color after being clicked
      onMouseDown={useCallback((e) => e.preventDefault(), [])}
      //
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      css={`
        && {
          color: white;
          background: transparent;
          border: none;
          box-shadow: none;
        }
        &&:hover {
          transition: all 0.3s linear;
          color: ${hoverColor};
        }
        &&:active {
          background: ${activeBtnBgColor} !important;
        }
      `}
    />
  );

  if (!tooltipDisabled) {
    content = (
      <Tooltip
        title={title}
        placement={tooltipPlacement}
        mouseEnterDelay={AG_TABLE_RIBBON_BUTTON_TOOLTIP_DELAY}
      >
        {content}
      </Tooltip>
    );
  }
  return <RootElement>{content}</RootElement>;
}

function RbnVisibilityBtn({
  toggleVmShown,
}: {
  toggleVmShown: MouseEventHandler;
}): JSX.Element {
  const [icon, setIcon] = useState("eye");
  const onMouseEnter = useCallback(() => setIcon("eye-invisible"), [setIcon]);
  const onMouseLeave = useCallback(() => setIcon("eye"), [setIcon]);

  return (
    <RbnBtn
      tooltipPlacement={"topLeft"}
      title="Show/hide columns"
      icon={icon === "eye" ? <EyeOutlined /> : <EyeInvisibleOutlined />}
      onClick={toggleVmShown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
}

export { RbnBtn };
