import { AutoComplete } from "antd";
import "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import _ from "lodash";
import React, { useMemo } from "react";
import { AgRows } from "./types";
import { DataSourceItemType } from "antd/es/auto-complete";

const { Option } = AutoComplete;

export type OptionsT = JSX.Element[] | DataSourceItemType[];

export interface StringCounts {
  [key: string]: number;
}

function gatherOptions(rowData: AgRows): OptionsT {
  const stringCounts = gatherStrings(rowData);
  const sortedStrings = _.sortBy(
    Object.entries(stringCounts),
    ([str, cnt]) => -cnt
  );
  return sortedStrings.map(([str, cnt], idx) => (
    <Option key={idx} value={str} title={" " + _.toLower(str)}>
      <div
        css={`
          display: flex;
          justify-content: space-between;
        `}
      >
        {str} <span>{cnt}</span>
      </div>
    </Option>
  ));
}

function filterOptions(options: OptionsT, iv: string): OptionsT {
  const filteredOptions: OptionsT = [];
  options.every((opt) => {
    if (iv.length === 0 || filteredOptions.length >= 50) return false;
    const ov = opt.props.title;
    const isMatch = ov.includes(iv) && ov !== iv;
    if (isMatch) {
      filteredOptions.push(opt);
    }
    return true;
  });
  return filteredOptions;
}

export function useOptions(rowData: AgRows, value: string): OptionsT {
  const options = useMemo(() => gatherOptions(rowData), [rowData]);
  const iv = value.length ? " " + _.toLower(_.trim(value)) : "";
  return useMemo(() => filterOptions(options, iv), [options, iv]);
}

function gatherStrings(rowData): StringCounts {
  if (!rowData || !_.size(rowData)) {
    return {};
  }

  const counts: StringCounts = {};
  rowData.forEach((row) => {
    Object.entries(row).forEach(([fieldName, fieldValue]) => {
      if (typeof fieldValue !== "string") return null;
      const s = _.trim(fieldValue);
      if (s.length) {
        counts[s] = (counts[s] || 0) + 1;
      }
      // if (_.isString(fieldValue))
    });
  });
  return counts;
}
