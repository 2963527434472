import "styled-components/macro"; // DO NOT REMOVE. Necessary for using the css={`...`} prop
import React, { useCallback, useState } from "react";
import { Modal, Progress, Statistic } from "antd";
import AgTable from "./AgTable";
import _ from "lodash";
import { getScoreInfo } from "../helpers/get-score-info";
import { useCustomerRow } from "../helpers/use-customer-row";
import { GridContext } from "./AgTable/my-ag-grid-react-context";

function isScoreColumn(colDef) {
  return colDef.field === "churn_risk_score";
}

function valueIsValidChurnScore(value) {
  return !_.isNull(value) && !_.isNaN(value) && value >= 0 && value <= 100;
}

function useOnCellClicked(setCustomerId) {
  return useCallback(
    ({
      data, // the user provided data for the row in question
      colDef, // the column definition for the cell in question
      // type, // the event type, eg 'sortChanged' or 'columnResized'
      // api, // see Grid API
      // columnApi, // see Column API
      // node, // the RowNode for the row in question
      // rowIndex, // the visible row index for the row in question
      // rowPinned, // either 'top', 'bottom' or undefined / null (if not pinned)
      // context, // bag of attributes, provided by user, see Context
      // event, // if even was due to browser event (eg click), then this is browser event
      // column, // the column for the cell in question
      value, // the value for the cell in question
    }) => {
      if (isScoreColumn(colDef) && valueIsValidChurnScore(value)) {
        setCustomerId(data.customer_id);
      }
    },
    [setCustomerId]
  );
}

export function ChurnScoreAgTable({ rowData, columnDefs, ...restProps }) {
  const [customerId, setCustomerId] = useState(null);
  // const onCellClicked = useOnCellClicked(setCustomerId);

  return (
    <>
      <AgTable
        rowData={rowData}
        columnDefs={columnDefs}
        // onCellClicked={onCellClicked}
        tableName={"churn_risk"}
        {...restProps}
      />
      <ScoreExplanationModal
        customerId={customerId}
        setCustomerId={setCustomerId}
      />
    </>
  );
}

export function ScoreExplanationModal({ customerId, setCustomerId }) {
  // const visible = !!customerId;
  const hideModal = () => setCustomerId(null);

  const customerRow = useCustomerRow(customerId);
  const isReady = customerRow && _.size(customerRow);

  return (
    <Modal
      visible={isReady}
      onOk={hideModal}
      onCancel={hideModal}
      title={
        isReady && (
          <span>
            Churn Risk Breakdown:&nbsp;
            <span
              css={`
                font-weight: bolder;
              `}
            >
              {customerRow.CustomerName}
            </span>
          </span>
        )
      }
      footer={false}
      mask={true}
      centered // Vertically centered
      width={"1000px"}
    >
      {isReady && <ScoreExplanationModalInner customerRow={customerRow} />}
    </Modal>
  );
}

const columnDefs = [
  {
    field: "humanName",
    headerName: "Feature",
    type: "categoryColumn",
    // width: 200
  },
  {
    field: "value",
    headerName: "Value",
    type: "floatColumn",
    // width: 92
  },
  {
    field: "contrib",
    headerName: "Contribution",
    type: "floatColumn",
    // width: 167,
    valueFormatter: ({ value }) => value.toFixed(2),
  },
  {
    field: "valueExplanation",
    headerName: "Signal",
    type: "categoryColumn",
    // width: 200
  },
];

function ScoreExplanationModalInner({ customerRow }) {
  const churnScore = customerRow["churn_risk_score"];
  const rowData = getScoreInfo(customerRow);
  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
      `}
    >
      <div
        css={`
          margin-bottom: 32px;

          & .ant-statistic {
            display: flex;
            align-items: center;
          }
          & .ant-statistic-title.ant-statistic-title {
            width: 200px;
            word-wrap: break-word;
            margin-right: 8px;
          }
          & .ant-statistic-content {
            text-align: center;
          }
        `}
      >
        <Statistic
          title={`Probability this customer will fall to 50% of 4-week trailing average volume in the upcoming fiscal week`}
          valueRender={() => (
            <Progress
              percent={Math.round(Number(parseFloat(churnScore)))}
              type="dashboard"
            />
          )}
        />
      </div>
      <div
        css={`
          padding: 24px;
          width: 100%;
        `}
      >
        <GridContext.Provider value={SCORE_FACTORS__GRID_CONTEXT}>
          <AgTable rowData={rowData} columnDefs={columnDefs} height={"430px"} />
        </GridContext.Provider>
      </div>
    </div>
  );
}

const SCORE_FACTORS__GRID_CONTEXT = {
  tableName: "score_factors",
};

const CHURN_RISK__GRID_CONTEXT = {
  tableName: "churn_risk",
};
