import {
  CustomerInteractionsModel,
  getCustomerInteractionsModel,
} from "./model-customer-interactions";

import { StarsModel, getStarsModel } from "./model-stars";
import {
  getInvoiceCreditsModel,
  InvoiceCreditsModel,
} from "./model-invoice-credits";

export interface TableModels {
  customerInteractions: CustomerInteractionsModel;
  invoiceCredits: InvoiceCreditsModel;
  stars: StarsModel;
}

export type TableModelSliceName =
  | "invoiceCredits"
  | "customerInteractions"
  | "stars";

export const tableModels: TableModels = {
  customerInteractions: getCustomerInteractionsModel(),
  invoiceCredits: getInvoiceCreditsModel(),
  stars: getStarsModel(),
};
