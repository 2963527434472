import { HMAC } from "fast-sha256";

const SECRET = "8bo0m1%D1ff#f";

export function fsIdentify({ email }) {
  // console.log(`Called fsIdentify(${JSON.stringify({email, displayName})})`);

  const h = new HMAC(SECRET);
  const email_hmac = h.update(email).digest();

  // eslint-disable-next-line no-undef
  safeIdentify(email_hmac, {
    displayName: email,
    email,
  });
}

export function safeIdentify(...args) {
  try {
    // eslint-disable-next-line no-undef
    FullStory.identify(...args);
  } catch (e) {
    if (e.name !== "ReferenceError") {
      throw e;
    }
  }
}
